import { useState, useEffect } from 'react';
import './index.scss';
import { removeSplash } from '../../helpers/removeSplash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NewestLastEvaluatedKey } from '../../../interface/listEbook';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
    getNewest,
    getPublisherById,
    getCatalogBySubCategoryId
} from '../../redux/catalog/catalogCrud';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { onErrorImage } from '../../Utilities/imageUtilities';
import ContentDetailModal from '../../components/modal/contentDetailModal/ContentDetailModal';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface Ebook {
    authorName: string;
    catId: number;
    catName: string;
    contentUID: string;
    coverImage: string;
    creatorUID: string;
    fileType: string;
    orderAmount: number;
    publicationDate?: number;
    publisherName?: string;
    purchasedConcurrent: number;
    subcatId: number;
    subcatName: string;
    title: string;
}

interface EbookProps {
    contents: Ebook[];
    publisherName?: string;
    type?: string;
    id?: string;
    LastEvaluatedKey?: any;
    receivedFrom?: string;
    categoryId?: string;
    subCategoryId?: string;
    publisherID?: string;
}

export const Ebook = (props: EbookProps) => {
    const pageId = 'catalogueBooklistsPage';
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const [contents, setContents] = useState<Ebook[]>(props.contents);
    const [lastEvaluated, setLastEvaluated] = useState<
        NewestLastEvaluatedKey | undefined
    >(props?.LastEvaluatedKey);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    const { environment, request } = useSelector(
        (state: HibraryRootState) => ({
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        removeSplash(dispatch);
    }, [contents]);

    useEffect(() => {
        if (
            environment.endOfScreen &&
            lastEvaluated &&
            contents !== undefined &&
            contents.length > 0 &&
            !request.loading
        ) {
            dispatch(action.setProcess(true, pageId));
            if (props.receivedFrom === 'newest') {
                getNewest(lastEvaluated)
                    .then((res) => {
                        let contentTemp = contents;
                        contentTemp = contentTemp.concat(res.contents);
                        console.log('res', res.LastEvaluatedKey);
                        setContents(contentTemp);
                        setLastEvaluated(res.LastEvaluatedKey);
                        dispatch(action.setProcess(false, pageId));
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch(action.setProcess(false, pageId));
                    });
            } else {
                if (
                    props.receivedFrom === 'catagory' &&
                    props.categoryId &&
                    props.subCategoryId
                ) {
                    getCatalogBySubCategoryId(
                        parseInt(props.categoryId),
                        parseInt(props.subCategoryId),
                        lastEvaluated
                    )
                        .then((res) => {
                            let contentTemp = contents;
                            contentTemp = contentTemp.concat(res.contents);
                            console.log('res', res.LastEvaluatedKey);
                            setContents(contentTemp);
                            setLastEvaluated(res.LastEvaluatedKey);
                            dispatch(action.setProcess(false, pageId));
                        })
                        .catch(() => {
                            dispatch(action.setProcess(false, pageId));
                        });
                } else {
                    if (
                        props.receivedFrom === 'publisher' &&
                        props.publisherID
                    ) {
                        getPublisherById(props.publisherID, lastEvaluated)
                            .then((res) => {
                                let contentTemp = contents;
                                contentTemp = contentTemp.concat(res.contents);
                                console.log('res', res.LastEvaluatedKey);
                                setContents(contentTemp);
                                setLastEvaluated(res.LastEvaluatedKey);
                                dispatch(action.setProcess(false, pageId));
                            })
                            .catch((err) => {
                                console.log(err);
                                dispatch(action.setProcess(false, pageId));
                            });
                    }
                }
            }
        }
    }, [environment]);

    const handleDetailModal = (contentUID: string) => {
        history.replace({ search: `?contentUID=${contentUID}` });
        setIsShowModal(true);
    };

    const renderEbook = (el: Ebook) => {
        // let date;
        // if (el.publicationDate !== undefined) {
        //     date = new Date(el.publicationDate * 1000).getFullYear();
        // } else {
        //     date = '-';
        // }

        return (
            <div className='col-ebook cover catalog-ebook' key={el.contentUID}>
                <div
                    className={
                        el.fileType === 'pdf' ? 'filetype pdf' : 'filetype epub'
                    }
                >
                    {el.fileType}
                </div>
                <img
                    className='col-ebook-image'
                    onError={onErrorImage}
                    src={el.coverImage}
                    onClick={() => handleDetailModal(el.contentUID)}
                />
                <div className='col-ebook-detail'>
                    <div className='book-title'>
                        <span onClick={() => handleDetailModal(el.contentUID)}>
                            {el.title}
                        </span>
                    </div>
                    <div className='detail'>
                        {intl.formatMessage({ id: 'Book.Writer' }) +
                            ' : ' +
                            el.authorName}
                    </div>
                    <div className='detail'>
                        {intl.formatMessage({ id: 'Book.Publisher' }) + ' : '}
                        {el.publisherName
                            ? el.publisherName
                            : props.publisherName}
                    </div>
                    {/* <div className='detail'>ปีที่ตีพิมพ์ : {date}</div> */}
                    <div className='detail'>
                        {intl.formatMessage({ id: 'Catalogue.NewestCard' }) +
                            ' : ' +
                            el.catName}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className='ebookContainer'>
                {contents !== undefined && contents.length > 0 && (
                    <div className='contentsContainer'>
                        {contents.map((el) => {
                            return renderEbook(el);
                        })}
                    </div>
                )}
            </div>
            <ContentDetailModal
                visible={isShowModal}
                onClose={() => setIsShowModal(false)}
            />
            <div className='bottom-loading'>
                <Loading id={pageId} />
            </div>
        </>
    );
};
