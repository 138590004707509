/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Category, SubCategory } from '../../../interface';

export interface SegmentHeaderParam {
    left?: string;
    right?: boolean;
    path?: string;
    params?: Category | SubCategory;
    title?: string;
    subTitle?: string;
}

export const SegmentHeader = (props: SegmentHeaderParam) => {
    const intl = useIntl();

    return (
        <div className='segmentHeader'>
            <div className='titleSegment'>
                <h1>{props.left}</h1>
                {props.subTitle && <h3>{props.subTitle}</h3>}
            </div>
            {props.right ? (
                <Link
                    to={{
                        pathname: props.path ?? '',
                        state: props.params
                    }}
                >
                    {`${intl.formatMessage({
                        id: intl.formatMessage({ id: 'Library.SeeAllBtn' })
                    })} >`}
                </Link>
            ) : null}
        </div>
        // <div className='title-segment'>
        //     <div>
        //         <h2>{props.title || 'title'}</h2>
        //         <h3>{props.subTitle}</h3>
        //     </div>
        //     <Link
        //         to={{
        //             pathname: props.path ?? '',
        //             state: props.params
        //         }}
        //     >
        //         {`ดูทั้งหมด >`}
        //     </Link>
        // </div>
    );
};
