import { Link } from 'react-router-dom';
import { DetailViewParam } from '../../../interface/detailPage';
import { getWriterNameRoute } from '../../routes/generatePath';
import { useIntl } from 'react-intl';

export const WriterView = (props: DetailViewParam) => {
    const content = props.content;
    const intl = useIntl();
    return (
        <>
            {intl.formatMessage({ id: 'Book.Writer' })} :{' '}
            <Link
                to={{
                    pathname: getWriterNameRoute(
                        content.authorName,
                        content.licenseType,
                        content.contentType,
                        content.writerID,
                        content.creatorUID
                    )
                }}
            >
                {content.authorName}
            </Link>
        </>
    );
};
