import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { action } from '../../redux/request';
import { getContentDetail, rentEbook } from '../../redux/store/storeCrud';
import { showLogin } from '../../redux/login';
import { Modal } from 'antd';
import { EbookDetailView } from './DetailView';
import { MemoVideoDetailView } from './VideoView';
import { PodcastDetailView } from './PodcastView';
import { removeSplash } from '../../helpers/removeSplash';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../customUI/loading';
import { BreadcrumbLink, BreadCrumbView } from '../../components/breadcrumb';
import { RequestStage } from '../../redux/rental';
import { getNextRentalStatus } from '../../customUI/RentButton';
import {
  getDetailRoute,
  getListSubCategoryRoute,
  getListSubInCategoryRoute
} from '../../routes/generatePath';
import {
  LicenseType,
  ContentDetail,
  ContentType,
  VideoContent,
  PodcastContent,
  RentalStatus
} from '../../../interface/home';

import * as model from '../../redux/store/storeModel';
import NoData from '../../helpers/placeholder-nodata';
import './_PodcastDetail.scss';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface DetailParam {
  uid: string;
  licenseType: LicenseType;
  location?: any;
}

export const DetailPage = (props: DetailParam) => {
  const pageId = 'DetailPage';
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [content, setContent] = useState<
    ContentDetail | VideoContent | PodcastContent
  >();
  const [remainConcurrent] = useState(0);
  const [waitingAmount] = useState(0);
  const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();

  const { clientInfo, authState, request, rentalState } = useSelector(
    (state: HibraryRootState) => ({
      authState: state.auth,
      clientInfo: state.clientInfo,
      request: state.fetch,
      rentalState: state.rental.id === props.uid ? state.rental : undefined
    }),
    shallowEqual
  );

  useEffect(() => {
    setContent(undefined);
    fetchContentDetail();
  }, [props.uid]);

  useEffect(() => {
    if (content) fetchUpdateContentDetail();
  }, [authState]);

  useEffect(() => {
    if (rentalState && rentalState.stage === RequestStage.Complete) {
      updateContentAfterRequest(rentalState.rentalStatus ?? RentalStatus.Rent);
    }
  }, [rentalState]);

  const fetchContentDetail = async () => {
    try {
      dispatch(action.setProcess(true, pageId));
      if (props.uid) {
        const data = (await getContentDetail(
          props.uid,
          props.licenseType,
          clientInfo.companyPrefix,
          !authState.isLogin
        )) as ContentDetail;
        setContent({ ...data });
        history.replace(
          getDetailRoute(
            data.contentUID,
            data.contentType ?? ContentType.ebook,
            data.licenseType
          )
        );
        setBreadCrumbs([
          {
            title: data?.catName ?? '',
            path: getListSubInCategoryRoute(
              data.catId!,
              props.licenseType,
              data.contentType
            )
          },
          {
            title: data?.subcatName ?? '',
            path: getListSubCategoryRoute(
              data.catId!,
              data.subcatId!,
              props.licenseType,
              data.contentType
            )
          },
          { title: data?.title ?? '' }
        ]);
      }
      dispatch(removeSplash);
      dispatch(action.setProcess(false, pageId));
    } catch (error) {
      dispatch(action.setProcess(false, pageId));
      dispatch(removeSplash);
    }
  };
  //

  const fetchUpdateContentDetail = async () => {
    try {
      dispatch(action.setProcess(true, pageId));
      if (props.uid) {
        const data = (await getContentDetail(
          props.uid,
          props.licenseType,
          clientInfo.companyPrefix,
          !authState.isLogin
        )) as ContentDetail;
        setContent({ ...data });
      }
      dispatch(removeSplash);
      dispatch(action.setProcess(false, pageId));
    } catch (error) {
      dispatch(action.setProcess(false, pageId));
      dispatch(removeSplash);
    }
  };

  const fetchUpdatePlayList = async () => {
    try {
      dispatch(action.setProcess(true, pageId));
      const data = await getContentDetail<VideoContent>(
        content!.contentUID,
        props.licenseType,
        clientInfo.companyPrefix,
        !authState.isLogin
      );
      dispatch(action.setProcess(false, pageId));
      setContent({
        ...content!,
        playlist: data!.playlist,
        remainConcurrent: rentalState?.license?.balance,
        rentalStatus: RentalStatus.Rented
      });
    } catch (error) {
      dispatch(action.setProcess(false, pageId));
    }
  };

  const updateContentAfterRequest = (status: RentalStatus) => {
    const newStatus = getNextRentalStatus(status);
    switch (newStatus) {
      case RentalStatus.Rent:
        break;
      case RentalStatus.Rented:
        if (content?.contentType === ContentType.video) {
          fetchUpdatePlayList();
        } else {
          setContent({
            ...content!,
            remainConcurrent: rentalState?.license?.remainConcurrent,
            waitingAmount: rentalState?.license?.waiting,
            totalRent: rentalState?.license?.totalRent,
            rentalStatus: newStatus
          });
        }

        break;
      case RentalStatus.WaitingList:
        setContent({
          ...content!,
          waitingAmount:
            rentalState?.license?.waiting ?? (content?.waitingAmount ?? 0) - 1,
          rentalStatus: newStatus
        });
        break;
      case RentalStatus.WaitingListed:
        setContent({
          ...content!,
          waitingAmount:
            rentalState?.license?.waiting ?? (content?.waitingAmount ?? 0) + 1,
          rentalStatus: newStatus
        });
        break;
      default:
        break;
    }
  };

  const SampleBtnDidClick = (content: ContentDetail) => {
    if (authState.authToken === undefined) {
      dispatch(showLogin(true));
    } else {
      Modal.confirm({
        title: intl.formatMessage({ id: 'Book.ModalDownloadSample' }),
        content: content.title,
        okText: intl.formatMessage({ id: 'Action.OKBtn' }),
        cancelText: intl.formatMessage({ id: 'Action.CancelBtn' }),
        onOk: () => SampleAction(content)
      });
    }
  };

  const SampleAction = (content: ContentDetail) => {
    rentEbook(
      content.contentUID,
      model.RentalStatus.Rent,
      model.LicenseType.sample
    )
      ?.then((response: any) => {
        if (response.data && response.data.status === true) {
          const modal = Modal.success({});
          modal.update({
            title: intl.formatMessage({
              id: 'Book.ModalMediaAdded'
            }),
            okText: intl.formatMessage({ id: 'Action.OKBtn' })
          });
          setTimeout(() => {
            modal.destroy();
          }, 3000);
        }
      })
      .catch((err: any) => {
        if (err) {
          Modal.info({
            title:
              err.response && err.response.data && err.response.data.message,
            okText: 'ตกลง'
          });
        }
      });
  };

  const DetailViewByTypeMemo = useMemo(() => {
    switch (content?.contentType) {
      case ContentType.video:
        return (
          <div className="ebookDetail video">
            <MemoVideoDetailView
              content={content as VideoContent}
              licenseType={props.licenseType}
            />
          </div>
        );
      case ContentType.podcast:
        return (
          <div className="ebookDetail podcast">
            <PodcastDetailView
              content={content as PodcastContent}
              licenseType={props.licenseType}
            />
          </div>
        );
      default:
        return (
          <div className="ebookDetail">
            <EbookDetailView
              content={content as ContentDetail}
              remainConcurrent={remainConcurrent}
              waitingAmount={waitingAmount}
              sampleDidClick={() => SampleBtnDidClick(content as ContentDetail)}
            />
          </div>
        );
    }
  }, [content, authState.authToken]);

  const breadcrumbs = useMemo(() => {
    return <BreadCrumbView addLinks={breadCrumbs} />;
  }, [breadCrumbs, content]);

  return (
    <>
      {breadcrumbs}
      <div className="store view-all-content">
        <Helmet>
          <title>
            {window.location.hostname.match(/cu-elibrary.com/)
              ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
              : 'Hibrary'}{' '}
            | {content?.title ?? ''}
          </title>
        </Helmet>

        {content ? DetailViewByTypeMemo : !request.loading && <NoData />}
        <Loading id={pageId} />
      </div>
    </>
  );
};
