import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { getDomainName } from '../pages/main';

export const setCookie = (name: string, value: string, expired: number) => {
    Cookies.set(name, value, {
        expires: dayjs().add(expired, 'd').unix(),
        path: '/',
        domain: `.${getDomainName(window.location.hostname)}`
    });
};

export const eraseCookie = (name: string) => {
    Cookies.remove(name, {
        path: '/',
        domain: `.${getDomainName(window.location.hostname)}`
    });
};

export const getCookie = (name: string) => {
    return Cookies.get(name);
};

export const getJSONCookie = (name: string) => {
    return Cookies.getJSON(name);
};
