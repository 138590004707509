const initialData: LoginState = {
   showLogin: false
};
export interface LoginState {
   showLogin: boolean;
}
export enum LoginActionType {
   ShowLogin = 'LoginAction[ShowLogin]',
   CloseLogin = 'LoginAction[CloseLogin]'
}
export interface LoginAction {
   payload?: any;
   type: LoginActionType;
}
export const reducer = (state = initialData, action: LoginAction): LoginState => {
   switch (action.type) {
      case LoginActionType.ShowLogin: {
         return {
            showLogin: true
         };
      }
      case LoginActionType.CloseLogin: {
         return {
            showLogin: false
         };
      }
      default:
         return state;
   }
};

export const showLogin = (isShow: boolean) => {
   if (isShow) {
      return {
         type: LoginActionType.ShowLogin
      };
   } else {
      return {
         type: LoginActionType.CloseLogin
      };
   }
};

export const action = {
   showLogin
};
