import axios, { AxiosError } from 'axios';
import { RentalAction, RentalActionType } from '.';
import {
  Content,
  LicenseType,
  RentalStatus,
  BestSellerContent
} from '../../../interface/home';
import { AddLicenseResponse } from '../../../interface/rental';
import { HtiResponse } from '../../../interface/request';
import { ReduxDisPatch } from '../../../redux';

export const rentProcess = (
  content: Content | BestSellerContent
): RentalAction => {
  return {
    type: RentalActionType.Process,
    payload: { content }
  };
};
export const rentConfirm = (
  content: Content | BestSellerContent,
  rentalStatus: RentalStatus
): RentalAction => {
  return {
    type: RentalActionType.Confirm,
    payload: { content, rentalStatus }
  };
};
export const rentComplete = (response: {
  license: AddLicenseResponse;
}): RentalAction => {
  return {
    type: RentalActionType.Complete,
    payload: { response }
  };
};
export const rentError = (error: any): RentalAction => {
  return {
    type: RentalActionType.Error,
    payload: { response: error }
  };
};
export const rentCancel = (): RentalAction => {
  return {
    type: RentalActionType.Cancel
  };
};
export const rentReset = (): RentalAction => {
  return {
    type: RentalActionType.Reset
  };
};
export const rentEbook =
  (
    content: Content | BestSellerContent,
    licenseType?: LicenseType,
    licenseUID?: string
  ) =>
  async (dispatch: ReduxDisPatch<RentalAction>) => {
    dispatch(rentProcess(content));
    const uid =
      content.rentalStatus === RentalStatus.Rented
        ? licenseUID
        : content.contentUID;
    try {
      const result = await requestFromServer(
        uid ?? '',
        content.rentalStatus,
        licenseType
      );

      dispatch(rentComplete(result.data ?? {}));
    } catch (error) {
      const err = error as AxiosError<HtiResponse>;
      dispatch(rentError(err.response?.data));
    }
  };
export const requestFromServer = async (
  uid: string,
  rentalStatus?: RentalStatus,
  licenseType?: LicenseType
) => {
  switch (rentalStatus) {
    case RentalStatus.Rent: {
      const path = `/licenses`;
      const qString: any = {
        contentUID: uid,
        licenseType: licenseType ? licenseType : LicenseType.Rent
      };
      const { data } = await axios.post<HtiResponse>(path, qString);
      return data;
    }
    case RentalStatus.WaitingList: {
      const path = `/licenses/${uid}/waiting`;
      const qString: any = {
        licenseType: licenseType ? licenseType : LicenseType.Rent
      };
      const { data } = await axios.post<HtiResponse>(path, qString);
      return data;
    }
    case RentalStatus.WaitingListed: {
      const path = `/licenses/${uid}/waiting`;
      const qString: any = {
        licenseType: licenseType ? licenseType : LicenseType.Rent
      };
      const { data } = await axios.delete<HtiResponse>(path, qString);
      return data;
    }
    case RentalStatus.Rented: {
      const path = `/licenses/${uid}/return`;
      const qString: any = {
        licenseType: licenseType ? licenseType : LicenseType.Rent
      };
      const { data } = await axios.patch<HtiResponse>(path, qString);
      return data;
    }
    default:
      // eslint-disable-next-line no-case-declarations
      const { data } = await axios.get<HtiResponse>('');
      return data;
  }
};
