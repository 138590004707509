import axios from 'axios';
import { NewestLastEvaluatedKey } from '../../../interface/listEbook';
import { chkLastEvaluateKey } from '../utilities/index';
import queryString from 'query-string';

export const getPublisher = async () => {
   const {
      data: { data }
   } = await axios.get(`/catalogs/publishers`);
   return data;
};

export const getPublisherById = async (publisherID: string, LastEvaluatedKey?: any) => {
   let path = `/catalogs/publishers/${publisherID}`;
   const qString: any = chkLastEvaluateKey(LastEvaluatedKey);
   if (LastEvaluatedKey) {
      path += `?lastEvaluatedKey=${LastEvaluatedKey}`;
   }
   const {
      data: { data }
   } = await axios.get(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getNewest = async (LastEvaluatedKey?: NewestLastEvaluatedKey) => {
   let path = `/catalogs/newest`;
   const qString: any = chkLastEvaluateKey(LastEvaluatedKey);

   if (LastEvaluatedKey) {
      path += `?lastEvaluatedKey=${LastEvaluatedKey}`;
   }
   const {
      data: { data }
   } = await axios.get(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getCategories = async () => {
   const {
      data: { data }
   } = await axios.get('/catalogs/categories');
   return data;
};

export const getSubCategories = async (categoryId: number) => {
   const {
      data: { data }
   } = await axios.get(`/catalogs/categories/${categoryId}`);
   return data;
};

export const getCatalogBySubCategoryId = async (
   categoryId: number,
   subCategoryId: number,
   LastEvaluatedKey?: any
) => {
   let path = `/catalogs/categories/${categoryId}/subcategories/${subCategoryId}`;
   const qString: any = chkLastEvaluateKey(LastEvaluatedKey);

   if (LastEvaluatedKey) {
      path += `?lastEvaluatedKey=${LastEvaluatedKey}`;
   }
   const {
      data: { data }
   } = await axios.get(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getCatalogDetail = async (contentUID: string) => {
   const path = `/catalogs/contents/${contentUID}`;
   const {
      data: { data }
   } = await axios.get(path);
   return data;
};
