import { Sponsor } from './sponsor';

export interface HomeResponse {
  home: Home[];
  banners: Banner[];
}

export enum DisplayType {
  ContentSet = 'ContentSet',
  CategoryEbook = 'CategoryEbook',
  SubCategoryEbook = 'SubCategoryEbook',
  AllContent = 'AllContent',
  Categories = 'Categories',
  BestSeller = 'BestSeller',
  NewContent = 'NewContent',
  ContentWithButton = 'ContentWithButton',
  ContentSlideWithButtonDetail = 'ContentSlideWithButtonDetail',
  ContentWithDetail = 'ContentWithDetail',
  ContentWithButtonDetail = 'ContentWithButtonDetail',
  Banner = 'Banner',
  AllCategory = 'AllCategory',
  VideoList = 'VideList',
  VideoSlide = 'VideoSlide',
  MagazineSet = 'MagazineSet',
  NewspaperSet = 'NewspaperSet',
  MainSponsor = 'MainSponsor',
  SubSponsor = 'SubSponsor'
}

export enum HomeType {
  ContentSet = 'ContentSet',
  CategoryEbook = 'CategoryEbook',
  SubCategoryEbook = 'SubCategoryEbook',
  AllContent = 'AllContent',
  Categories = 'Categories',
  BestSeller = 'BestSeller',
  NewContent = 'NewContent',
  MainSponsor = 'MainSponsor',
  SecondarySponsor = 'SecondarySponsor',
  RecommendContent = 'RecommendContent',
  RentAbleContent = 'RentAbleContent'
}
export enum ContentType {
  content = 'content',
  ebook = 'ebook',
  podcast = 'podcast',
  video = 'video'
}
export enum HomeSetType {
  Magazine = 'Magazine',
  Newspaper = 'Newspaper',
  eBook = 'eBook'
}

export enum BannerType {
  normal = 'normal',
  ebook = 'ebook',
  podcast = 'podcast',
  video = 'video',
  link = 'link',
  creator = 'creator',
  author = 'author',
  category = 'category',
  subcategory = 'subcategory',
  search = 'search'
}

export enum LicenseType {
  Sample = 'Sample',
  Rent = 'Rent',
  Buffet = 'Buffet',
  Private = 'Private',
  Free = 'Free'
}

export enum RentalStatus {
  Rent = 'Rent',
  Rented = 'Rented',
  WaitingList = 'WaitingList',
  WaitingListed = 'WaitingListed',
  Returned = 'Returned',
  ReturnAble = 'ReturnAble'
}
export enum RentBtnTitle {
  Rent = 'ยืมสื่อ',
  Rented = 'ยืมแล้ว',
  WaitingList = 'จองคิว',
  WaitingListed = 'ยกเลิก'
  // Returned = "Returned",
  // ReturnAble = "ReturnAble",
}

type HomeContent = VideoContent | Content | PodcastContent;

export interface Home {
  type?: HomeType | DisplayType;
  sets?: HomeSet[];
  setType?: HomeSetType;
  title?: string;
  subtitle?: string;
  contentType?: ContentType;
  ebooks?: Content[];
  videos?: VideoContent[];
  podcasts?: PodcastContent[];
  contents?: HomeContent[];
  sponsors?: Sponsor[];
  recommends?: Recommend[];
  catId?: string | number;
  subcatId?: string | number;
  category?: Category[];
}

export interface Category {
  catId: number;
  status?: number;
  catName: string;
  coverImage?: string;
  subCategory?: SubCategory[];
  licenseType: LicenseType;
  contentType?: ContentType;
  bgColor?: string;
}

export const getLicenseType = (licenseType: string): LicenseType => {
  switch (licenseType) {
    case 'sample':
      return LicenseType.Sample;
    case 'rent':
      return LicenseType.Rent;
    case 'buffet':
      return LicenseType.Buffet;
    case 'private':
      return LicenseType.Private;
    case 'free':
      return LicenseType.Free;
    default:
      return LicenseType.Rent;
  }
};
export interface SubCategory {
  subcatId: number;
  status: number;
  subcatName: string;
  coverImage: string;
}

export interface Recommend {
  coverImage: string;
  coverImageWebp?: string;
  setUID: string;
  sponsorUID: string;
  string: number;
  title: string;
}

export interface Content {
  contentUID: string;
  subcatId?: string;
  concurrent: number;
  catId?: string;
  creatorUID: string;
  title: string;
  authorName: string;
  coverImage: string;
  fileType: string;
  licenseType: LicenseType;
  contentType: ContentType;
  writerID?: number;
  status?: number;
  pageCount?: number;
  fileSize?: number;
  description?: string;
  publisherName?: string;
  catName?: string;
  subcatName?: string;
  rentalStatus?: RentalStatus;
  rentalPeriod?: number;
  channelName?: string;
  view?: number;
  uploadDate?: number;
  duration?: string;
  writerUID?: string;
  bgColor?: string;
  expireDate?: number;
}
export interface HomeSet {
  uid: string;
  title: string;
  imagePath: string;
  bgColor?: string;
}
export interface Banner {
  type: BannerType;
  licenseType?: LicenseType;
  value: string;
  subValue?: string;
  imageUrl: string;
}

export interface Ebook {
  contentUID: string;
  subcatId: number;
  activeLicense: number;
  concurrent: number;
  catId: number;
  creatorUID: string;
  title: string;
  authorName: string;
  isNew: boolean;
  isBestSeller: boolean;
  isPromotions: boolean;
  coverImage: string;
  fileType: string;
  channelName?: string;
}
export interface BestSellerContent extends Content {
  concurrent: number;
  writerID: number;
  title: string;
  fileType: string;
  status: number;
  pageCount: number;
  fileSize: number;
  description: string;
  publisherName: string;
  catName: string;
  subcatName: string;
  rentalStatus: RentalStatus;
  rentalPeriod: number;
  waitingAmount: number;
  remainConcurrent: number;
  licenseType: LicenseType;
  contentType: ContentType;
}

export interface ContentDetail extends Content {
  concurrent: number;
  pageCount: number;
  fileSize: number;
  description: string;
  publisherName: string;
  catName: string;
  subcatName: string;
  creatorUID: string;
  totalRent?: number;
  title: string;
  authorName: string;
  coverImage: string;
  coverImageWebp: string;
  fileType: string;
  relatedEbooks?: Content[];
  relatedContents?: Content[];
  rentalPeriod?: number;
  rentalStatus: RentalStatus;
  waitingAmount?: number;
  remainConcurrent?: number;
  licenseType: LicenseType;
  tags: string[];
}

export interface VideoContent extends Content {
  contentUID: string;
  subcatId: string;
  catId: string;
  creatorUID: string;
  title: string;
  coverImage: string;
  status: number;
  description: string;
  rentalPeriod: number;
  channelName: string;
  channelImage: string;
  duration: string;
  view?: number;
  uploadDate?: number;
  playlist: string;
  language: string;
  totalRent?: number;
  waitingAmount?: number;
  remainConcurrent?: number;
  relatedContents?: Content[];
  licenseType: LicenseType;
}

export interface PodcastContent extends Content {
  contentUID: string;
  subcatId: string;
  catId: string;
  creatorUID: string;
  title: string;
  coverImage: string;
  status: number;
  description: string;
  rentalPeriod: number;
  channelName: string;
  uploadDate: number;
  totalRent?: number;
  waitingAmount?: number;
  remainConcurrent?: number;
  relatedContents?: Content[];
  licenseType: LicenseType;
  episodes?: PodcastEpisode[];
  duration: string;
}

export interface PodcastEpisode {
  title: string;
  contentUID: string;
  description: string;
  uploadDate: number;
  duration: string;

  // sourceUrl?: string;
  // isPlaying?: boolean;
  // onPlayClick?: (target: any, episode: any) => void;
  // onPauseClick?: (target: any, episode: any) => void;
  // autoPlay?: boolean;
  // rentBtn_playpodcast?: any;
  // rentalStatus?: any;
  // licenseType?: string;
  // episodeUID?: string;
  // podcastUID?: string;
  // playlist?: string;
  // playingEp?: any;
  // setPlayingEp?: any;
  // ep?: any;
  // isPlay?: boolean;
  // isPlaylist?: boolean;
  // setIsPlaylist?: any;
}
export interface PodcastPlaylist {
  playlist: string;
}

export enum ContentViewType {
  List, // 0
  Collection, // 1
  BestSeller, // 2
  History, //3
  Queue, //4
  BookShelf, //5
  Detail
}

export interface CategoryContent {
  catId: number;
  subcats: SubCategoryContent[];
  catName: string;
  status: number;
}

export interface SubCategoryContent {
  subcatId: number;
  subcatName: string;
  ebooks?: Content[];
  status: number;
  contents?: Content[];
}

export interface RenderHomeSegmentParam {
  segment: Home;
  indexSegment: number;
  licenseType: LicenseType;
}
