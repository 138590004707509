import { SplashLoading } from '../splashLoading';
import { createGlobalStyle } from 'styled-components';
import { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getUserInfo } from '../../redux/auth/authRedux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { setupAxiosByToken } from '../../../request';
import { RentalModal } from '../../components/modal';
import { useLocation, useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { actions } from '../../redux/auth/authRedux';
import { HtiErrorResponse, StoreStatus } from '../../../interface';
import { fetchClientInfo } from '../../redux/clientInfo/request';
import { setTheme } from '../../redux/theme/action';
import { setClientInfo } from '../../redux/clientInfo/action';
import { AxiosError } from 'axios';
import { clientInfoMock } from '../../routes/mockClientInfo';
import { Modal } from 'antd';
import { ip } from '../../../request';
import * as environment from '../../redux/environment';
import Routes from '../../routes';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import dayjs from 'dayjs';
import './index.scss';
import { getTokenByHash } from '../../redux/auth/authCrud';
import CookieTab from '../../components/cookiePopup/CookiePopup';

export const Main = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [GlobalStyle, setGlobalStyle] = useState(createGlobalStyle({}));
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const { authen, themeState, clientInfo } = useSelector(
        (state: HibraryRootState) => ({
            authen: state.auth,
            themeState: state.theme,
            clientInfo: state.clientInfo
        }),
        shallowEqual
    );

    useEffect(() => {
        const theme = themeState.darkMode ? themeState.darkTheme : themeState.normalTheme;
        setGlobalStyle(
            createGlobalStyle({
                html: {
                    '--primary': theme.Primary,
                    '--primary-variant': theme.PrimaryVariant,
                    '--secondary': theme.Secondary,
                    '--secondary-variant': theme.SecondaryVariant,
                    '--background': theme.Background,
                    '--surface': theme.Surface,
                    '--surface2': theme.Surface2,
                    '--error': theme.Error,
                    '--on-primary': theme.OnPrimary,
                    '--on-secondary': theme.OnSecondary,
                    '--on-background': theme.OnBackground,
                    '--on-surface': theme.OnSurface,
                    '--on-error': theme.OnError,
                    '--title': theme.Title,
                    '--header': theme.Header,
                    '--sub-header': theme.SubHeader,
                    '--body': theme.Body,
                    '--grey1': theme.grey1,
                    '--grey2': theme.grey2,
                    '--grey3': theme.grey3,
                    '--grey4': theme.grey4,
                    '--grey5': theme.grey5,
                    '--grey6': theme.grey6,
                    '--grey7': theme.grey7,
                    '--primary-dark': theme.PrimaryDark,
                    '--on-primary-dark': theme.OnPrimaryDark,
                    '--hti-primary-light': theme.htiPrimaryLight,
                    '--hti-primary-dark': theme.htiPrimaryDark,
                    '--hti-primary-variant': theme.htiPrimaryVariant,
                    '--hti-secondary': theme.htiSecondary,
                    '--hti-secondary-variant': theme.htiSecondaryVariant,
                    '--modal-background': theme.modalBackground
                }
            })
        );
    }, [themeState]);

    useEffect(() => {
        if (authen.authToken) checkRedirect(authen.authToken);
    }, [authen.authToken]);

    useEffect(() => {
        const regexPrefix = /ago/; //! fix prefix by paan
        const regexMatch = window.location.href.match(regexPrefix);

        if (regexMatch) {
            const isDev = process.env.REACT_APP_ENV === 'development';
            window.location.href = isDev
                ? `https://dev-elibrary-oag.hibrary.me/`
                : `https://elibrary-oag.hibrary.me/`;
        }
    }, [clientInfo, location]);

    useEffect(() => {
        if (query.authenUserToken && query.ref) {
            checkAutoLogin();
        }
    }, [query.authenUserToken, query.ref]);

    useEffect(() => {
        if (dayjs().unix() > clientInfo.expireDate || !authen.authToken) getClientInfo();
        setDeviceUID();

        const handleScroll = (el: any) => {
            const target = el.target.scrollingElement as HTMLDivElement;
            const scrollTop = Math.floor(target.scrollTop);
            const offsetScrollHeight = Math.floor(
                (target.scrollHeight - target.offsetHeight) / 1.2
            );
            if (scrollTop > offsetScrollHeight) {
                dispatch(environment.action.setEndOfScreen(true));
            } else {
                dispatch(environment.action.setEndOfScreen(false));
            }

            if (scrollTop > 400) {
                dispatch(environment.action.showScrollToTop());
                dispatch(environment.action.hideOpenAppTab());
            } else {
                dispatch(environment.action.hideScrollToTop());
                dispatch(environment.action.showOpenAppTab());
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const checkAutoLogin = async () => {
        if (query.authenUserToken && query.authenUserToken !== '') {
            try {
                const token = await getTokenByHash(
                    query.authenUserToken as string,
                    query.ref as string
                );
                jwtDecode(token.data?.userToken as string);
                setupAxiosByToken(token.data?.userToken as string);
                dispatch(actions.login(token.data?.userToken as string));
                history.push({ pathname: '/', search: '' });
            } catch (error) {
                console.error(error);
            }
        } else {
            if (authen.authToken !== '') {
                setupAxiosByToken(authen.authToken);
            }
        }
    };

    const setDeviceUID = async () => {
        localStorage.setItem('deviceuid', await ip);
    };

    const getClientInfo = async () => {
        let prefix = process.env.REACT_APP_MAIN_PREFIX ?? 'hti';
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        const getPrefix = getDomainPrefix();
        const regex = new RegExp(`${process.env.REACT_APP_HIBRARY_CU_ELIBRARY_ENDPOINT}`, 'i');

        if (getPrefix) prefix = getPrefix;

        if (window.location.hostname.match(regex)) {
            dispatch(actions.setIsCuDomain(true));
        } else {
            dispatch(actions.setIsCuDomain(false));
        }

        if (
            authen.userInfo?.clientPrefix &&
            (query.authenUserToken === null || query.authenUserToken === undefined)
        ) {
            prefix = authen.userInfo?.clientPrefix;
        }

        if (
            clientInfo.companyPrefix == '' ||
            clientInfo.expireDate < dayjs().unix() ||
            clientInfo.companyPrefix !== prefix ||
            clientInfo.storeStatus === StoreStatus.Trial
        ) {
            try {
                const response = await fetchClientInfo(prefix);
                dispatch(setTheme(response.theme!));
                dispatch(setClientInfo(response));
                if (response.storeStatus === StoreStatus.Trial) modalInfo();
            } catch (error) {
                const err = error as AxiosError<HtiErrorResponse>;
                if (err.response?.data.errorCode === '009') {
                    dispatch(setTheme(clientInfoMock.theme!));
                    dispatch(setClientInfo(clientInfoMock));
                    history.push('/error/not-found-organization');
                }
            }
        } else {
            dispatch(setTheme(clientInfo.theme!));
        }
    };

    const modalInfo = () => {
        Modal.info({
            title: 'เริ่มทดลองใช้งาน',
            wrapClassName: 'trial-store',
            content: (
                <p>หนังสืออิเล็กทรอนิกส์ที่แสดงเป็นเพียงตัวอย่างสำหรับการทดลองใช้งานเท่านั้น</p>
            )
        });
    };

    const checkRedirect = (userToken: string) => {
        const userInfo = getUserInfo(userToken);
        const getPrefix = getDomainPrefix();
        if (
            authen.authToken !== undefined &&
            getPrefix !== undefined &&
            authen.userInfo !== undefined &&
            authen.userInfo?.clientPrefix !== undefined &&
            authen.userInfo?.clientPrefix !== getPrefix &&
            userInfo.clientPrefix !== getPrefix
        ) {
            const isDevPath = window.location.origin.includes('dev-');
            const webState = isDevPath ? 'dev-' : '';
            const reg = new RegExp(
                `elibrary-([A-z0-9]+)\.${getDomainName(window.location.hostname)}}`
            );
            if (window.location.origin.match(reg)) {
                const newLocation = window.location.origin.replace(
                    reg,
                    `${webState}elibrary-${userInfo.clientPrefix}.${getDomainName(
                        window.location.hostname
                    )}`
                );
                window.location.href = `${newLocation}`;
            } else {
                window.location.href = `https://${webState}elibrary-${userInfo.clientPrefix}.hibrary.me`;
            }
        }
    };

    const RouteMemo = useMemo(() => {
        return <>{clientInfo.clientName !== '' ? <Routes /> : null}</>;
    }, [clientInfo, authen]);

    return (
        <>
            <GlobalStyle />
            <SplashLoading />
            <RentalModal />
            <CookieTab />
            {RouteMemo}
        </>
    );
};

export const getDomainPrefix = () => {
    const regMatch = new RegExp(
        `elibrary-([A-z0-9]+)\.${getDomainName(window.location.hostname)}$`
    );
    const domainName = window.location;
    const prefix = domainName.hostname.match(regMatch);
    if (window.location.host === 'bkk.hibrary.me') return 'bkk';
    return prefix ? prefix[1] : undefined;
};

export const getDomainName = (hostName: string) => {
    return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
};
