import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BreadCrumbView, BreadcrumbLink } from '../../components/breadcrumb';
import { action } from '../../redux/request';
import { removeSplash } from '../../helpers/removeSplash';
import { getSubCategoryContent } from '../../redux/categories/categoriesCrud';
import { getListSubInCategoryRoute } from '../../routes/generatePath';
import { MemoContentCollectionView } from '../../components/contents';
import { EmptyDataView } from '../../components/nodata';
import { Loading } from '../../customUI/loading';
import { Helmet } from 'react-helmet-async';
import {
    ContentType,
    LicenseType,
    ListAllFreeContentResponse,
    ListAllRentContentResponse,
    ListContentByCreatorResponse,
    ListContentByWriterResponse
} from '../../../interface';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
    findEbook,
    getEbookListByCreator,
    getEbookListByKeyword,
    getEbookListByWriter,
    getEbookListByWriterById
} from '../../redux/store/storeCrud';

export interface ViewAllContentProps {
    contentType: ContentType;
    licenseType: LicenseType;
    subcatId?: string;
    catId?: string;
    creatorUID?: string;
    writerNameOrId?: string;
    keyword?: string;
}

type ResponseModel =
    | ListAllRentContentResponse
    | ListAllFreeContentResponse
    | ListContentByCreatorResponse
    | ListContentByWriterResponse;

export const ViewAllContent = (props: ViewAllContentProps) => {
    const pageId = 'ViewAllContent';
    const dispatch = useDispatch();
    const [contentList, setContentList] = useState<ResponseModel>();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const getInitTitle = () => {
        const title = props.writerNameOrId
            ? `อีบุ๊กของ "${props.writerNameOrId}"`
            : props.keyword
            ? `คำสำคัญ "${props.keyword}"`
            : '';
        return title;
    };
    const [title, setTitle] = useState<string>(getInitTitle());

    const { environment, clientInfo, authen, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        setTitle(getInitTitle());
        fetchContent(false);
    }, []);

    useEffect(() => {
        if (
            environment.endOfScreen &&
            contentList?.lastEvaluatedKey !== undefined &&
            !request.loading
        ) {
            fetchContent(true);
        }
    }, [environment]);

    const fetchListContent = async (appendData = true) => {
        setTitle('ดูทั้งหมด');
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData
                ? contentList?.lastEvaluatedKey
                : undefined;
            const data = (await findEbook(
                props.contentType,
                props.licenseType,
                lastEvaluatedKey,
                clientInfo.companyPrefix,
                !authen.isLogin
            )) as ListAllRentContentResponse;
            data.contents = data.ebooks
                ? data.ebooks
                : data.videos
                ? data.videos
                : data.podcasts
                ? data.podcasts
                : data.contents;
            if (appendData) {
                setListModel(data);
            } else {
                setContentList(data);
            }
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const fetchListContentByCreator = async (appendData = true) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData
                ? contentList?.lastEvaluatedKey
                : undefined;
            const data = await getEbookListByCreator(
                props.creatorUID ?? '',
                lastEvaluatedKey,
                props.licenseType,
                props.contentType,
                clientInfo.companyPrefix,
                !authen.isLogin
            );
            data!.contents = data?.ebooks
                ? data.ebooks
                : data?.videos
                ? data?.videos
                : data?.podcasts
                ? data?.podcasts
                : data?.contents ?? [];
            if (data?.title) {
                setTitle(data?.title ? `อีบุ๊กของ "${data?.title}"` : title);
            }

            if (appendData) {
                setListModel(data!);
            } else {
                setContentList(data);
                setBreadCrumbs([{ title: data?.title ?? '' }]);
            }
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const fetchListContentByKeyword = async (appendData = true) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData
                ? contentList?.lastEvaluatedKey
                : undefined;
            const data = await getEbookListByKeyword(
                props.keyword ?? '',
                lastEvaluatedKey,
                props.licenseType,
                props.contentType,
                clientInfo.companyPrefix,
                !authen.isLogin
            );
            data!.contents = data?.ebooks
                ? data.ebooks
                : data?.videos
                ? data?.videos
                : data?.podcasts
                ? data?.podcasts
                : data?.contents ?? [];
            if (data?.title) {
                setTitle(data?.title ? `อีบุ๊กของ "${data?.title}"` : title);
            }

            if (appendData) {
                setListModel(data!);
            } else {
                setContentList(data);
                setBreadCrumbs([{ title: data?.title ?? '' }]);
            }

            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const fetchListContentByWriter = async (appendData = true) => {
        try {
            let data: ListContentByWriterResponse | undefined;
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData
                ? contentList?.lastEvaluatedKey
                : undefined;
            if (props.licenseType === LicenseType.Free) {
                data = await getEbookListByWriterById(
                    props.writerNameOrId ?? '',
                    lastEvaluatedKey,
                    props.licenseType,
                    props.contentType,
                    props.creatorUID,
                    clientInfo.companyPrefix,
                    !authen.isLogin
                );
                if (data?.title) setTitle(data?.title);
            } else {
                data = await getEbookListByWriter(
                    props.writerNameOrId ?? '',
                    lastEvaluatedKey,
                    props.licenseType,
                    props.contentType,
                    clientInfo.companyPrefix,
                    !authen.isLogin
                );
            }

            data!.contents = data?.ebooks
                ? data.ebooks
                : data?.videos
                ? data?.videos
                : data?.podcasts
                ? data?.podcasts
                : data?.contents ?? [];
            if (appendData) {
                setListModel(data!);
            } else {
                setContentList(data);
                setBreadCrumbs([
                    { title: data?.title ?? props.writerNameOrId ?? '' }
                ]);
            }
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const fetchCategoriesContent = async (appendData = true) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = appendData
                ? contentList?.lastEvaluatedKey
                : undefined;
            const data = (await getSubCategoryContent(
                props.catId!,
                props.subcatId!,
                props.licenseType,
                props.contentType,
                lastEvaluatedKey,
                clientInfo.companyPrefix,
                authen.isLogin
            )) as ListAllRentContentResponse;

            data.contents = data.ebooks
                ? data.ebooks
                : data.videos
                ? data.videos
                : data.podcasts
                ? data.podcasts
                : data.contents;
            if (appendData) {
                setListModel(data);
            } else {
                if (typeof data?.title === 'string') {
                    setTitle(data?.title ?? '');
                } else {
                    setTitle(data?.title?.subcatName ?? '');
                    setBreadCrumbs([
                        {
                            title: data?.title?.catName ?? '',
                            path: getListSubInCategoryRoute(
                                props.catId!,
                                props.licenseType,
                                props.contentType
                            )
                        },
                        { title: data?.title?.subcatName ?? '' }
                    ]);
                }
                setContentList(data);
            }
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const fetchContent = (appendData = true) => {
        if (props.catId) {
            fetchCategoriesContent(appendData);
        } else if (props.creatorUID && !props.writerNameOrId) {
            fetchListContentByCreator(appendData);
        } else if (props.writerNameOrId) {
            fetchListContentByWriter(appendData);
        } else if (props.keyword) {
            fetchListContentByKeyword(appendData);
        } else {
            fetchListContent(appendData);
        }
    };

    const setListModel = (data: ResponseModel) => {
        switch (props.licenseType) {
            case LicenseType.Free: {
                const current = contentList as ListAllFreeContentResponse;
                const currentData = data as ListAllFreeContentResponse;
                setContentList({
                    contents: current.contents.concat(
                        currentData?.contents ?? []
                    ),
                    lastEvaluatedKey: currentData?.lastEvaluatedKey
                });
                break;
            }
            default: {
                const current = contentList as ListAllRentContentResponse;
                const currentData = data as ListAllRentContentResponse;
                setContentList({
                    contents: current.contents.concat(
                        currentData?.contents ?? []
                    ),
                    lastEvaluatedKey: currentData?.lastEvaluatedKey
                });
                break;
            }
        }
    };

    const getListContent = () => {
        switch (props.licenseType) {
            case LicenseType.Free: {
                const data = (contentList as ListAllFreeContentResponse) ?? [];
                return data.contents?.map((e, index) => (
                    <div key={index}>
                        <MemoContentCollectionView
                            content={e}
                            licenseType={props.licenseType}
                        />
                    </div>
                ));
            }
            default: {
                const data = contentList as ListAllRentContentResponse;
                const listData = data?.ebooks ?? data?.contents ?? [];
                return listData.map((e, index) => (
                    <div key={index}>
                        <MemoContentCollectionView
                            content={e}
                            licenseType={props.licenseType}
                        />
                    </div>
                ));
            }
        }
    };

    const generateClassName = () => {
        switch (props.contentType) {
            case ContentType.ebook:
                return `content-list-container-ebook`;
            case ContentType.video:
                return `content-list-container-video`;
            case ContentType.podcast:
                return `content-list-container-podcast`;
            default:
                return `content-list-container-ebook`;
        }
    };

    const breadcrumbs = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    return (
        <>
            {breadcrumbs}
            <div className='store view-all-content'>
                <Helmet>
                    <title>
                        {window.location.hostname.match(/cu-elibrary.com/)
                            ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                            : 'Hibrary'}{' '}
                        | {title ?? 'All E-book'}
                    </title>
                </Helmet>
                <h1 className='viewAllTitle'>{title ?? 'All E-book'}</h1>
                <div className='content-list'>
                    <div className={generateClassName()}>
                        {contentList && contentList.contents.length > 0
                            ? getListContent()
                            : !request.loading && <EmptyDataView />}
                    </div>
                </div>
                <Loading id={pageId} />
            </div>
        </>
    );
};
