import { Button, Modal, ModalProps } from 'antd';
import './index.scss';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { HibraryRootState } from '../../../../redux/rootReducer';
import CUPolicy from './CUPolicy';
import HibraryPolicy from './HibraryPolicy';

interface PrivacyPolicyModalProps {
    visible: boolean;
    handleCheckPrivacy: () => void;
    handleVisible: (visible: boolean) => void;
}

const PrivacyPolicyModal = (props: PrivacyPolicyModalProps) => {
    const [isEndScroll, setIsEndScroll] = useState<boolean>(false);
    const { authen } = useSelector((state: HibraryRootState) => ({
        authen: state.auth
    }));
    const modalProps: ModalProps = {
        open: props.visible,
        onCancel: () => props.handleVisible(false),
        footer: null,
        className: 'privacy-policy-modal',
        width: 800
    };

    const handleEndScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        console.log(clientHeight, scrollTop, scrollHeight - 30);

        if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight - 50)) {
            setIsEndScroll(true);
        } else {
            setIsEndScroll(false);
        }
    };

    const renderPolicy = () => {
        if (authen.isCuDomain) return <CUPolicy />;
        else return <HibraryPolicy />;
    };

    return (
        <Modal {...modalProps}>
            <div className='title-container'>
                <h1>นโยบายความเป็นส่วนตัว</h1>
                <p>กรุณาเลื่อนลงเพื่ออ่านข้อมูลทั้งหมด ก่อนกดยอมรับ</p>
            </div>
            <div className='content-container' onScroll={handleEndScroll}>
                {renderPolicy()}
            </div>
            <div className='button-container'>
                <Button
                    shape='round'
                    size='large'
                    disabled={!isEndScroll}
                    onClick={() => {
                        props.handleCheckPrivacy();
                        props.handleVisible(false);
                    }}
                >
                    ยอมรับ
                </Button>
            </div>
        </Modal>
    );
};

export default PrivacyPolicyModal;
