import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';
import { removeSplash } from '../../helpers/removeSplash';
import { getListSearch } from '../../redux/store/storeCrud';
import { HibraryRootState } from '../../../redux/rootReducer';
import { SearchResultResponse } from '../../../interface/request';
import { Content, ContentType, LicenseType } from '../../../interface/home';
import { ContentCollectionView } from '../../components/contents';
import { EmptyDataView } from '../../components/nodata';
import { SegmentButton } from '../../components/bookShelfSegmented';
// import { Segmented } from 'antd';

interface SearchResultProps {
    keyword: string;
}

enum SearchResultViewType {
    Library,
    PrivateLibrary
}

export const SearchResult = (props: SearchResultProps) => {
    const pageId = 'SearchResult';
    const location = useLocation();
    const dispatch = useDispatch();
    const [resultType, setResultType] = useState(SearchResultViewType.Library);
    const [resultList, setResultList] = useState<SearchResultResponse>();

    const { clientInfo, authen } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchResult();
    }, [location]);

    const fetchResult = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const data = await getListSearch(
                props.keyword.trim(),
                clientInfo.companyPrefix,
                !authen.isLogin
            );
            setResultList(data);
            if (
                data?.hibraryResult.contents.length === 0 &&
                data?.privateResult.contents.length !== 0
            ) {
                setResultType(SearchResultViewType.PrivateLibrary);
            }
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const onSelectedSegment = (index: number) => {
        setResultType(index);
    };

    return (
        <div className='store view-all-content'>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}{' '}
                    | ผลการค้นหา {props.keyword}
                </title>
            </Helmet>
            <h1 className='viewAllTitle'>ผลการค้นหา {props.keyword}</h1>
            <SegmentButton
                buttonTitles={['ห้องสมุด', 'สื่อองค์กร']}
                defaultIndex={0}
                onSelected={onSelectedSegment}
            />

            {resultType === SearchResultViewType.Library ? (
                <SearchResultListView
                    contents={resultList?.hibraryResult.contents}
                    licenseType={LicenseType.Rent}
                    contentType={ContentType.ebook}
                />
            ) : (
                <SearchResultListView
                    contents={resultList?.privateResult.contents}
                    licenseType={LicenseType.Private}
                    contentType={ContentType.ebook}
                />
            )}
            <Loading id={pageId} />
        </div>
    );
};

interface SearchResultListViewProps {
    contents?: Content[];
    contentType: ContentType;
    licenseType: LicenseType;
}

const SearchResultListView = (props: SearchResultListViewProps) => {
    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request: state.fetch
        }),
        shallowEqual
    );

    const generateClassName = () => {
        switch (props.contentType) {
            case ContentType.ebook:
                return `content-list-container-ebook`;
            case ContentType.video:
                return `content-list-container-video`;
            case ContentType.podcast:
                return `content-list-container-podcast`;
            default:
                return `content-list-container-ebook`;
        }
    };

    const renderContent = () => {
        if (props.contents && props.contents.length > 0) {
            return (
                <div className='content-list'>
                    <div className={generateClassName()}>
                        {props.contents?.map((content, index: number) => (
                            <ContentCollectionView
                                key={`${content.contentUID}_${index}`}
                                content={content}
                                licenseType={content.licenseType}
                            />
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                !request.loading && (
                    <div className='content-list flex-center '>
                        <EmptyDataView />
                    </div>
                )
            );
        }
    };

    return <>{renderContent()}</>;
};
