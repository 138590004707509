import { Banner, Home } from '../../../interface/home';
export interface HomeState {
   banners?: Banner[];
   listHomeHibrary?: Home[];
   listHomeFree?: Home[];
   listHomePrivate?: Home[];
   listHomeContent?: Home[];
   isReset?: boolean;
}
const initialState: HomeState = {
   isReset: false,
   banners: [],
   listHomeHibrary: undefined,
   listHomeFree: undefined,
   listHomePrivate: undefined,
   listHomeContent: undefined
};
export enum HomeActionType {
   SetHibrary = 'SetHome[Hibrary]',
   SetFree = 'SetHome[Free]',
   SetPrivate = 'SetHome[Private]',
   SetBanner = 'SetBanner[Banner]',
   ResetData = 'ResetData[ResetData]',
   SetHomeContent = 'SetHome[Content]'
}
export interface HomeAction {
   type: HomeActionType;
   payload?: HomeState;
}
export const reducer = (state = initialState, action: HomeAction): HomeState => {
   switch (action.type) {
      case HomeActionType.SetHibrary:
         return {
            ...state,
            isReset: false,
            listHomeHibrary: action.payload?.listHomeHibrary ?? []
         };

      case HomeActionType.SetFree:
         return {
            ...state,
            isReset: false,
            listHomeFree: action.payload?.listHomeFree ?? []
         };
      case HomeActionType.SetPrivate:
         return {
            ...state,
            isReset: false,
            listHomePrivate: action.payload?.listHomePrivate ?? []
         };
      case HomeActionType.SetHomeContent:
         return {
            ...state,
            isReset: false,
            listHomeContent: action.payload?.listHomeContent ?? []
         };

      case HomeActionType.SetBanner:
         return {
            ...state,
            isReset: false,
            banners: action.payload?.banners ?? []
         };

      case HomeActionType.ResetData:
         return {
            isReset: true,
            banners: [],
            listHomeHibrary: undefined,
            listHomeFree: undefined,
            listHomePrivate: undefined,
            listHomeContent: undefined
         };
      default:
         return state;
   }
};

const setBanner = (banners: Banner[]): HomeAction => {
   return {
      type: HomeActionType.SetBanner,
      payload: { banners }
   };
};
const setHomeHibrary = (home: Home[]): HomeAction => {
   return {
      type: HomeActionType.SetHibrary,
      payload: { listHomeHibrary: home }
   };
};
const setHomeFree = (home: Home[]): HomeAction => {
   return {
      type: HomeActionType.SetFree,
      payload: { listHomeFree: home }
   };
};
const setHomePrivate = (home: Home[]): HomeAction => {
   return {
      type: HomeActionType.SetPrivate,
      payload: { listHomePrivate: home }
   };
};
const setHomeContent = (home: Home[]): HomeAction => {
   return {
      type: HomeActionType.SetHomeContent,
      payload: { listHomeContent: home }
   };
};

const resetData = (): HomeAction => {
   return {
      type: HomeActionType.ResetData
   };
};
export const action = {
   setBanner,
   setHomeHibrary,
   setHomePrivate,
   setHomeFree,
   resetData,
   setHomeContent
};
