/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { action } from '../../redux/request';
import { chkLastEvaluateKey } from '../../redux/utilities';
import { removeSplash } from '../../helpers/removeSplash';
import { Helmet } from 'react-helmet-async';
// import { Row, Col } from 'antd';
import { BreadCrumbView } from '../../components/breadcrumb';
import { Loading } from '../../customUI/loading';
import { ContentCover, ContentMetaView } from '../../components/contents';
import { RentBtn } from '../../customUI/RentButton';
import { EmptyDataView } from '../../components/nodata';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
  Content,
  ContentDetail,
  HtiResponse,
  LicenseType,
  RentalStatus,
  HistoryListResponse,
  HistoryContent,
  ContentType
} from '../../../interface';

import axios from 'axios';
import queryString from 'query-string';
import { Button, Space } from 'antd';
import ReadingStatModal from '../../components/modal/reading-stat/reading-stat';
import { useIntl } from 'react-intl';
import { formatDate } from '../../helpers/changeFormatDateTime';

export const HistoryPage = () => {
  const pageId = 'HistoryPage';
  const intl = useIntl();
  const titleText = intl.formatMessage({ id: 'BorrowingHistory.Title' });
  const dispatch = useDispatch();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [contentList, setContentList] = useState<HistoryListResponse>();

  const { environment, request } = useSelector(
    (state: HibraryRootState) => ({
      environment: state.environment,
      request: state.fetch
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadingPage(false);
    fetchData();
  }, []);

  useEffect(() => {
    if (
      environment.endOfScreen &&
      contentList?.lastEvaluatedKey &&
      !request.loading
    ) {
      fetchData();
    }
  }, [environment]);

  const reqFunc = async () => {
    dispatch(action.setProcess(true, pageId));
    const qString: any = chkLastEvaluateKey(contentList?.lastEvaluatedKey);
    const {
      data: { data }
    } = await axios.get<HtiResponse<HistoryListResponse>>(
      queryString.stringifyUrl({
        url: '/licenses/histories',
        query: qString
      })
    );
    const currentList = contentList?.ebooks ?? [];
    setContentList({
      ebooks: currentList.concat(data!.ebooks),
      lastEvaluatedKey: data?.lastEvaluatedKey
    });
    dispatch(action.setProcess(false, pageId));
    setLoadingPage(true);
  };

  const fetchData = async () => {
    try {
      dispatch(removeSplash);
      reqFunc();
    } catch (error) {
      dispatch(action.setProcess(false, pageId));
      dispatch(removeSplash);
    }
  };

  return (
    <>
      <div
        className={`qNh${
          contentList && contentList?.ebooks.length === 0 ? ' emptyView' : ''
        }`}
      >
        <Helmet>
          <title>
            {window.location.hostname.match(/cu-elibrary.com/)
              ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
              : 'Hibrary'}{' '}
            | {titleText}
          </title>
        </Helmet>
        <BreadCrumbView />
        <h1>{titleText}</h1>
        <Loading id={pageId} />
        {contentList && contentList?.ebooks.length > 0 ? (
          <div className="gridContainer">
            {contentList.ebooks.map((e) => (
              <MemoHistoryView content={e} key={e.licenseUID} />
            ))}
          </div>
        ) : (
          loadingPage && <EmptyDataView />
        )}
      </div>
    </>
  );
};

export const DateView = ({
  content,
  title,
  date,
  isRent = false
}: {
  content: Content | HistoryContent;
  title: string;
  date: string;
  isRent?: boolean;
}) => {
  return (
    <>
      <div className="headDate">{title}</div>
      <div className={isRent ? `date` : `date ${content.rentalStatus}`}>
        {date}
      </div>
    </>
  );
};

interface HistoryViewParam {
  content: HistoryContent;
}

export const HistoryView = (props: HistoryViewParam) => {
  const intl = useIntl();
  const content = props.content;
  const { contentType, licenseType } = props.content;
  const [statModalVisible, setStatModalVisible] = useState<boolean>(false);
  return (
    <div className="contentCover" key={content.contentUID}>
      <ContentCover
        content={content}
        licenseType={content.licenseType as LicenseType}
      />
      <div className="bookDetail">
        <Space direction="vertical" size={8}>
          <ContentMetaView
            content={content}
            licenseType={content.licenseType as LicenseType}
          />
          <div>
            <DateView
              title={intl.formatMessage({
                id: 'BorrowingHistory.BorrowedDate'
              })}
              date={formatDate(content.startDate, 'en', 'DD/MM/YYYY')}
              content={content}
              isRent={true}
            />
          </div>
          <div>
            <DateView
              title={
                content.rentalStatus === RentalStatus.Returned
                  ? intl.formatMessage({
                      id: 'BorrowingHistory.ReturnDate'
                    })
                  : intl.formatMessage({
                      id: 'BookShelf.ModalDueDate'
                    })
              }
              date={formatDate(
                content.rentalStatus === RentalStatus.Returned
                  ? content.returnDate ?? 0
                  : content.expireDate,
                'en',
                'DD/MM/YYYY'
              )}
              content={content}
            />
          </div>
        </Space>
        <Space size={8}>
          {!content.returnDate ? (
            <RentBtn
              content={content as unknown as ContentDetail}
              size="middle"
              contentType={contentType ?? ContentType.ebook}
              status={content.rentalStatus!}
              disabled={false}
              licenseType={content.licenseType as LicenseType}
              licenseUID={content.licenseUID}
            />
          ) : null}
          {(contentType ?? ContentType.ebook) === ContentType.ebook &&
            licenseType !== LicenseType.Sample && (
              <Button
                size="middle"
                shape="round"
                className="stat"
                onClick={() => setStatModalVisible(true)}
              >
                {intl.formatMessage({
                  id: 'BorrowingHistory.ReadingStatBtn'
                })}
              </Button>
            )}
        </Space>
      </div>
      {statModalVisible && (
        <ReadingStatModal
          visible={statModalVisible}
          onClose={() => setStatModalVisible(false)}
          licenseUID={content.licenseUID}
        />
      )}
    </div>
  );
};
const MemoHistoryView = memo(HistoryView);
