import { Divider, Modal, Space, Tooltip } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { UseFetchType } from '../../../../interface';
import { useEffect, useState } from 'react';
import { Sponsor } from '../../../../interface/sponsor';
import { getSponsorDetail } from '../../../redux/sponsor/request';
import { ModalLoading } from '../../../customUI/loading';
import { onErrorImage } from '../../../Utilities/imageUtilities';
import { EmptyDataView } from '../../nodata';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';
import './index.scss';

interface ModalProps {
    visible: boolean;
    onCancel: () => void;
}

const useFetchSponsorDetail = (
    uid: string,
    isOpen: boolean
): UseFetchType<Sponsor> => {
    const [data, setData] = useState<Sponsor>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const { authState, clientInfo } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo
        }),
        shallowEqual
    );

    useEffect(() => {
        const fetchSponsorDetail = async () => {
            try {
                setLoading(true);
                const response = await getSponsorDetail(
                    uid,
                    !authState.isLogin,
                    clientInfo.companyPrefix
                );
                if (response) {
                    setData(response);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) fetchSponsorDetail();
    }, []);

    return { data, loading, error };
};

const SponsorModal = (props: ModalProps) => {
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const params = new URLSearchParams(location.search);
    const uid = params.get('uid') || '';
    const { data, loading, error } = useFetchSponsorDetail(uid, props.visible);

    const { theme } = useSelector(
        (state: HibraryRootState) => ({
            theme: state.theme
        }),
        shallowEqual
    );

    const replaceUrl = () => {
        params.delete('uid');
        history.replace({
            search: params.toString()
        });
    };

    return (
        <Modal
            title={null}
            footer={null}
            open={props.visible}
            className='sponsorModal'
            width={900}
            onCancel={() => {
                props.onCancel();
                replaceUrl();
            }}
        >
            {data && !loading && (
                <>
                    <div className='coverImageContainer'>
                        <div className='coverImage'>
                            <img
                                src={data.coverImageWebp || data.coverImage}
                                onError={onErrorImage}
                            />
                        </div>
                    </div>
                    <div className='descriptionContainer'>
                        <h2 className='title'>{data.name}</h2>
                        <Divider
                            style={
                                theme.darkMode
                                    ? { border: '1px solid #fff' }
                                    : {}
                            }
                        />
                        <p className='description'>{data.description}</p>
                        <div className='addressData'>
                            <Space direction='vertical' size={4}>
                                <p>
                                    {intl.formatMessage({
                                        id: 'Sponsor.ModalEmail'
                                    })}
                                </p>
                                <p className='text'>{data.email}</p>
                            </Space>
                            <Space direction='vertical' size={4}>
                                <p>
                                    {intl.formatMessage({
                                        id: 'Sponsor.ModalPhone'
                                    })}
                                </p>
                                <p className='text'>{data.phone}</p>
                            </Space>

                            <Space
                                size={8}
                                className='iconContainer'
                                style={theme.darkMode ? { color: '#fff' } : {}}
                            >
                                {data.webpage && (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'Sponsor.ModalWebsite'
                                        })}
                                        placement='bottom'
                                    >
                                        <a target='_blank' href={data.webpage}>
                                            <SVG src='/images/icon/sponsorModal/websiteIcon.svg' />
                                        </a>
                                    </Tooltip>
                                )}
                                {data.facebook && (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'Sponsor.ModalFacebook'
                                        })}
                                        placement='bottom'
                                    >
                                        <a href={data.facebook} target='_blank'>
                                            <SVG src='/images/icon/sponsorModal/facebookIcon.svg' />
                                        </a>
                                    </Tooltip>
                                )}
                                {data.twitter && (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'Sponsor.ModalTwitter'
                                        })}
                                        placement='bottom'
                                    >
                                        <a href={data.twitter} target='_blank'>
                                            <SVG src='/images/icon/sponsorModal/twitterIcon.svg' />
                                        </a>
                                    </Tooltip>
                                )}
                                {data.instagram && (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'Sponsor.ModalInstagram'
                                        })}
                                        placement='bottom'
                                    >
                                        <a
                                            href={data.instagram}
                                            target='_blank'
                                        >
                                            <SVG src='/images/icon/sponsorModal/instagramIcon.svg' />
                                        </a>
                                    </Tooltip>
                                )}
                                {data.youtube && (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'Sponsor.ModalYouTube'
                                        })}
                                        placement='bottom'
                                    >
                                        <a
                                            href={data.instagram}
                                            target='_blank'
                                        >
                                            <SVG src='/images/icon/sponsorModal/youtubeIcon.svg' />
                                        </a>
                                    </Tooltip>
                                )}
                            </Space>
                        </div>
                    </div>

                    {loading && <ModalLoading />}
                    {error && <EmptyDataView />}
                </>
            )}
        </Modal>
    );
};

export default SponsorModal;
