import { useEffect, useState } from 'react';
import { Button, Modal, ModalProps } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchClientInfo } from '../../../../redux/clientInfo/request';
import { action } from '../../../../redux/request';
import { Loading } from '../../../../customUI/loading';
import { HibraryRootState } from '../../../../../redux/rootReducer';
import {
  AuthPageType,
  ClientInfo,
  LoginResponse,
  OtpPassword,
  OtpRegister
} from '../../../../../interface';
import Login from './Login';
import ForgotPassword from '../ForgotPassword';
import Register from './Register';
import RegisterForm from './RegisterForm';
import Success from '../Success';
import NewPassword from '../NewPassword';
import VerifyOTP from '../VerifyOTP';
import './loginModal.scss';
import { onErrorImage } from '../../../../Utilities/imageUtilities';

interface LoginModalProps {
  visible: boolean;
  onClose: () => void;
}

const LoginModal = (props: LoginModalProps) => {
  const pageID = 'LoginModal';
  const dispatch = useDispatch();
  const { clientInfo } = useSelector(
    (state: HibraryRootState) => ({
      clientInfo: state.clientInfo
      // request: state.fetch
    }),
    shallowEqual
  );

  const [client, setClient] = useState<ClientInfo>(clientInfo);
  const [otpData, setOTPData] = useState<OtpPassword | OtpRegister>();
  const [pageType, setPageType] = useState<AuthPageType>(AuthPageType.Login);
  const [currentLocation, setCurrentLocation] =
    useState<GeolocationCoordinates>();
  const [userTokenRegister, setUserTokenRegister] = useState<LoginResponse>();

  useEffect(() => {
    const getCurrentLocation = async () => {
      try {
        dispatch(action.setProcess(true, pageID));
        const position = await new Promise<GeolocationPosition>(
          (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          }
        );
        getClientInfo(position.coords);
        setCurrentLocation(position.coords);
      } finally {
        dispatch(action.setProcess(false, pageID));
      }
    };

    if (props.visible) getCurrentLocation();
  }, [props.visible]);

  const modalProps: ModalProps = {
    open: props.visible,
    onCancel: props.onClose,
    className: 'loginModal',
    footer: null,
    width: 450,
    afterClose: () => setPageType(AuthPageType.Login)
  };

  const getClientInfo = async (position: GeolocationCoordinates) => {
    try {
      const response = await fetchClientInfo(
        clientInfo.companyPrefix,
        position
      );
      if (response) setClient(response);
    } catch (error) {
      console.log(error);
    }
  };

  const onBack = () => {
    switch (pageType) {
      case AuthPageType.ForgotPassword:
        setPageType(AuthPageType.Login);
        break;
      case AuthPageType.Register:
        setPageType(AuthPageType.Login);
        break;
      case AuthPageType.VerifyOTP:
        setPageType(AuthPageType.Login);
        break;
      default:
        setPageType(AuthPageType.Login);
    }
  };

  const renderPage = () => {
    switch (pageType) {
      case AuthPageType.Login:
        return <Login setPageType={setPageType} clientInfo={client} />;
      case AuthPageType.Register:
        return (
          <Register
            clientInfo={client ?? clientInfo}
            location={currentLocation}
            setPageType={setPageType}
            setOTPData={setOTPData}
          />
        );

      case AuthPageType.ForgotPassword:
        return (
          <ForgotPassword
            setPageType={setPageType}
            setOTPData={setOTPData}
            onBack={() => onBack()}
          />
        );
      case AuthPageType.ForgotPasswordOTP:
      case AuthPageType.RegisterOTP:
        return (
          <VerifyOTP
            clientInfo={client ?? clientInfo}
            otp={otpData!}
            pageType={pageType}
            currentLocation={currentLocation!}
            setPageType={setPageType}
            setOTPData={setOTPData}
            onBack={() => setPageType(AuthPageType.Login)}
            onClose={props.onClose}
          />
        );
      case AuthPageType.NewPassword:
        return (
          <NewPassword
            clientInfo={clientInfo}
            otp={otpData!}
            verifyOTP={otpData?.verifyOtp ?? ''}
            setPageType={setPageType}
            onBack={() => setPageType(AuthPageType.Login)}
          />
        );
      case AuthPageType.RegisterForm:
        return (
          <RegisterForm
            otp={otpData as OtpRegister}
            verifyOTP={otpData?.verifyOtp ?? ''}
            referenceCode={otpData?.referenceCode ?? ''}
            location={currentLocation!}
            setPageType={setPageType}
            setUserTokenRegister={setUserTokenRegister}
          />
        );
      case AuthPageType.SuccessForgotPassword:
      case AuthPageType.SuccessRegister:
        return (
          <Success
            pageType={pageType}
            clientInfo={clientInfo}
            userTokenRegistered={userTokenRegister}
            onCloseModal={props.onClose}
            setPageType={setPageType}
          />
        );
    }
  };

  return (
    <Modal {...modalProps}>
      <img
        src={clientInfo.logoImages ?? '/images/login-sticker.svg'}
        alt=""
        onError={onErrorImage}
      />
      {renderPage()}
      <Loading absolute={true} id={pageID} />
    </Modal>
  );
};

export default LoginModal;
