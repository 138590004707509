import { ContentType, HomeSetType, LicenseType } from '../../interface';

export const getListCategoryRoute = (
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/categories/${contentType}/all`;
};

export const getListSubInCategoryRoute = (
   catId: number | string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/categories/${catId}/${contentType}/list`;
};

export const getSetContentRoute = (
   setType: HomeSetType,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/`;
};

export const getSetContentListRoute = (
   setUID: string,
   setType: HomeSetType,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/${setUID}`;
};

export const getListSubCategoryRoute = (
   catId: number | string,
   subcatId: number | string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/categories/${catId}/subcategories/${subcatId}/${contentType}/all`;
};

export const getListSetRoute = (
   setUID: string,
   setType: HomeSetType,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/sets/${setType.toLowerCase()}/${setUID}`;
};

export const getAllContentRoute = (
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/all/${contentType}`;
};

export const getWriterRoute = (
   writerName: string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/writer/${writerName}/${contentType}`;
};

export const getKeywordRoute = (
   keyword: string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/keyword/${keyword}/${contentType}`;
};

export const getCreatorRoute = (
   creatorUID: string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/creator/${creatorUID}/${contentType}`;
};

export const getDetailRoute = (
   contentUID: string,
   contentType: ContentType = ContentType.ebook,
   licenseType: LicenseType = LicenseType.Rent
) => {
   return `/${licenseType.toLowerCase()}/${contentType}/detail/${contentUID}`;
};

export const getHomeRoute = (
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook
) => {
   return `/${licenseType.toLowerCase()}/home/${contentType}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getSearchRoute = (keyword: string) => {
   return `/search/${keyword.trim()}`;
};

export const getWriterNameRoute = (
   writerName: string,
   licenseType: LicenseType = LicenseType.Rent,
   contentType: ContentType = ContentType.ebook,
   writerUID?: number,
   creatorUID?: string
) => {
   if (
      licenseType === LicenseType.Rent ||
      licenseType === LicenseType.Private
   ) {
      return `/${licenseType.toLowerCase()}/writer/${writerName}/${contentType}`;
   } else {
      return `/${licenseType.toLowerCase()}/writer/${writerUID}/${contentType}/${creatorUID}`;
   }
};

export const convertSetType = (setType: string): HomeSetType => {
   switch (setType) {
      case 'magazine':
         return HomeSetType.Magazine;
      case 'newspaper':
         return HomeSetType.Newspaper;
      default:
         return HomeSetType.eBook;
   }
};
