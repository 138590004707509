import { useEffect, useMemo, useState } from 'react';
import { Row, Divider, Menu, Dropdown } from 'antd';
import { PodcastPlayerView } from './PodcastPlayerView';
import { RentBtn } from '../../customUI/RentButton';
import { MemoPodcastEpisodeView } from './PodcastEpisodeView';
import { HtiResponse } from '../../../interface/request';
import { EmptyDataView } from '../../components/nodata';
import {
    ContentType,
    LicenseType,
    PodcastContent,
    PodcastEpisode,
    PodcastPlaylist
} from '../../../interface/home';
import axios from 'axios';
import parse from 'html-react-parser';
import { SegmentHeader } from '../home/segmentHeader';
import CoverView from '../../components/detailPage/CoverView';
import { TagsView } from '../../components/detailPage/TagsView';
import { RelatedView } from '../../components/detailPage/RelateView';
import { useIntl } from 'react-intl';

export interface PodcastViewParam {
    content: PodcastContent;
    licenseType?: LicenseType;
}

enum EpisodeDateSort {
    Ascending,
    Descending
}

export const PodcastDetailView = (props: PodcastViewParam) => {
    const intl = useIntl();
    const [currentEpisode, setCurrentEpisode] = useState<PodcastEpisode>();
    const [playlist, setPlaylist] = useState<string>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [sortBy, setSortBy] = useState(EpisodeDateSort.Descending);
    const podcast = props.content;

    useEffect(() => {
        console.log('<PodcastDetailView/>');
    });

    useEffect(() => {
        if (currentEpisode && isPlaying)
            getPlaylist(currentEpisode!.contentUID);
    }, [currentEpisode]);

    useEffect(() => {
        if (isPlaying) {
            if (
                !currentEpisode &&
                podcast.episodes &&
                podcast.episodes?.length > 0
            ) {
                const getLastEp = podcast.episodes![0];
                setCurrentEpisode(getLastEp);
            }
        }
    }, [isPlaying]);

    const getPlaylist = async (episodeUID: string) => {
        try {
            const {
                data: { data }
            } = await axios.post<HtiResponse<PodcastPlaylist>>(
                `/licenses/${podcast.contentUID}/podcasts`,
                {
                    licenseType: podcast.licenseType,
                    episodeUID: episodeUID
                }
            );
            setPlaylist(data!.playlist);
        } catch (error) {
            // showError
        }
    };

    const findIndexEP = (episode: PodcastEpisode) => {
        const index = podcast.episodes?.findIndex(
            (el) => el.contentUID === episode.contentUID
        );
        return index;
    };

    const goNext = (episode: PodcastEpisode) => {
        let index = findIndexEP(episode);
        if (index! < podcast.episodes!.length - 1 && index) {
            index++;
            const nextEp = podcast.episodes![index];
            setCurrentEpisode({ ...nextEp });
        }
    };

    const goBack = (episode: PodcastEpisode) => {
        let index = findIndexEP(episode);
        if (podcast.episodes && index !== undefined && index > 0) {
            index--;
            const lastEp = podcast.episodes![index];
            setCurrentEpisode({ ...lastEp });
        }
    };

    const dropdownBtn = (
        <Menu>
            <Menu.Item
                key='1'
                onClick={() => setSortBy(EpisodeDateSort.Ascending)}
            >
                <span className='dropdown-text no-pic'>
                    {intl.formatMessage({ id: 'Podcast.DateAddedOldest' })}
                </span>
            </Menu.Item>
            <Menu.Item
                key='2'
                onClick={() => setSortBy(EpisodeDateSort.Descending)}
            >
                <span className='dropdown-text no-pic'>
                    {intl.formatMessage({ id: 'Podcast.DateAddedLatest' })}
                </span>
            </Menu.Item>
        </Menu>
    );

    const sortWording = () => {
        switch (sortBy) {
            case EpisodeDateSort.Ascending:
                return intl.formatMessage({ id: 'Podcast.DateAddedLatest' });
            case EpisodeDateSort.Descending:
                return intl.formatMessage({ id: 'Podcast.DateAddedOldest' });
            default:
                break;
        }
    };

    const sortByFunc = () => {
        switch (sortBy) {
            case EpisodeDateSort.Ascending:
                return compareOldDate;
            case EpisodeDateSort.Descending:
                return compareNewDate;

            default:
                break;
        }
    };

    const compareOldDate = (a: any, b: any) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    };

    const compareNewDate = (a: any, b: any) => {
        if (a.title < b.title) {
            return 1;
        }
        if (a.title > b.title) {
            return -1;
        }
        return 0;
    };

    const playDidChange = (isPlaying: boolean) => {
        setIsPlaying(isPlaying);
    };
    const onSelectCurrentEpisode = (ep: PodcastEpisode, isPlaying: boolean) => {
        if (currentEpisode?.contentUID !== ep.contentUID) {
            setCurrentEpisode({ ...ep });
        }
        setIsPlaying(isPlaying);
    };

    const memoEpList = useMemo(() => {
        return (
            <>
                {podcast.episodes
                    ?.sort(sortByFunc())
                    .map((episode: PodcastEpisode, index) => (
                        <MemoPodcastEpisodeView
                            key={index}
                            podcast={props.content}
                            episode={episode}
                            isPlaying={
                                episode.contentUID ===
                                    currentEpisode?.contentUID && isPlaying
                                    ? true
                                    : false
                            }
                            currentEpisode={currentEpisode}
                            setCurrentEpisode={onSelectCurrentEpisode}
                        />
                    ))}
            </>
        );
    }, [currentEpisode, isPlaying, sortBy, props.content]);
    return (
        <>
            <div className='column'>
                <div className='leftDetail'>
                    <div>
                        <CoverView
                            hasLink={false}
                            contentUID={podcast.contentUID}
                            coverImage={podcast.coverImage}
                            contentType={
                                podcast.contentType ?? ContentType.podcast
                            }
                        />
                        <div className='title'>{podcast.title}</div>
                        <div className='subtitle'>{podcast.channelName}</div>
                        <div className={`columnButton noSample`}>
                            <div className='boxRentButton'>
                                <RentBtn
                                    content={podcast}
                                    size='small'
                                    contentType={podcast.contentType}
                                    status={podcast.rentalStatus!}
                                    style={{ height: 40 }}
                                    licenseType={podcast.licenseType}
                                />
                            </div>
                        </div>
                        <Divider />
                        <TagsView content={podcast} />
                    </div>

                    {podcast.description ? (
                        <div style={{ marginTop: '1rem' }}>
                            <SegmentHeader left='รายละเอียด' right={false} />
                            <div className='description'>
                                {parse(podcast.description)}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='rightDetail'>
                    <Row>
                        <PodcastPlayerView
                            podcast={props.content as PodcastContent}
                            autoPlaying={false}
                            isPlaying={isPlaying}
                            episode={currentEpisode}
                            playlist={playlist}
                            goBack={goBack}
                            goNext={goNext}
                            playDidChange={playDidChange}
                        />
                    </Row>
                    <div>
                        <div>
                            <SegmentHeader left='Episodes' right={false} />
                            <div className='flex'>
                                <div style={{ marginRight: '0.5rem' }}>
                                    {podcast.episodes?.length} Ep. •{' '}
                                    {podcast.duration}
                                </div>
                                <div>
                                    {intl.formatMessage({
                                        id: 'Podcast.SortBy'
                                    }) + ' : '}
                                    <Dropdown
                                        overlay={dropdownBtn}
                                        trigger={['click']}
                                        placement='bottomLeft'
                                    >
                                        <span className='blue'>{`${sortWording()} ∨`}</span>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        {podcast.episodes && podcast.episodes?.length > 0 ? (
                            memoEpList
                        ) : (
                            <EmptyDataView />
                        )}
                    </div>
                </div>
            </div>
            {podcast.relatedContents && podcast.relatedContents.length > 0 ? (
                <div>
                    <SegmentHeader
                        left={intl.formatMessage({
                            id: 'Podcast.RelatedPodcast'
                        })}
                        right={false}
                    />
                    <RelatedView content={podcast} />
                </div>
            ) : null}
        </>
    );
};
