/* eslint-disable @typescript-eslint/no-unused-vars */
import * as requestFromServer from './storeCrud';
import { ebooksSlice, callTypes } from './storeSlice';
import { ContentType, HomeResponse, LicenseType } from '../../../interface/home';
import * as homeRedux from '../home';
import { hideSplash } from '../../helpers/removeSplash';
import * as request from '../request';

const { actions } = ebooksSlice;
const isNil = (val: any) => val == null;

// export const fetchEbooks =
//   (
//     _contentType?: string,
//     _lastEvaluated?: any,
//     _licenseType?: string,
//     _authToken?: string,
//     _prefix?: string
//   ) =>
//   (_dispatch: (arg0: { payload: any; type: string }) => void) => {
//     //isAll is for dropdown
//     /*  dispatch(
//         actions.startCall({
//           callType: callTypes.list,
//           lastEvaluatedKey: lastEvaluated,
//         })
//       );
//       return requestFromServer
//         .findEbook(contentType, lastEvaluated, licenseType, authToken, prefix)
//         .then((response) => {
//           // console.log("lastEvaluated ", lastEvaluated);
//           const entities = response.data.data;
//           const listContents = entities.ebooks
//             ? entities.ebooks
//             : entities.contents;

//           dispatch(
//             actions.ebooksFetched({
//               // set payload //
//               callType: callTypes.list,
//               entities,
//               listContents,
//               lastEvaluatedKey: lastEvaluated,
//             })
//           );
//         })
//         .catch((error) => {
//           /*dispatch(
//           actions.ebooksFetched({
//             entities: [],
//             lastEvaluatedKey: undefined,
//           })
//         );*/
//     /*   error.clientMessage = "Can't find ebooks";
//           dispatch(actions.catchError({ error, callType: callTypes.list }));
//         });*/
//   };

export const fetchStoreHome =
   (
      id: string,
      prefix?: string,
      licenseType?: LicenseType,
      contentType?: ContentType,
      isLogin = true
   ) =>
   async (dispatch: (arg0: homeRedux.HomeAction | any) => void) => {
      dispatch(request.action.setProcess(true, id));
      try {
         let home: HomeResponse | undefined;
         switch (licenseType) {
            case LicenseType.Rent:
               if (isLogin) {
                  home = await requestFromServer.getStoreHome();
               } else {
                  home = await requestFromServer.getPublicHome(prefix);
               }
               if (!contentType || contentType === ContentType.content)
                  dispatch(homeRedux.action.setHomeHibrary(home?.home ?? []));
               else dispatch(homeRedux.action.setHomeContent(home?.home ?? []));
               break;
            case LicenseType.Free:
               home = await requestFromServer.getFreeHome(prefix, undefined, !isLogin);
               if (!contentType || contentType === ContentType.content)
                  dispatch(homeRedux.action.setHomeFree(home?.home ?? []));
               else dispatch(homeRedux.action.setHomeContent(home?.home ?? []));
               break;
            case LicenseType.Private:
               home = await requestFromServer.getPrivateHome(prefix, contentType, !isLogin);
               if (!contentType || contentType === ContentType.content)
                  dispatch(homeRedux.action.setHomePrivate(home?.home ?? []));
               else dispatch(homeRedux.action.setHomeContent(home?.home ?? []));
               break;
            default:
               break;
         }

         dispatch(homeRedux.action.setBanner(home?.banners ?? []));
         dispatch(hideSplash());
         dispatch(request.action.setProcess(false, id));
      } catch (error) {
         dispatch(hideSplash());
         dispatch(request.action.setProcess(false, id));
      }
   };

export const fetchEbookDetail =
   (
      uid: string,
      licenseType?: string,
      _authToken?: string,
      _prefix?: string,
      noReload?: boolean,
      resolve?: any,
      reject?: any
   ) =>
   (dispatch: (arg0: { payload: any; type: string }) => void) => {
      //isAll is for dropdown
      if (!noReload) dispatch(actions.startCall({ callType: callTypes.detail }));
      return requestFromServer
         .getEbookDetail(uid, licenseType)
         .then((response: any) => {
            const entities = response.data.data;
            dispatch(
               actions.detailFetched({
                  entities
               })
            );
            if (noReload) setTimeout(resolve, 1000);
         })
         .catch((error) => {
            dispatch(
               actions.detailFetched({
                  entities: {}
               })
            );
            error.clientMessage = "Can't find ebooks";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
            if (noReload) setTimeout(reject, 1000);
         });
   };

export const fetchEbookSubcategory =
   (
      catId: string,
      subcatId: string,
      contentType: string,
      lastEvaluated?: any,
      licenseType?: string,
      _authToken?: string,
      _prefix?: string
   ) =>
   (dispatch: (arg0: { payload: any; type: string }) => void) => {
      //isAll is for dropdown
      dispatch(
         actions.startCall({
            callType: callTypes.list,
            lastEvaluatedKey: lastEvaluated
         })
      );
      return requestFromServer
         .getEbookList(catId, subcatId, contentType, lastEvaluated, licenseType)
         .then((response) => {
            const entities = response.data.data;
            const listContents = entities.ebooks ? entities.ebooks : entities.contents;

            dispatch(
               actions.ebooksFetched({
                  entities,
                  listContents,
                  lastEvaluatedKey: lastEvaluated
               })
            );
         })
         .catch((error) => {
            dispatch(
               actions.ebooksFetched({
                  entities: [],
                  lastEvaluatedKey: undefined
               })
            );
            error.clientMessage = "Can't find ebooks";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
         });
   };

export const fetchEbookByCreator =
   (
      _creatorUID: string,
      lastEvaluated?: any,
      _licenseType?: string,
      _authToken?: string,
      _prefix?: string
   ) =>
   (dispatch: (arg0: { payload: any; type: string }) => void) => {
      //isAll is for dropdown
      dispatch(
         actions.startCall({
            callType: callTypes.list,
            lastEvaluatedKey: lastEvaluated
         })
      );
      return requestFromServer;
      /* .getEbookListByCreator(
        creatorUID,
        lastEvaluated,
        licenseType,
        authToken,
        prefix
      )
      .then((response) => {
        const entities = response.data.data;
        const listContents = entities.ebooks
          ? entities.ebooks
          : entities.contents;

        dispatch(
          actions.ebooksFetched({
            entities,
            listContents,
            lastEvaluatedKey: lastEvaluated,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actions.ebooksFetched({
            entities: [],
            lastEvaluatedKey: undefined,
          })
        );
        error.clientMessage = "Can't find ebooks";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });*/
   };
export const fetchEbookBySetId =
   (
      _setId: string,
      _setType: string,
      _lastEvaluated?: any,
      _licenseType?: string,
      _authToken?: string,
      _prefix?: string
   ) =>
   (dispatch: (arg0: { payload: any; type: string }) => void) => {
      //isAll is for dropdown
      dispatch(actions.startCall({ callType: callTypes.list }));
      return requestFromServer;
      /*.getListContentSets(setId, setType, lastEvaluated, authToken, prefix) //, lastEvaluated, licenseType)
        .then((response) => {
          const entities = response.data.data;
          const listContents = entities.ebooks
            ? entities.ebooks
            : entities.contents;
        
          dispatch(
            actions.ebooksFetched({
              entities,
              listContents,
              lastEvaluatedKey: lastEvaluated,
            })
          );
        })
        .catch((error) => {
          dispatch(
            actions.ebooksFetched({
              entities: [],
              lastEvaluatedKey: undefined,
            })
          );
          error.clientMessage = "Can't find ebooks";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
        });*/
   };

export const fetchContactList =
   () => (_dispatch: (arg0: { payload: any; type: string }) => void) => {
      return requestFromServer
         .getContactList()
         .then((response) => {
            if (response.data.status && response.data.data) {
               const contactList = response.data.data.contactList;
            }
         })
         .catch((_err) => console.log(_err));
   };

export const fetchIsPublicRegister =
   (prefix: string, removeSplash: any) =>
   (dispatch: (arg0: { payload: any; type: string }) => void) => {
      return requestFromServer
         .getIsPublicRegister(prefix)
         .then((response) => {
            if (response.data.status && response.data.data) {
               console.log(response.data);
            }
         })
         .catch((_err) => {
            if (removeSplash) {
               dispatch(removeSplash);
            }
         });
   };
