import { useEffect, useMemo } from 'react';
import { NavBar } from '../components/navbar/NavBar';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { removeSplash } from '../helpers/removeSplash';
import { message } from 'antd';
import { BrowserView, isMobile, isIE } from 'react-device-detect';
import { HibraryRootState } from '../../redux/rootReducer';
import axios from 'axios';

import BasePage from '../pages/base/BasePage';
import Footer from '../components/footer/Footer';
import moment from 'moment';
import qs from 'qs';
import InternetExplorerView from './internetExplorerView';
import MobileViewPage from './mobileView';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

message.config({
    top: 50,
    duration: 2,
    maxCount: 1
});

const Routes = () => {
    const dispatch = useDispatch();

    const { authen } = useSelector(
        (state: HibraryRootState) => ({
            authen: state.auth
        }),
        shallowEqual
    );

    const basePageMemoAuth = useMemo(() => {
        return <BasePage />;
    }, [authen]);

    useEffect(() => {
        if (isIE) dispatch(removeSplash);
    }, []);

    useEffect(() => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (!authen.authToken && localStorage.getItem('readingNow-Hibrary')) {
            localStorage.removeItem('readingNow-Hibrary');
        }
        if (!authen.authToken && localStorage.getItem('lastCountTime')) {
            localStorage.removeItem('lastCountTime');
        }
        if (!authen.authToken && localStorage.getItem('bookmark-Hibrary')) {
            localStorage.removeItem('bookmark-Hibrary');
        }
        if (
            authen.authToken &&
            (query.authenUserToken === undefined || query.authenUserToken === null)
        ) {
            const countPeopleApi = async () => {
                const fetchApi = await axios.post('/statistics/login');
                if (fetchApi.data.status) {
                    localStorage.setItem('lastCountTime', moment().unix().toString());
                }
            };
            if (!localStorage.getItem('lastCountTime')) {
                countPeopleApi();
            } else if (localStorage.getItem('lastCountTime')) {
                if (
                    parseInt(localStorage.getItem('lastCountTime') as string, 10) + 3600 <
                    moment().unix()
                ) {
                    countPeopleApi();
                }
            }
        }
    }, [authen.authToken]);

    const renderPage = () => {
        if (isIE) {
            return <InternetExplorerView isCuDomain={authen.isCuDomain ?? false} />;
        } else if (isMobile) {
            return <MobileViewPage />;
        } else {
            return (
                <BrowserView>
                    <Switch>
                        <Route path='/'>
                            <NavBar />
                            {basePageMemoAuth}
                            <Footer />
                        </Route>
                    </Switch>
                </BrowserView>
            );
        }
    };

    return <>{renderPage()}</>;
};

export default Routes;
