import { Link } from 'react-router-dom';
import { DetailViewParam } from '../../../interface/detailPage';
import { useIntl } from 'react-intl';

const PublisherView = (props: DetailViewParam) => {
    const content = props.content;
    const intl = useIntl();

    return (
        <>
            {intl.formatMessage({ id: 'Book.Publisher' }) + ' : '}
            <Link
                to={{
                    pathname: `/${
                        content.licenseType
                            ? content.licenseType.toLowerCase()
                            : 'private'
                    }/creator/${content.creatorUID}/ebook`
                }}
            >
                {content.publisherName}
            </Link>
        </>
    );
};

export default PublisherView;
