import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { NotificationActionType } from '.';
import { action } from '../request';
import { getNotification } from './request';

export const fetchNotificationData = () => async (dispatch: Dispatch<AnyAction>) => {
   try {
      dispatch(action.setProcess(true));
      const response = await getNotification();
      if (response) {
         dispatch({
            type: NotificationActionType.fetchNotificationData,
            payload: response.data
         });
      }
   } catch (error) {
      dispatch(action.setProcess(false));
   } finally {
      dispatch(action.setProcess(false));
   }
};
