import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbLink, BreadCrumbView } from '../../../components/breadcrumb';
import { HomeSet, HomeSetType, LicenseType } from '../../../../interface/home';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { ContentSetView } from '../../../components/store';
import { removeSplash } from '../../../helpers/removeSplash';
import { getListSets, getListContentSets } from '../../../redux/store/storeCrud';
import { Loading } from '../../../customUI/loading';
import { action } from '../../../redux/request';
import { ContestSetListResponse } from '../../../../interface/request';
import { ContentCollectionView } from '../../../components/contents';

import './index.scss';
import { useIntl } from 'react-intl';

interface SetsPageProps {
    setType: HomeSetType;
    licenseType: LicenseType;
}

export const SetsPage = (props: SetsPageProps) => {
    const pageId = 'SetsPage';
    const setType = props.setType;
    const setTypeName = getSetTypeName(setType);
    const dispatch = useDispatch();
    const [listSets, setListSet] = useState<HomeSet[]>([]);
    const { clientInfo, authen } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchListSets();
    }, []);

    const fetchListSets = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const data = await getListSets(setType, clientInfo.companyPrefix, authen.isLogin);
            setListSet(data?.sets ?? []);
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    return (
        <div className='categories'>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}
                    | {setTypeName}
                </title>
            </Helmet>
            <BreadCrumbView />
            <>
                <h1>{setTypeName}</h1>
                <div className='content-list'>
                    <Row gutter={[16, 16]} className='list-row-categories'>
                        {listSets.map((e: HomeSet, index: number) => (
                            <Col key={`col_${e.uid}_${index}`} className='col-category'>
                                <ContentSetView
                                    set={e}
                                    setType={setType as any}
                                    setTypeName={setTypeName}
                                    licenseType={LicenseType.Rent}
                                    prefix={clientInfo.companyPrefix}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </>
            <Loading id={pageId} />
        </div>
    );
};

export const getSetTypeName = (type: HomeSetType) => {
    const intl = useIntl();
    switch (type) {
        case HomeSetType.Magazine:
            return intl.formatMessage({ id: 'ContentType.Magazine' });
        case HomeSetType.Newspaper:
            return intl.formatMessage({ id: 'ContentType.Newspaper' });
        case HomeSetType.eBook:
            return intl.formatMessage({ id: 'ContentType.Ebook' });
        default:
            return intl.formatMessage({ id: 'ContentType.Magazine' });
    }
};

interface SetsContentListPageProps {
    setType: HomeSetType;
    setUID: string;
    licenseType: LicenseType;
}

export const SetsContentListPage = (props: SetsContentListPageProps) => {
    const pageId = 'SetsContentListPage';
    const setType = props.setType;
    const setId = props.setUID;
    const dispatch = useDispatch();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
    const [title, setTitle] = useState<string>('');
    const [contentSet, setContentSet] = useState<ContestSetListResponse>();
    const { clientInfo, authen, environment, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        fetchListContent(false);
    }, []);

    useEffect(() => {
        if (environment.endOfScreen && !request.loading) {
            if (contentSet?.lastEvaluatedKey !== undefined) {
                fetchListContent(true);
            }
        }
    }, [environment]);

    const fetchListContent = async (appendData: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const data = await getListContentSets(
                setId,
                setType,
                contentSet?.lastEvaluatedKey,
                clientInfo.companyPrefix,
                authen.isLogin
            );
            if (appendData) {
                setListModel(data!);
            } else {
                if (contentSet) {
                    setContentSet({
                        contents: contentSet.contents.concat(data?.contents ?? []),
                        lastEvaluatedKey: data?.lastEvaluatedKey
                    });
                } else {
                    setTitle(data?.title ?? '');
                    setContentSet({ ...data! });
                    setBreadCrumbs([{ title: data?.title ?? '' }]);
                }
            }
            dispatch(removeSplash);
            dispatch(action.setProcess(false, pageId));
        } catch (error) {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    const breadcrumbsMemo = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    const setListModel = (data: ContestSetListResponse) => {
        const current = contentSet as ContestSetListResponse;
        const currentData = data as ContestSetListResponse;
        setContentSet({
            contents: current.contents.concat(currentData?.contents ?? []),
            lastEvaluatedKey: currentData?.lastEvaluatedKey
        });
    };

    return (
        <>
            <Helmet>
                <title>
                    {window.location.hostname.match(/cu-elibrary.com/)
                        ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                        : 'Hibrary'}{' '}
                    | {title ?? ''}
                </title>
            </Helmet>
            {breadcrumbsMemo}
            <div className='store view-all-content'>
                <>
                    <h1 className='viewAllTitle'>{title ?? ''}</h1>
                    <div className='bookLayout collection' style={{ marginBottom: '2rem' }}>
                        {contentSet?.contents.map((el, index) => {
                            return (
                                <Col key={`set_content_${index}`} className='col-ebook'>
                                    <ContentCollectionView
                                        content={el}
                                        licenseType={LicenseType.Rent}
                                    />
                                </Col>
                            );
                        })}
                    </div>
                </>
                <Loading id={pageId} />
            </div>
        </>
    );
};
