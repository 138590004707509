import { ReadingGoalPresetResponse } from './../../../interface/request';
import axios from 'axios';
import { genRanHex } from '../utilities';
import {
   GenerateContentResponse,
   GetDownloadUrlResponse,
   HtiResponse,
   License,
   LicenseResponse,
   LicenseType,
   ReadingStatResponse,
   ReadingTimeResponse
} from '../../../interface';

export const getReadingTime = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<ReadingTimeResponse>>('/statistics');
   return data;
};

export const getLicense = async () => {
   const {
      data: { data }
   } = await axios.get<HtiResponse<LicenseResponse>>('/licenses');

   return data;
};

export const getReaderContentUrl = async (clientUID: string, license: License) => {
   const {
      data: { data }
   } = await axios.post<HtiResponse<GetDownloadUrlResponse>>(
      `/licenses/${license.contentUID}/download`,
      {
         licenseType: license.licenseType,
         licenseUID: license.licenseUID,
         clientUID: clientUID
      }
   );
   return data;
};

export const generateContent = async (
   clientUID: string,
   license: License,
   requestURL: string,
   clientKey: string,
   encryptKey: string = genRanHex(16)
) => {
   const url =
      license.licenseType === LicenseType.Sample
         ? `${requestURL}?licenseUID=${license.licenseUID}`
         : requestURL;
   const payload =
      license.licenseType === LicenseType.Sample
         ? undefined
         : {
              fileType: license.fileType,
              encryptKey: encryptKey,
              clientKey,
              contentUID: license.contentUID,
              licenseType: license.licenseType,
              licenseUID: license.licenseUID,
              clientUID
           };
   const {
      data: { data }
   } = await axios.post<HtiResponse<GenerateContentResponse>>(url, payload);
   return data;
};

export const getReadingStat = async (licenseUID: string) => {
   try {
      const { data } = await axios.get<HtiResponse<ReadingStatResponse>>(
         `/licenses/${licenseUID}/readingStat`
      );
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const getReadingGoalPreset = async () => {
   try {
      const {
         data: { data }
      } = await axios.get<HtiResponse<ReadingGoalPresetResponse>>(`/licenses/readingGoal`);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const setReading = async (second: number) => {
   try {
      const payload = {
         readingGoal: second
      };
      const { data } = await axios.post<HtiResponse>(`/licenses/readingGoal`, payload);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};
