export const compareAddedDate = (a: any, b: any) => b.startDate - a.startDate;

export const compareExpiredDate = (a: any, b: any) =>
   b.expireDate - a.expireDate;

export const compareTitle = (a: any, b: any) => a.title.localeCompare(b.title);

export const compareAuthorName = (a: any, b: any) => {
   const nameA = a.authorName ?? a.channelName;
   const nameB = b.authorName ?? b.channelName;
   return nameA.localeCompare(nameB);
};

export const compareReadingTime = (a: any, b: any) => {
   if (b.lastReading > a.lastReading) {
      return 1;
   } else if (b.lastReading < a.lastReading) {
      return -1;
   } else {
      return 0;
   }
};
