import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SponsorContentSet } from '../../../../interface/sponsor';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { ContentCollectionView } from '../../../components/contents';
import { EmptyDataView } from '../../../components/nodata';
import { ComponentLoading } from '../../../customUI/loading';
import { removeSplash } from '../../../helpers/removeSplash';
import { action } from '../../../redux/request';
import { getSponsorContentSet } from '../../../redux/sponsor/request';
import './index.scss';

interface SponsorSetEbookProps {
   setUID: string;
}

const SponsorSetEbook = (props: SponsorSetEbookProps) => {
   const pageId = 'sposorContentSetPage';
   const dispatch = useDispatch();
   const [contentSets, setContentSets] = useState<SponsorContentSet>();

   const { request, authen, clientInfo } = useSelector(
      (state: HibraryRootState) => ({
         request: state.fetch,
         authen: state.auth,
         clientInfo: state.clientInfo
      })
   );

   useEffect(() => {
      fetchContentSet();
   }, []);

   const fetchContentSet = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const response = await getSponsorContentSet(
            props.setUID,
            !authen.isLogin,
            clientInfo.companyPrefix
         );
         if (response) {
            setContentSets(response);
            dispatch(action.setProcess(false, pageId));
         }
      } catch (error) {
         console.log(error);
      } finally {
         dispatch(action.setProcess(false, pageId));
         dispatch(removeSplash);
      }
   };

   return (
      <div className='sponsorSetContainer'>
         <h1>{`${contentSets?.title ?? ''}`}</h1>
         {!request.loading && contentSets ? (
            <div className='contentContainer'>
               {contentSets?.contents.map((el) => (
                  <ContentCollectionView
                     content={el}
                     licenseType={el.licenseType}
                  />
               ))}
            </div>
         ) : null}
         {request.loading && <ComponentLoading id={pageId} />}
         {(contentSets == undefined || contentSets.contents.length <= 0) ?? (
            <EmptyDataView />
         )}
      </div>
   );
};

export default SponsorSetEbook;
