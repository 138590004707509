import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../redux/auth/authCrud';
import { showSplash, removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { action } from '../../redux/home';
import { Loading } from '../../customUI/loading';
import * as auth from '../../redux/auth/authRedux';
import * as request from '../../redux/request';
import qs from 'qs';

const LogoutSSO = () => {
   const pageId = 'logoutSSO';
   const query = qs.parse(location.search, { ignoreQueryPrefix: true });
   const dispatch = useDispatch();
   const history = useHistory();
   const { authState } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth
      }),
      shallowEqual
   );

   useEffect(() => {
      dispatch(showSplash());
   }, []);

   useEffect(() => {
      dispatch(request.action.setProcess(true, pageId));
      if (authState.authToken !== undefined) {
         logout()
            .then(() => {
               dispatch(request.action.setProcess(false, pageId));
               removeSplash(dispatch);
               dispatch(auth.actions.logout());
               dispatch(action.resetData());
               history.push(`${query.currentPath ?? '/'}`);
            })
            .catch(() => {
               dispatch(request.action.setProcess(false, pageId));
               dispatch(auth.actions.logout());
               dispatch(action.resetData());
               history.push(`${query.currentPath ?? '/'}`);
               removeSplash(dispatch);
            });
      } else {
         history.push(`/`);
      }
   }, [dispatch, history]);

   return (
      <div>
         <Loading id={pageId} />
      </div>
   );
};
export default LogoutSSO;
