import { Row } from 'antd';
import { ContentDetail } from '../../../interface';
import { DetailViewParam } from '../../../interface/detailPage';
import EbookDetailNumber from './EbookDetailNumber';
import { useIntl } from 'react-intl';

export const FileDetailView = (props: DetailViewParam) => {
    const content = props.content;
    const intl = useIntl();

    const showFileSize = (content: ContentDetail) => {
        return !isNaN(content.fileSize)
            ? content.fileSize >= 100.0
                ? `${Number(content.fileSize).toFixed(0)}MB`
                : `${Number(content.fileSize).toFixed(2)}MB`
            : '-';
    };

    return (
        <Row gutter={[0, 0]}>
            <EbookDetailNumber
                title={intl.formatMessage({ id: 'Book.FileSize' })}
                number={`${showFileSize(content as ContentDetail)}`}
            />
            <EbookDetailNumber
                title={intl.formatMessage({ id: 'Book.FileType' })}
                number={`${
                    content.fileType ? content.fileType.toUpperCase() : ''
                }`}
            />
            <EbookDetailNumber
                title={intl.formatMessage({ id: 'Book.Pages' })}
                number={`${content.pageCount}`}
            />
        </Row>
    );
};
