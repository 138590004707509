import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ClientInfo, StoreStatus } from '../../../interface';
import { HibraryRootState } from '../../../redux/rootReducer';

import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import AddNewLibraryModal from '../modal/authModal/addLibraryModal/AddLibraryModal';

const UserInfo = () => {
    const intl = useIntl();
    const [isModalSelectLIB, setIsModalSelectLIB] = useState<boolean>(false);
    const { clientInfo, authState } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo as ClientInfo,
            theme: state.theme
        }),
        shallowEqual
    );

    const checkStoreExpire = (storeStatus: StoreStatus) => {
        switch (storeStatus) {
            case StoreStatus.Activate:
                if (clientInfo.clientExpireDate && clientInfo.clientExpireDate < dayjs().unix())
                    return ` (${intl.formatMessage({
                        id: 'StoreStatus.Expire'
                    })})`;
                else return ``;
            case StoreStatus.Trial:
                return ` (${intl.formatMessage({ id: 'StoreStatus.Trial' })})`;
            default:
                return ``;
        }
    };

    // const SelectLibraryMemo = useMemo(() => {
    //     return (
    //         <AuthPage
    //             initType={AuthPageType.SelectLibrary}
    //             onCloseModal={() => setIsModalSelectLIB(false)}
    //         />
    //     );
    // }, [isModalSelectLIB]);

    return (
        <>
            <div className='userInfo'>
                <img
                    className={authState.isLogin ? `logo cursor` : `logo`}
                    src={clientInfo.logoImages}
                    onClick={() => {
                        if (authState.authToken) setIsModalSelectLIB(true);
                    }}
                />

                <div className='div-text'>
                    {/*  displayTitle_line1 */}
                    <h1 className='top-text text-overflow-one-line'>
                        {clientInfo.customTitle && clientInfo.customTitle.line1
                            ? clientInfo.customTitle.line1
                            : intl.formatMessage({ id: 'Library.Title' })}
                        {checkStoreExpire(clientInfo.storeStatus ?? StoreStatus.Trial)}
                    </h1>

                    {/*  displayTitle_line2 */}
                    {clientInfo.customTitle && clientInfo.customTitle.line2 ? (
                        <h3
                            className={
                                clientInfo.customTitle.line3
                                    ? 'text-overflow-one-line  under-text'
                                    : 'text-overflow-two-line under-text'
                            }
                        >
                            {clientInfo.customTitle.line2}
                        </h3>
                    ) : (
                        <h3 className='under-text text-overflow-two-line'>
                            {clientInfo.displayName.replace(/^ห้องสมุด/g, '')}
                        </h3>
                    )}

                    {/*  displayTitle_line3 */}
                    {clientInfo.customTitle && clientInfo.customTitle.line3 && (
                        <h3 className='under-text text-overflow-one-line'>
                            {clientInfo.customTitle.line3}
                        </h3>
                    )}
                </div>
            </div>
            {/* 
            <Modal
                destroyOnClose
                footer={null}
                width={620}
                visible={isModalSelectLIB}
                onCancel={() => setIsModalSelectLIB(false)}
                maskClosable={false}
            >
                {SelectLibraryMemo}
            </Modal> */}

            <AddNewLibraryModal
                visible={isModalSelectLIB}
                onClose={() => setIsModalSelectLIB(false)}
            />
        </>
    );
};

export default UserInfo;
