import './termOfService.scss';
import { useDispatch } from 'react-redux';
import {
    getPrivacyPolicy,
    getTermsOfUse,
    PrivacyPolicyResponse,
    TermsOfUseResponse
} from '../../redux/termsOfService/request';
import { useEffect, useState } from 'react';
import { action } from '../../redux/request';
import parse from 'html-react-parser';
import { ComponentLoading, Loading } from '../../customUI/loading';

interface ComponentProps {
    pageId: string;
}

export const TermsOfUse = () => {
    const pageId = 'TernsOfUse';
    const dispatch = useDispatch();
    const [term, setTerm] = useState<TermsOfUseResponse>();

    useEffect(() => {
        fetchTermOfUse();
    }, []);

    const fetchTermOfUse = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getTermsOfUse();
            if (response) {
                setTerm(response);
            }
        } finally {
            dispatch(action.setProcess(false, pageId));
        }
    };

    return <div>{parse(term?.termOfUse || '')}</div>;
    // return (
    //     <div className='term-of-use-container'>
    //         <div>
    //             <h1 style={{ textAlign: 'center' }}>เงื่อนไขการใช้บริการ</h1>
    //             <p>
    //                 โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน
    //                 หลังจากลงทะเบียนเสร็จสมบูรณ์แล้ว
    //                 ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างผู้ใช้งานกับ บริษัท ไฮเท็คซ์
    //                 อินเตอร์แอคทีฟ จำกัด หรือ Hibrary
    //             </p>
    //             <p>โดยทั้งสองฝ่ายตกลงทำข้อตกลงกัน ซึ่งมีข้อความดังต่อไปนี้</p>
    //         </div>

    //         <div>
    //             <h1>เงื่อนไขและข้อตกลงในการใช้บริการ Hibrary</h1>
    //             <p>
    //                 โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน
    //                 หลังจากที่การลงทะเบียนเสร็จสมบูรณ์แล้ว
    //                 ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างสมาชิก กับ บริษัท ไฮเท็คซ์
    //                 อินเตอร์แอคทีฟ จำกัด ผู้ให้บริการ Hibrary
    //             </p>
    //             <p>ทั้งสองฝ่ายตกลงทำข้อตกลงกันดังมีข้อความดังต่อไปนี้</p>
    //         </div>

    //         <div>
    //             <h1>หนังสืออิเล็กทรอนิกส์ (E-BOOK)</h1>
    //             <ol>
    //                 <li>
    //                     หนังสืออิเล็กทรอนิกส์ที่เผยแพร่ใน Hibrary จะถูกเข้ารหัสเพื่อจัดการลิขสิทธ์
    //                     (Digital Right Management)
    //                 </li>
    //                 <li>
    //                     สมาชิกจะต้องสมัคร Adobe ID ก่อนที่จะดาวน์โหลดหนังสืออิเล็กทรอนิกส์
    //                     เพื่อที่จะสามารถนำไปอ่านในอุปกรณ์พกพาอื่นๆได้ เช่น iPad iPhone Android
    //                     e-Reader Pc และ Mac
    //                 </li>
    //                 <li>สมาชิกสามารถ Authorize อุปกรณ์พกพาได้สูงสุด 5 อุปกรณ์ ต่อ 1 Adobe ID</li>
    //                 <li>
    //                     หนังสืออิเล็กทรอนิกส์ และ ตัวอย่างหนังสืออิเล็กทรอนิกส์ ที่ถูกจัดจำหน่ายโดย
    //                     Hibrary ห้ามทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ จัดจำหน่าย โดยไม่ได้รับอนุญาตจาก
    //                     Hibrary
    //                 </li>
    //             </ol>
    //         </div>

    //         <div>
    //             <h1>บัญชีผู้ใช้งาน</h1>
    //             <ol>
    //                 <li>
    //                     ผู้ที่ต้องการสมัครสมาชิก ต้องกรอกข้อมูลของตนเองที่เป็นจริงให้แก่ Hibrary
    //                     ให้ครบทุกข้อ
    //                 </li>
    //                 <li>
    //                     ผู้ใดแอบอ้าง หรือกระทำการใดๆ อันเป็นการละเมิดสิทธิ์ส่วนบุคคล
    //                     โดยใช้ข้อมูลของผู้อื่นมาแอบอ้างสมัครสมาชิกเพื่อให้ได้สิทธิ์การสมาชิก
    //                     ถือเป็นความผิด ต้องรับโทษตามที่กฎหมายกำหนดไว้
    //                 </li>
    //                 <li>
    //                     เพื่อความเป็นส่วนตัวและความปลอดภัยในข้อมูลของท่านเอง Hibrary
    //                     ขอแจ้งให้ทราบว่า เป็นหน้าที่ของสมาชิกในการรักษาข้อมูลชื่อ Login และ Password
    //                     โดยไม่บอกให้ผู้อื่นทราบ
    //                 </li>
    //                 <li>
    //                     สมาชิก สามารถเปลี่ยนแปลงรหัสผ่าน (Password)
    //                     ด้วยตนเองได้ตลอดระยะเวลาการใช้งาน โดยไม่ต้องแจ้งให้ Hibrary ทราบ
    //                     ทั้งนี้สมาชิก ตกลงให้รหัสผ่าน (Password) ที่เปลี่ยนแปลงไปในแต่ละขณะดังกล่าว
    //                     เป็นรหัสผ่านการใช้บริการของ Hibrary ที่มีผลผูกพันสมาชิก
    //                     ตามข้อกำหนดและเงื่อนไขการใช้บริการนี้ทุกประการ
    //                 </li>
    //                 <li>
    //                     การกระทำการใดๆ โดยผ่านบริการ ไม่ว่ากรณีใดๆก็ตาม
    //                     หากกระทำโดยใช้รหัสสมาชิกและรหัสผ่านของสมาชิกและ/หรือตรงตามวิธีการหรือเงื่อนไขที่บริษัทกำหนดไว้เกี่ยวกับการใช้บริการ
    //                     Hibrary แล้ว สมาชิกตกลงให้ถือว่าการกระทำนั้นมีผลสมบูรณ์
    //                     โดยสมาชิกไม่ต้องลงลายมือชื่อเป็นหลักฐานในเอกสารใดๆ อีก
    //                     และยินยอมรับผิดชอบในการกระทำดังกล่าวเสมือนหนึ่งว่าสมาชิกเป็นผู้กระทำด้วยตนเอง
    //                     ทั้งนี้ไม่ว่าจะเกิดขึ้นด้วยความทุจริตของบุคคลภายนอกหรือไม่ก็ตาม
    //                     สมาชิกจะไม่เรียกร้องให้บริษัทต้องรับผิดชอบแต่อย่างใด
    //                 </li>
    //                 <li>
    //                     รหัสผ่าน (Password)
    //                     ถือเป็นความลับเฉพาะตัวของสมาชิกที่ต้องเก็บรักษาและปกปิดมิให้ผู้อื่นล่วงรู้
    //                     สมาชิกต้องเก็บรักษาไว้ด้วยตนเอง หากมีการใช้บริการรายการใดๆโดยใช้รหัสผ่าน
    //                     (Password) ของสมาชิกแล้ว
    //                     ให้ถือว่าถูกต้องสมบูรณ์มีผลผูกพันสมาชิกเสมือนสมาชิกใช้บริการในครั้งนั้นๆ
    //                     ด้วยตนเอง ทั้งนี้ บริษัทไม่สามารถล่วงรู้รหัสผ่าน (Password) ของสมาชิกได้
    //                     ดังนั้น บริษัทจึงไม่ต้องรับผิดชอบในความเสียหายใดๆที่เกิดขึ้นในการใช้บริการ
    //                     Hibrary โดยรหัสผ่าน (Password) ของสมาชิก
    //                 </li>
    //                 <li>
    //                     สมาชิกจำเป็นต้องตรวจสอบความถูกต้องในการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์ว่าถูกต้องหรือไม่
    //                     ก่อนที่จะทำการตกลงชำระเงิน
    //                     ซึ่งถ้าหากเกิดข้อผิดพลาดจากการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์อันเนื่องมาจากความไม่ถูกต้องของรายการเนื่องมาจากความผิดพลาดของสมาชิกเอง
    //                     ทาง Hibrary จะไม่รับผิดชอบทั้งสิ้น
    //                 </li>
    //                 <li>
    //                     ถ้า Hibrary
    //                     พบว่าสมาชิกนำหนังสืออิเล็กทรอนิกส์และตัวอย่างหนังสืออิเล็กทรอนิกส์ของ
    //                     Hibrary ไปทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ หรือจัดจำหน่าย โดยไม่ได้รับอนุญาตจาก
    //                     Hibrary ทีมงานมีสิทธิ์ยกเลิกความเป็นสมาชิกที่ใช้บริการ Hibraryได้โดยทันที
    //                 </li>
    //                 <li>
    //                     หนังสืออิเล็กทรอนิกส์ที่สมาชิกสั่งใช้บริการ ห้ามทำซ้ำหรือดัดแปลง
    //                     ห้ามให้เช่าต้นฉบับหรือสำเนางาน ห้ามเผยแพร่งานต่อสาธารณชน
    //                     หากผู้ใดกระทำการดังกล่าว ถือเป็นการละเมิดลิขสิทธิ์
    //                     ต้องรับโทษอาญาและจ่ายค่าเสียหายแก่เจ้าของลิขสิทธิ์และ Hibrary ด้วย
    //                     หากผู้ใดรู้อยู่แล้วหรือมีเหตุอันควรรู้ว่างานใดได้ทำขึ้นโดยละเมิดลิขสิทธิ์ของผู้อื่น
    //                     กระทำการต่อไปนี้แก่งานนั้นเพื่อหากำไร
    //                     ให้ถือว่าผู้นั้นกระทำละเมิดลิขสิทธิ์ด้วย ได้แก่
    //                     <ol>
    //                         <li>
    //                             ขาย มีไว้เพื่อขาย เสนอขาย ให้เช่า เสนอให้เช่า ให้เช่าซื้อ
    //                             หรือเสนอให้เช่าซื้อ
    //                         </li>
    //                         <li>เผยแพร่ต่อสาธารณชน</li>
    //                         <li>แจกจ่ายในลักษณะที่อาจก่อให้เกิดความเสียหายแก่เจ้าของลิขสิทธิ์</li>
    //                     </ol>
    //                 </li>
    //                 <li>
    //                     สมาชิกจะต้องไม่โพสต์ข้อความที่มีเนื้อหาดังต่อไปนี้ที่ Hibrary
    //                     <ol>
    //                         <li>ไม่โพสต์ข้อความซึ่งพาดพิงหรือลบหลู่ สถาบันพระมหากษัตริย์</li>
    //                         <li>
    //                             ไม่โพสต์ข้อมูลหยาบคาบ หมิ่นประมาณ
    //                             หรือสร้างความเสียหายต่อบุคคลหนึ่งบุคคลใด
    //                         </li>
    //                     </ol>
    //                 </li>
    //                 <li>
    //                     Hibrary จะไม่รับผิดชอบต่อความสูญเสียในด้านข้อมูลสมาชิก ข้อมูลการสั่งซื้อ
    //                     และข้อมูลทุกๆอย่างภายในเว็บไซต์หากมีการสูญหาย
    //                     จากการถูกจารกรรมความผิดพลาดจากระบบของเครื่องแม่ข่ายที่เก็บข้อมูลหรือความผิดพลาดที่เกิดจากระบบเว็บไซต์ทั้งสิ้น
    //                 </li>
    //                 <li>
    //                     หากพบว่าสมาชิกกระทำการที่ผิดเงื่อนไขและข้อตกลงกับ Hibrary
    //                     ทีมงานมีสิทธิ์ที่จะยกเลิกความเป็นสมาชิกได้โดยทันที
    //                 </li>
    //             </ol>
    //         </div>

    //         <div>
    //             <h1>ข้อกำหนดเนื้อหาของการใช้งานสื่อองค์กร</h1>
    //             <p>
    //                 Hibrary ไม่มีหน้าที่ตรวจสอบ เนื้อหา เนื้อเรื่อง ภาพประกอบ ลิขสิทธิ์
    //                 หรือส่วนหนึ่งส่วนใด ของหนังสืออิเล็กทรอนิกส์ ไฟล์เสียง หรือไฟล์วีดีโอ
    //                 ที่ผู้ใช้บริการนำส่งข้อมูล
    //                 ดังนั้นผู้ใช้บริการตกลงว่าจะไม่นำงานวรรณกรรมที่มีเนื้อหา เนื้อเรื่อง
    //                 ภาพประกอบหรือส่วนหนึ่งส่วนใดดังต่อไปนี้ให้ Hibrary จัดจำหน่าย โฆษณาและเผยแพร่ ใน
    //                 Hibrary
    //             </p>
    //             <ol>
    //                 <li>
    //                     งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาท หรือแสดงความอาฆาตมาดร้าย
    //                     พาดพิงสถาบันพระมหากษัตริย์ รัชทายาทราชวงศ์ หรือผู้สำเร็จราชการแทนพระองค์
    //                     เป็นอันขาด
    //                 </li>
    //                 <li>
    //                     งานวรรณกรรมที่กล่าวโจมตี หรือวิพากษ์วิจารณ์ในทางเสียหายต่อศาสนา
    //                     หรือคำสอนของศาสนาใดๆ ทุกศาสนา
    //                 </li>
    //                 <li>
    //                     งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคลที่มีต่อบุคคลอื่นหรือหน่วยงานใด
    //                 </li>
    //                 <li>งานวรรณกรรมที่คัดลอกผลงาน หรือบทความบนเว็บไซต์ของบุคคลอื่น</li>
    //                 <li>งานวรรณกรรมที่มีเนื้อหาละเมิดกฎหมาย หรือสนับสนุนกิจกรรมที่ผิดกฎหมาย</li>
    //                 <li>
    //                     งานวรรณกรรมที่มีเนื้อหามีภาพประกอบหรือภาพบุคคลอื่นที่มีลักษณะหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคล
    //                 </li>
    //                 <li>
    //                     งานวรรณกรรมที่มีเนื้อหามีการโฆษณาในวัตถุประสงค์
    //                     เพื่อใช้ในการโฆษณางานวรรณกรรมเดียวกันที่จัดจำหน่ายในที่อื่นนอกเหนือจาก
    //                     Hibrary
    //                 </li>
    //                 <li>
    //                     งานวรรณกรรมที่มี ตัวอักษร หน้าปก หรือ
    //                     ภาพประกอบที่ละเมิดลิขสิทธิ์ของบุคคลอื่น
    //                 </li>
    //             </ol>
    //         </div>

    //         <div>
    //             <h1>ขั้นตอนการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย</h1>
    //             <p>
    //                 หากพบว่ามีผลงานที่เข้าข่ายผิดกฎหมาย
    //                 สำนักงานจะดำเนินการระงับการเผยแพร่และแจ้งให้เจ้าของผลงานทราบ
    //                 เพื่อดำเนินการแก้ไขต่อไป
    //                 และหากพบว่าเจ้าของผลงานมีการส่งผลงานที่ผิดกฎหมายเข้ามาซ้ำซาก
    //                 สำนักงานจะดำเนินการระงับบัญชีผู้ใช้ของเจ้าของผลงานต่อไป
    //                 ผู้ที่ประสงค์จะให้สำนักงานในฐานะผู้ให้บริการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย
    //                 สามารถดำเนินการแจ้งสำนักงานได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที่
    //             </p>
    //             <p>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด เลขที่ 3</p>
    //             <ol style={{ listStyle: 'none', paddingLeft: '0' }}>
    //                 <li>อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว 3</li>
    //                 <li>ถนนลาดพร้าว แขวงจอมพล เขตจตุจักร กทม 10900 โทร. 02-024-6690</li>
    //                 <li>Customer Service : 089-134-7470</li>
    //                 <li>E-mail : contact@hytexts.com</li>
    //                 <li>ช่วงเวลาทำการ: จันทร์-ศุกร์ 09:00-18:00 น.</li>
    //             </ol>
    //             <p>บริษัทจะดำเนินการและตอบกลับภายใน 7 วันทำการ</p>
    //         </div>

    //         <div>
    //             <h1>การจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม</h1>
    //             <p>
    //                 บริษัทฯ
    //                 มีหลักเกณฑ์ในการจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม
    //                 โดยคำนึงถึง ปัจจัยหลักในการจัดอันดับขายดี คือ ยอดขาย และ
    //                 ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ
    //                 ขึ้นกับความเกี่ยวข้องของหนังสือที่ผู้ใช้บริการมี
    //                 ซึ่งแพลตฟอร์มจะแบ่งเนื้อหาออกเป็น 3 ประเภท โดย
    //             </p>
    //             <ol style={{ listStyle: 'none', paddingLeft: '0', marginBottom: '0' }}>
    //                 <li>- ประเภทแรก คือ เนื้อหาทั่วไปที่สามารถเข้าถึงได้บนหน้าเว็บไซต์</li>
    //                 <li>
    //                     - ประเภทสอง คือ เนื้อหาที่เหมาะกับผู้ใช้งานที่มีอายุ 18 ปีขึ้นไป
    //                     โดยจะมีสัญลักษณ์ 18+ หรือ 18 ปีขึ้นไป
    //                 </li>
    //                 <li>
    //                     - ประเภทสาม คือ เนื้อหาที่เหมาะกับผู้ใช้งานบางกลุ่ม
    //                     ซึ่งจะจำกัดการแสดงผลให้เฉพาะผู้ใช้บางกลุ่มเท่านั้น เช่น หนังสือรุ่น เป็นต้น
    //                 </li>
    //             </ol>
    //             <p>
    //                 ทั้งนี้ ผลงานใดจะอยู่ในระดับ/ประเภทไหน ขึ้นกับการตรวจสอบและพิจารณาของเจ้าหน้าที่
    //                 โดยจะพิจารณาจากเนื้อหาของผลงานนั้นๆ
    //             </p>
    //         </div>

    //         <div>
    //             <h1>มาตรการดูแลผู้ใช้บริการ</h1>
    //             <p>
    //                 มาตรการดูแลผู้ใช้บริการซึ่งได้รับความเสียหายจากการใช้บริการเป็นไปตามนโยบายของบริษัทฯ
    //             </p>
    //         </div>
    //     </div>
    // );
};

export const Privacy = () => {
    const pageId = 'Privacy';
    const dispatch = useDispatch();
    const [privacy, setPrivacy] = useState<PrivacyPolicyResponse>();

    useEffect(() => {
        fetchPrivacyPolicy();
    }, []);

    const fetchPrivacyPolicy = async () => {
        try {
            dispatch(action.setProcess(true, pageId));
            const response = await getPrivacyPolicy();
            if (response) {
                setPrivacy(response);
            }
        } finally {
            dispatch(action.setProcess(false, pageId));
        }
    };

    return <div>{parse(privacy?.privacy || '')}</div>;
    // return (
    //     <div className='privacy-container'>
    //         <div>
    //             <h1 style={{ textAlign: 'center' }}>นโยบายความเป็นส่วนตัว</h1>
    //             <p>
    //                 Hibrary
    //                 คือระบบห้องสมุดหนังสืออิเล็กทรอนิกส์ที่ได้รับสิทธิ์อย่างถูกต้องในการโฆษณา
    //                 จัดจำหน่ายหนังสืออิเล็กทรอนิกส์(E-book) จากสำนักพิมพ์ที่เข้าร่วม
    //             </p>
    //             <p>ทรัพยากรอิเล็กทรอนิกส์ หมายถึง</p>
    //             <ul>
    //                 <li>
    //                     <b>หนังสืออิเล็กทรอนิกส์</b>
    //                     (E-book ) เป็นรูปแบบไฟล์ PDF หรือ ePub
    //                 </li>

    //                 <li>
    //                     <b>นิตยสารอิเล็กทริอนิกส์</b>
    //                     (E-Magazine ) เป็นรูปแบบไฟล์ PDF หรือ ePub
    //                 </li>

    //                 <li>
    //                     <b>หนังสือพิมพ์อิเล็กทริอนิกส์</b>
    //                     (E-Newspaper) เป็นรูปแบบไฟล์ PDF หรือ ePub
    //                 </li>

    //                 <li>
    //                     <b>วีดีโอ</b>
    //                     อยู่ในรูปแบบไฟล์สตรีมมิ่ง
    //                 </li>

    //                 <li>
    //                     <b>พอดแคสต์</b>
    //                     หรือ หนังสือเสียง อยู่ในรูปแบบไฟล์สตรีมมิ่ง
    //                 </li>
    //             </ul>
    //             <p>
    //                 <b>ทรัพยากรอิเล็กทรอนิกส์</b>
    //                 ที่เผยแพร่ใน Hibrary จะเป็นรูปแบบไฟล์ PDF หรือ ePub ซึ่งจะถูกเข้ารหัส
    //                 เพื่อควบคุมลิขสิทธิ์ (Digital Right Management) ทุกไฟล์ สมาชิกสามารถนำ
    //                 <b>ทรัพยากรอิเล็กทรอนิกส์</b>
    //                 ของ Hibrary ไปอ่านในอุปกรณ์ที่รองรับได้ เช่น iPhone, iPad, Android, E-Reader
    //                 และเว็บไชต์ เมื่อผู้ใช้บริการได้เข้าหรือออกจาก Hibrary รวมถึงการเข้าชมหน้าต่าง ๆ
    //                 ของเว็บไซต์ Hibrary จะมีการจัดเก็บค่า IP Address
    //                 รวมถึงชนิดเว็บเบราว์เซอร์ของสมาชิก
    //                 ทั้งนี้เพื่อนำข้อมูลเหล่านั้นมาวิเคราะห์และนำไปพัฒนาบริการของ Hibrary
    //                 เพื่อตอบสนองความต้องการของสมาชิกในอนาคต
    //             </p>
    //         </div>

    //         <div>
    //             <h1>การรวบรวมข้อมูล</h1>
    //             <ul>
    //                 <li>
    //                     <b>ข้อมูลส่วนตัว และ ข้อมูลเพื่อการติดต่อ</b>
    //                     ในการสมัครสมาชิกของสมาชิก กับ Hibrary ผู้ใช้บริการจะต้องกรอกข้อมูลชื่อ อีเมล
    //                     หรือรหัสพนักงานให้ถูกต้องและครบถ้วนเพื่อสิทธิประโยชน์ของผู้ใช้บริการในอนาคต
    //                 </li>

    //                 <li>
    //                     <b>ข้อมูลทางสถิติสมาชิก (demographic)</b>
    //                     เฉพาะ location-base จะถูกเก็บข้อมูลทุกที่มีการลงชื่อใช้งานผ่านทาง QR-Code
    //                     เพื่อใช้ในการยืนยันสิทธิ์การใช้งาน ตามที่องค์กรที่เป็นลูกค้าของ Hibrary
    //                     กำหนด
    //                 </li>

    //                 <li>
    //                     <b>ข้อมูลประวัติการทำธุรกรรม</b>
    //                     ข้อมูลการยืม-คืน-จองคิว ทรัพยากรอิเล็กทรอนิกส์
    //                 </li>

    //                 <li>
    //                     <b>ข้อมูลทางเทคนิค</b>
    //                     IP address คุกกี้ ระบบปฏิบัติการและแพลตฟอร์ม ประเภทและเวอร์ชันของเบราว์เซอร์
    //                     ชื่ออุปกรณ์
    //                 </li>

    //                 <li>
    //                     <b>ข้อมูลเกี่ยวกับพฤติกรรม</b>
    //                     เวลาการเข้าใช้งาน เวลาการอ่านทรัพยากรอิเล็กทรอนิกส์
    //                 </li>

    //                 <li>
    //                     ข้อมูลส่วนบุคคลที่สมาชิกได้ให้หรือใช้ผ่านการประมวลผลของเครื่องคอมพิวเตอร์ที่ควบคุมการทำงานของ
    //                     Hibrary ทั้งหมดนั้นสมาชิกยอมรับและตกลงว่าเป็นสิทธิและกรรมสิทธิ์ของบริษัท
    //                     Hibrary
    //                     จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับตามเกณฑ์มาตรฐานสากลสำหรับการรักษาข้อมูลเป็นความลับและจะไม่อนุญาตให้ผู้อื่นเข้าถึงข้อมูลดังกล่าวได้นอกจากพนักงานผู้ที่ได้รับอนุญาตจาก
    //                     Hibrary อย่างไรก็ตามเพื่อความปลอดภัยในข้อมูลส่วนบุคคลของสมาชิก
    //                     สมาชิกควรปฏิบัติตามข้อกำหนดและเงื่อนไขการให้บริการของเว็บไซต์ของบริษัทแต่ละเว็บไซต์โดยเคร่งครัด
    //                 </li>
    //             </ul>
    //         </div>

    //         <div>
    //             <h1>วัตถุประสงค์ในการรวบรวมข้อมูล</h1>
    //             <ul>
    //                 <li>
    //                     <b>การลงทะเบียนและการยืนยันตัวตน</b>
    //                     เพื่อลงทะเบียน ตรวจสอบ ยืนยัน ระบุและ/หรือ รับรองหรือตัวตนของผู้ใช้งาน
    //                 </li>

    //                 <li>
    //                     <b>การทำงานของเว็บไซต์ แอปพลิเคชันมือถือ และแพลตฟอร์ม</b>
    //                     เพื่อดูแล ดำเนินงาน ติดตาม สังเกตการณ์ และจัดการเว็บไซต์และแพลตฟอร์ม
    //                     เพื่อปรับปรุงให้มีประสิทธิภาพและปลอดภัย และใช้ในการพัฒนาฟีเจอร์ใหม่
    //                 </li>

    //                 <li>
    //                     <b>การพัฒนาการให้บริการและการวัดผลความคุ้มค่าของทรัพยากร</b>
    //                     การวิเคราะห์ข้อมูลตามความสนใจหรือพฤติกรรมของการใช้งาน และการวิเคราะข้อมูล
    //                     เพื่อปรับปรุงในการให้บริการ
    //                     และการจัดซื้อทรัพยากรให้ตรงกับความต้องการของผู้ใช้งานให้ได้มากที่สุด
    //                 </li>
    //             </ul>
    //         </div>

    //         <div>
    //             <h1>การเปิดเผยข้อมูลแก่บุคคลที่สาม</h1>
    //             <ul>
    //                 <li>
    //                     Hibrary อาจเปิดเผยข้อมูลส่วนบุคคลเท่าที่จำเป็น การเปิดเผยข้อมูลนั้น ๆ
    //                     เป็นไปโดยถูกต้องตาม
    //                 </li>
    //                 <li>
    //                     กฎหมายหรือตามคำสั่งของหน่วยงานของรัฐที่เกี่ยวข้อง หรือ บริษัทในเครือ
    //                     หรือบริษัทในกลุ่ม คู่สัญญาหรือองค์กรที่ใช้บริการ Hibrary
    //                 </li>
    //             </ul>
    //         </div>

    //         <div>
    //             <h1>คุกกี้ (COOKIE)</h1>
    //             <p>
    //                 คุกกี้คือไฟล์ขนาดเล็กที่มีสตริงอักขระซึ่งส่งไปยังคอมพิวเตอร์ของสมาชิกเมื่อสมาชิกเข้าชมเว็บไซต์
    //                 เมื่อสมาชิกเข้าชมเว็บไซต์นั้นอีกครั้งคุกกี้จะทำให้ไซต์ดังกล่าวจดจำเบราว์เซอร์ของสมาชิก
    //                 คุกกี้อาจเก็บการตั้งค่าของผู้ใช้หรือข้อมูลอื่นๆสมาชิก
    //                 สามารถตั้งค่าเบราว์เซอร์ของสมาชิกใหม่ให้ปฏิเสธคุกกี้ทั้งหมดหรือแสดงให้รู้เมื่อกำลังมีการส่งคุกกี้
    //                 อย่างไรก็ตามลักษณะหรือบริการบางอย่างของเว็บไซต์อาจทำงานได้ไม่สมบูรณ์เมื่อไม่มีคุกกี้
    //             </p>
    //         </div>

    //         <div>
    //             <h1>ความรับผิดชอบ</h1>
    //             <p>
    //                 Hibrary
    //                 ขอสงวนสิทธิ์ในการปฏิเสธความรับผิดทั้งหมดในกรณีที่ข้อมูลส่วนบุคคลของสมาชิกเสียหายหรือสูญหายเนื่องจากความผิดพลาดใดๆ
    //                 ระหว่างส่งข้อมูลระหว่าง server กับผู้ใช้บริการเหตุสุดวิสัยใด ๆ
    //                 การถูกจารกรรมข้อมูลคอมพิวเตอร์ (Hack) และหรือการกระทำใด ๆ
    //                 ที่ขัดกฎหมายไม่ว่าการกระทำนั้นจะเกิดจากบุคคลใด ๆ ก็ตาม
    //             </p>
    //         </div>

    //         <div>
    //             <h1>ลิขสิทธิ์ (COPYRIGHTS)</h1>
    //             <p>
    //                 ลิขสิทธิ์ตามกฎหมาย (Copyrights) ข้อมูลทรัพยยากรอิเล็กทรอนิกส์
    //                 ข้อมูลตัวอย่างทรัพยากรอิเล็กทรอนิกส์, ข้อความ ภาพ เสียงเนื้อหา ส่วนประกอบใด ๆ
    //                 ทั้งหมดของ Hibrary แต่ไม่จำกัดเฉพาะเครื่องหมายการค้าเครื่องหมายบริการ
    //                 ชื่อทางการค้าที่ปรากฏบน Hibrary
    //                 เป็นงานอันได้รับความคุ้มครองตามกฎหมายทรัพย์สินทางปัญญาของไทยโดยชอบด้วยกฎหมายของ
    //                 Hibrary แต่เพียงผู้เดียว หากบุคคลใดลอกเลียน ปลอมแปลงทำซ้ำ ดัดแปลง
    //                 เผยแพร่ต่อสาธารณชนจำหน่าย
    //                 มีไว้ให้เช่าหรือกระทำการใดๆในลักษณะที่เป็นการแสวงหาผลประโยชน์ทางการค้าหรือประโยชน์โดยมิชอบไม่ว่าโดยประการใดๆ
    //                 จากทรัพย์สินทางปัญญาดังกล่าวข้างต้นโดยไม่ได้รับอนุญาตจาก Hibrary
    //                 จะดำเนินการตามกฎหมายกับผู้ละเมิดสิทธิ์ดังกล่าวโดยทันที
    //             </p>
    //         </div>

    //         <div>
    //             <h1>สิทธิ์ของเจ้าของข้อมูลส่วนบุคคล</h1>
    //             <p>
    //                 ภายใต้บทบัญญัติแห่งกฎหมายและข้อยกเว้นตามกฎหมายที่เกี่ยวข้อง
    //                 ผู้ใช้งานอาจมีสิทธิ์ตามที่ระบุไว้
    //             </p>
    //             <ul>
    //                 <li>
    //                     การเข้าถึง:
    //                     ผู้ใช้งานอาจมีสิทธิ์ในการขอเข้าถึงหรือขอรับสำเนาข้อมูลส่วนบุคคลที่เราเก็บรวบรวม
    //                     ใช้หรือเปิดเผยเกี่ยวกับผู้ใช้งาน ทั้งนี้
    //                     เพื่อความเป็นส่วนตัวและความปลอดภัยของผู้ใช้งาน
    //                     เราอาจขอให้ผู้ใช้งานพิสูจน์ตัวตนของผู้ใช้งานก่อนจะให้ข้อมูลตามที่ผู้ใช้งานขอ
    //                 </li>

    //                 <li>
    //                     การแก้ไขให้ถูกต้อง:
    //                     ผู้ใช้งานอาจมีสิทธิ์ขอให้มีการดำเนินการแก้ไขข้อมูลส่วนบุคคลที่เราได้เก็บรวบรวม
    //                     ใช้หรือเปิดเผยเกี่ยวกับผู้ใช้งาน ให้ ถูกต้อง เป็นปัจจุบัน สมบูรณ์
    //                     และไม่ก่อให้เกิดความเข้าใจผิด
    //                 </li>

    //                 <li>
    //                     การคัดค้าน: ผู้ใช้งานอาจมีสิทธิ์คัดค้านการเก็บรวบรวม
    //                     ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน เช่น การคัดค้านการตลาดแบบตรง
    //                 </li>

    //                 <li>
    //                     การขอให้ระงับการใช้:
    //                     ผู้ใช้งานอาจมีสิทธิ์ขอให้ระงับการใช้ข้อมูลส่วนบุคคลของผู้ใช้งานในบางกรณี
    //                 </li>

    //                 <li>
    //                     การถอนความยินยอม:
    //                     สำหรับวัตถุประสงค์ที่ผู้ใช้งานได้ให้ความยินยอมให้เราเก็บรวบรวม
    //                     ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน
    //                     ผู้ใช้งานมีสิทธิ์ที่จะถอนความยินยอมของผู้ใช้งาน ณ เวลาใด ก็ได้
    //                 </li>

    //                 <li>
    //                     การลบหรือทำลายข้อมูล: ผู้ใช้งานอาจมีสิทธิ์ขอให้เราดำเนินการลบหรือทำลาย
    //                     หรือทำให้ข้อมูลส่วนบุคคลของผู้ใช้งานที่เราเก็บรวบรวม ใช้หรือเปิดเผย
    //                     เป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูล เว้นเสียแต่ว่า
    //                     การเก็บรักษาข้อมูลดังกล่าวของเรานั้นเป็นไปเพื่อการปฏิบัติตามกฎหมาย
    //                     หรือเพื่อก่อตั้งสิทธิ์เรียกร้องตามกฎหมาย เพื่อการใช้
    //                     หรือการปกป้องสิทธิ์เรียกร้องตามกฎหมาย
    //                 </li>

    //                 <li>
    //                     การยื่นเรื่องร้องเรียน:
    //                     ผู้ใช้งานอาจมีสิทธิ์ยื่นเรื่องร้องเรียนไปยังหน่วยงานที่มีอำนาจในกรณีที่ผู้ใช้งานเชื่อว่าการเก็บรวบรวม
    //                     ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของเรานั้นไม่ชอบด้วยกฎหมายหรือไม่สอดคล้องกับกฎหมายคุ้มครองข้อมูลที่บังคับใช้
    //                 </li>
    //             </ul>
    //         </div>

    //         <div>
    //             <h1>ระยะเวลาในการที่เราเก็บข้อมูลส่วนบุคคล</h1>
    //             <p>
    //                 เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผล
    //                 เพื่อให้บรรลุตามวัตถุประสงค์ที่เราได้รับข้อมูลส่วนบุคคลนั้นมา
    //                 และเพื่อปฏิบัติตามภาระหน้าที่ทางกฎหมายและกฎข้อบังคับต่างๆ อย่างไรก็ตาม
    //                 เราอาจเก็บข้อมูลส่วนบุคคลของท่านนานขึ้นหากจำเป็นตามกฎหมาย ที่ใช้บังคับ
    //             </p>
    //         </div>

    //         <div>
    //             <h1>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h1>
    //             <p>
    //                 Hibrary สามารถเปลี่ยนแปลงนโยบายความเป็นส่วนตัวได้ตลอดเวลา
    //                 เมื่อมีการเปลี่ยนแปลงหรือเพิ่มเติมบริการหรือเมื่อเราได้รับข้อเสนอแนะจากสมาชิก
    //                 การเปลี่ยนแปลงใด ๆ ในนโยบายจะมีการแจ้งถึงสมาชิกผ่านทางไปรษณีย์อิเล็กทรอนิกส์
    //             </p>
    //         </div>

    //         <div>
    //             <h1>รายละเอียดการติดต่อเรา</h1>

    //             <p style={{ marginBottom: '1rem' }}>
    //                 รายละเอียดการติดต่อเรา
    //                 หากผู้ใช้งานมีความประสงค์ที่จะใช้สิทธิ์ของผู้ใช้งานที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของผู้ใช้งาน
    //                 หรือหากผู้ใช้งานมีข้อสงสัยเกี่ยวกับข้อมูลส่วนบุคคลของผู้ใช้งานภายใต้นโยบายความเป็นส่วนตัวฉบับนี้
    //                 โปรดติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเรา ที่
    //             </p>

    //             <p>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด</p>
    //             <p>
    //                 เลขที่ 3 อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว 3
    //                 ถนนลาดพร้าว
    //             </p>
    //             <p style={{ marginBottom: '1rem' }}>แขวงจอมพล เขตจตุจักร กทม 10900</p>

    //             <p>โทร. 02-024-6690 ทุกวัน จันทร์-ศุกร์ 9.30-17.30 น.</p>
    //             <p>E-mail: contact@hytexts.com</p>
    //         </div>
    //     </div>
    // );
};
