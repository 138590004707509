import dayjs from 'dayjs';
import 'dayjs/locale/th';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { ContentViewProps } from '../components/contents';

export const formatDate = (
    unixTime: number,
    locale: string,
    formatTime: string
) => {
    const localeString = locale || 'en';
    const format = formatTime || 'DD MMMM YYYY';

    return dayjs.unix(unixTime).locale(localeString).format(format);
};

export const uploadDateText = (date?: number) => {
    if (date) {
        const intl = useIntl();
        const dateInt = moment().unix() - date;
        const minInt = 60;
        const hourInt = 60 * 60;
        const dayInt = 24 * 60 * 60;
        const weekInt = 7 * 24 * 60 * 60;
        const monthInt = 30 * 24 * 60 * 60;
        const yearInt = 365 * 30 * 24 * 60 * 60;
        return dateInt < hourInt
            ? Math.floor(dateInt / minInt).toFixed(0) +
                  `${intl.formatMessage({ id: 'Unit.Min' })}
                  ${Math.floor(dateInt / minInt) > 1 ? 's' : ''}`
            : dateInt < dayInt
            ? Math.floor(dateInt / hourInt).toFixed(0) +
              ` ${intl.formatMessage({ id: 'Unit.Hour' })}${
                  Math.floor(dateInt / hourInt) > 1 ? 's' : ''
              }`
            : dateInt < weekInt
            ? Math.floor(dateInt / dayInt).toFixed(0) +
              ` ${intl.formatMessage({ id: 'Unit.Day' })}${
                  Math.floor(dateInt / dayInt) > 1 ? 's' : ''
              }`
            : dateInt < monthInt
            ? Math.floor(dateInt / weekInt).toFixed(0) +
              ` ${intl.formatMessage({ id: 'Unit.Week' })}${
                  Math.floor(dateInt / weekInt) > 1 ? 's' : ''
              }`
            : dateInt < yearInt
            ? Math.floor(dateInt / monthInt).toFixed(0) +
              ` ${intl.formatMessage({ id: 'Unit.Month' })}${
                  Math.floor(dateInt / monthInt) > 1 ? 's' : ''
              }`
            : dateInt / yearInt + ` year${dateInt / yearInt > 1 ? 's' : ''}`;
    }
};

export const UploadDateView = (props: ContentViewProps) => {
    const content = props.content;
    return (
        <div className='boxUploadDate'>
            {content.view ? content.view : 0} view
            {content.view && content.view > 1 ? 's' : ''}
            {content.uploadDate
                ? ' • ' + uploadDateText(content.uploadDate) + ' ago'
                : ''}
        </div>
    );
};
