import { Col, Row } from 'antd';
import { BrowserView } from 'react-device-detect';

interface InternetExplorerPageProps {
   isCuDomain: boolean;
}

const InternetExplorerView = (props: InternetExplorerPageProps) => {
   const { isCuDomain } = props;
   return (
      <BrowserView>
         <div
            className={isCuDomain ? `mobile-view cu-mobile-bg` : `mobile-view`}
         >
            <img
               src={
                  isCuDomain
                     ? '/images/cu-mobile.png'
                     : '/images/hibrary-mobile.png'
               }
               className='hibrary-logo'
               alt=''
               style={{ maxWidth: '150px' }}
            />
            <div className='hibrary-text top48'>
               <p>E-LIBRARY ห้องสมุดออนไลน์สำหรับองค์กร</p>
            </div>
            <div className='hibrary-text browser-text top48'>
               <p>
                  เว็บ {isCuDomain ? 'CU-Elibrary' : 'Hibrary'}{' '}
                  รองรับการใช้งานบนเว็บเบราว์เซอร์ Google Chrome, Safari และ
                  Mozilla Firefox เท่านั้น
               </p>
            </div>
            <Row className='browsers-row' justify='center'>
               <Col span={4}>
                  <a
                     href='https://www.google.com/chrome/index.html'
                     target='_blank'
                     rel='noreferrer'
                  >
                     <img
                        src='/images/chrome-logo.png'
                        className='download-browser'
                        alt=''
                     />
                  </a>
               </Col>
               <Col span={4}>
                  <a
                     href='https://www.apple.com/safari/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     <img
                        src='/images/safari-logo.png'
                        className='download-browser'
                        alt=''
                     />
                  </a>
               </Col>
               <Col span={4}>
                  <a
                     href='https://www.mozilla.org/firefox/new/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     <img
                        src='/images/firefox-logo.png'
                        className='download-browser'
                        alt=''
                     />
                  </a>
               </Col>
            </Row>
         </div>
      </BrowserView>
   );
};

export default InternetExplorerView;
