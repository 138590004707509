/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { ContentType } from '../../../interface';
import { Segmented } from 'antd';
import './index.scss';

interface SegmentButtonProps {
    defaultIndex: number;
    buttonTitles: string[];
    onSelected: (index: number) => void;
    onSelected2?: React.Dispatch<React.SetStateAction<ContentType>>;
}

export const SegmentButton = (props: SegmentButtonProps) => {
    const [selectIndex, setSelectIndex] = useState(props.defaultIndex);

    useEffect(() => {
        setSelectIndex(props.defaultIndex);
    }, [props.defaultIndex]);

    const onBtnClick = (index: number) => {
        setSelectIndex(index);
        props.onSelected(index);
    };

    //  const onSelectTab = (currentTab: string) => {
    //      switch (currentTab) {
    //          case 'หนังสือ':
    //              props.onSelected2(ContentType.ebook);
    //              break;
    //          case 'วิดีโอ':
    //              props.onSelected2(ContentType.video);
    //              break;
    //          case 'พ็อดคาส':
    //              props.onSelected2(ContentType.podcast);
    //              break;
    //          default:
    //              props.onSelected2(ContentType.ebook);
    //              break;
    //      }
    //  };

    return (
        //   <div className='segmentContainer'>
        //       <Segmented
        //           defaultValue={`หนังสือ`}
        //           options={props.buttonTitles}
        //           size='large'
        //           onChange={(e) => console.log(e)}
        //       />
        //   </div>
        <div className='segmentContainer'>
            <div className='segment-button'>
                {props.buttonTitles.map((title, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => onBtnClick(index)}
                            className={
                                selectIndex === index
                                    ? 'mode-button-active'
                                    : 'mode-button'
                            }
                        >
                            {title}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
