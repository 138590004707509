import { Modal, ModalProps } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { HibraryRootState } from '../../../../redux/rootReducer';
import { CatalogDetail } from '../../../../interface/listEbook';
import { useEffect, useState } from 'react';
import { getCatalogDetail } from '../../../redux/catalog/catalogCrud';
import { ComponentLoading } from '../../../customUI/loading';
import { action } from '../../../redux/request';
import { EmptyDataView } from '../../nodata';
import './index.scss';
import { onErrorImage } from '../../../Utilities/imageUtilities';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

interface ContentDetailModalProps {
    visible: boolean;
    onClose: () => void;
}

const ContentDetailModal = (props: ContentDetailModalProps) => {
    const pageModalId = 'catalogEbookModalPage';
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const intl = useIntl();
    const queryParams = new URLSearchParams(location.search);

    const contentUID = queryParams.get('contentUID') || '';
    const [ebookDetail, setEbookDetail] = useState<CatalogDetail>();

    const modalProps: ModalProps = {
        open: props.visible,
        onCancel: props.onClose,
        width: 800,
        footer: null,
        className: 'contentDetailModal',
        destroyOnClose: true,
        afterClose: () => {
            history.replace({ search: '' });
        }
    };

    const { request } = useSelector(
        (state: HibraryRootState) => ({
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    useEffect(() => {
        if (props.visible && contentUID !== '') fetchEbookDetail();
    }, [props.visible]);

    const fetchEbookDetail = async () => {
        try {
            dispatch(action.setProcess(true, pageModalId));
            const response = await getCatalogDetail(contentUID || '');
            if (response) {
                setEbookDetail(response);
            }
        } finally {
            dispatch(action.setProcess(false, pageModalId));
        }
    };

    const copyToClipboard = () => {
        const copyText = document.getElementById('uid') as HTMLInputElement;
        if (copyText !== null) {
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText?.value);
        }
    };

    return (
        <Modal {...modalProps}>
            {ebookDetail && !request.loading && (
                <div className='modal-ebook'>
                    <div className='modal-ebook-top'>
                        <img
                            className='coverImage'
                            src={ebookDetail?.coverImage}
                            onError={onErrorImage}
                        />
                        <div className='detail-block'>
                            <h2 className='title'>
                                <span>{ebookDetail?.title}</span>
                            </h2>
                            <div className='head'>
                                <span>{ebookDetail?.authorName}</span>
                            </div>
                            <br />
                            <div className='detail'>
                                <div>
                                    {intl.formatMessage({ id: 'Book.Writer' }) +
                                        ' '}
                                    : {ebookDetail?.publisherName ?? '-'}
                                </div>
                                <div>
                                    {intl.formatMessage({
                                        id: 'Book.Category'
                                    }) + ' '}
                                    : {ebookDetail?.catName}{' '}
                                    {ebookDetail?.subcatName}
                                </div>
                                <div className='uid'>
                                    UID :{' '}
                                    <input
                                        id='uid'
                                        className='UID'
                                        value={ebookDetail.contentUID}
                                    />
                                    <button
                                        onClick={copyToClipboard}
                                        className='copyButton'
                                    >
                                        <img
                                            src='/images/copy-button.svg'
                                            alt=''
                                        />
                                    </button>
                                </div>
                                <div>
                                    {intl.formatMessage({
                                        id: 'Book.Publication'
                                    }) +
                                        ' : ' +
                                        dayjs
                                            .unix(ebookDetail.publicationDate)
                                            .format('YYYY')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-ebook-synopsis'>
                        <div className='title'>
                            {intl.formatMessage({ id: 'Book.Summary' })}
                        </div>
                        <div className='head'>{ebookDetail?.description}</div>
                    </div>
                    <div className='file-detail'>
                        <div className='file-detail-col'>
                            <div className='title'>
                                {intl.formatMessage({ id: 'Book.FileSize' })}
                            </div>
                            <div className='detailNumber'>
                                {ebookDetail?.fileSize}
                            </div>
                        </div>
                        <div className='file-detail-col'>
                            <div className='title'>
                                {intl.formatMessage({ id: 'Book.FileType' })}
                            </div>
                            <div className='detailNumber'>
                                {ebookDetail?.fileType}
                            </div>
                        </div>
                        <div className='file-detail-col'>
                            <div className='title'>
                                {intl.formatMessage({ id: 'Book.Pages' })}
                            </div>
                            <div className='detailNumber'>
                                {ebookDetail?.pageCount}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {request.loading && <ComponentLoading id={pageModalId} />}
            {!ebookDetail && !request.loading && <EmptyDataView />}
        </Modal>
    );
};

export default ContentDetailModal;
