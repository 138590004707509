import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CarouselEle } from '../banner';
import { Helmet } from 'react-helmet-async';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentSetView } from '../../components/store';
import { CategoryView } from '../../components/category';
import { HomeState } from '../../redux/home';
import { SegmentHeader } from './segmentHeader';
import { generateHeaderLinkPath } from '../../redux/utilities';
import {
   BestSellerContent,
   Category,
   Content,
   ContentType,
   Home,
   HomeSet,
   HomeType,
   LicenseType,
   Recommend,
   RenderHomeSegmentParam
} from '../../../interface/home';
import {
   ContentCollectionView,
   ContentBestsellerView,
   HomeSwiperCategory,
   SwiperBestseller,
   SponsorBreakpoint,
   SponsorBannerBreakPoint
} from '../../components/contents';
import {
   breakpointEbook,
   breakpointPodcast,
   breakpointVideo
} from '../../redux/utilities/swiper-breakpoint';

import StoreHomeList from './storeHomeList';
import * as actions from '../../redux/store/storeActions';
import 'swiper/swiper.scss';
import './index.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import HomeSegmentNew from './homeSegment_new';
import { SponsorCover } from '../customview/SponsorCover';
import { Sponsor } from '../../../interface/sponsor';
import { onErrorImage } from '../../Utilities/imageUtilities';
import { Link } from 'react-router-dom';

interface StoreHomePageParam {
   licenseType?: LicenseType;
   contentType?: ContentType;
}

export const StoreHomePage = (props: StoreHomePageParam) => {
   const pageId = 'StoreHomeList';
   const dispatch = useDispatch();

   const { clientInfo, home, authState, request } = useSelector(
      (state: HibraryRootState) => ({
         authState: state.auth,
         clientInfo: state.clientInfo,
         home: state.home,
         request: state.fetch
      }),
      shallowEqual
   );

   const fetchStore = (isLogin: boolean) => {
      dispatch(
         actions.fetchStoreHome(
            pageId,
            clientInfo.companyPrefix,
            props.licenseType,
            props.contentType,
            isLogin
         )
      );
   };

   useEffect(() => {
      if (checkReloadContent() && !request.loading) {
         fetchStore(authState.authToken === undefined ? false : true);
      }
   }, [authState]);

   useEffect(() => {
      if (home.isReset) {
         fetchStore(authState.authToken === undefined ? false : true);
      }
   }, [home]);

   const checkReloadContent = () => {
      switch (props.licenseType) {
         case LicenseType.Rent: {
            if (!home.listHomeHibrary) {
               return true;
            } else {
               return false;
            }
         }
         case LicenseType.Free:
            if (!home.listHomeFree) {
               return true;
            } else {
               return false;
            }
         case LicenseType.Private:
            if (props.contentType) {
               return true;
            }
            if (!home.listHomePrivate) {
               return true;
            } else {
               return false;
            }
         default:
            return true;
      }
   };

   const MainHome = useMemo(() => {
      const getHomeModel = (type: LicenseType, home: HomeState) => {
         if (
            props.contentType === undefined ||
            props.contentType === ContentType.content
         ) {
            switch (type) {
               case LicenseType.Rent:
                  return home.listHomeHibrary;
               case LicenseType.Free:
                  return home.listHomeFree;
               case LicenseType.Private:
                  return home.listHomePrivate;
               default:
                  return [];
            }
         } else {
            return home.listHomeContent;
         }
      };
      return (
         <StoreHomeList
            homes={getHomeModel(props.licenseType!, home)}
            licenseType={props.licenseType!}
         />
      );
   }, [home]);

   const MainBanner = useMemo(() => {
      return <CarouselEle banners={home.banners ?? []} />;
   }, [home]);

   return (
      <div className='store'>
         <Helmet>
            <title>
               {window.location.hostname.match(/cu-elibrary.com/)
                  ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                  : 'Hibrary'}{' '}
               | ห้องสมุดออนไลน์ (E-library) {clientInfo.displayName}
            </title>
         </Helmet>
         {home.banners!.length > 0 ? MainBanner : null}
         {MainHome}
      </div>
   );
};

export const RenderHomeSegment = (props: RenderHomeSegmentParam) => {
   const getSwiperClass = (setType: HomeType, contentType: ContentType) => {
      switch (setType) {
         case HomeType.ContentSet:
            return 'contentSet-swiper';
         case HomeType.CategoryEbook:
         case HomeType.SubCategoryEbook:
         case HomeType.AllContent:
         case HomeType.NewContent:
         case HomeType.RentAbleContent:
            return `${contentType}-swiper`;
         case HomeType.BestSeller:
            return 'best-seller-swiper';
         case HomeType.Categories:
            return 'category-swiper';
         case HomeType.MainSponsor:
         case HomeType.SecondarySponsor:
         case HomeType.RecommendContent:
            return ``;
         default:
            return 'contentSet-swiper';
      }
   };

   const getSwiperItem = (
      setType: HomeType,
      data:
         | HomeSet
         | Content
         | Category
         | BestSellerContent
         | Sponsor
         | Recommend,
      index?: number
   ) => {
      switch (setType) {
         case HomeType.ContentSet:
            return (
               <ContentSetView
                  key={index}
                  set={data as HomeSet}
                  setTypeName={props.segment.title}
                  setType={props.segment.setType}
                  licenseType={props.licenseType}
                  prefix={'hti'}
               />
            );
         case HomeType.CategoryEbook:
         case HomeType.SubCategoryEbook:
         case HomeType.AllContent:
         case HomeType.NewContent:
         case HomeType.RentAbleContent:
            return (
               <ContentCollectionView
                  key={index}
                  content={data as Content}
                  licenseType={props.licenseType}
               />
            );
         case HomeType.BestSeller:
            return (
               <ContentBestsellerView
                  key={index}
                  content={data as BestSellerContent}
                  licenseType={props.licenseType}
                  rank={index ?? 0}
               />
            );
         case HomeType.Categories:
            return (
               <CategoryView
                  key={index}
                  category={data as Category}
                  licenseType={props.licenseType}
                  contentType={(data as Content).contentType}
               />
            );
         case HomeType.MainSponsor:
         case HomeType.SecondarySponsor:
            return <SponsorCover content={data as Sponsor} />;
         case HomeType.RecommendContent: {
            const content = data as Recommend;
            return (
               <div className='sponsorBannerCover'>
                  <Link to={`/sponsor/sets/${content.setUID}`}>
                     <img
                        src={content.coverImageWebp ?? content.coverImage}
                        onError={onErrorImage}
                     />
                  </Link>
               </div>
            );
         }
         default:
            return <></>;
      }
   };

   const getViewAll = (home: Home) => {
      switch (home.type) {
         case HomeType.ContentSet:
            if (home.sets!.length >= 8) {
               return true;
            } else {
               return false;
            }
         case HomeType.CategoryEbook:
         case HomeType.SubCategoryEbook:
         case HomeType.AllContent:
         case HomeType.RentAbleContent:
            if (home.contents && home.contents!.length <= 8) {
               return false;
            } else {
               return true;
            }
         case HomeType.NewContent:
            return true;
         case HomeType.BestSeller:
            return false;
         case HomeType.Categories:
            if (home.category!.length > 8) {
               return true;
            } else {
               return false;
            }
         case HomeType.MainSponsor:
         case HomeType.SecondarySponsor:
            return home.sponsors!.length >= 7 ? true : false;
         case HomeType.RecommendContent:
            return home.recommends!.length >= 0 ? true : false;
         default:
            return false;
      }
   };

   const getBreakpointOfCollection = (home: Home) => {
      switch (home.type) {
         case HomeType.ContentSet:
         case HomeType.Categories:
            return HomeSwiperCategory;
         case HomeType.AllContent:
         case HomeType.NewContent:
            if (home.contentType === ContentType.video) {
               return breakpointVideo;
            }
            if (home.contentType === ContentType.podcast) {
               return breakpointPodcast;
            }
            return breakpointEbook;
         case HomeType.BestSeller:
            return SwiperBestseller;
         case HomeType.MainSponsor:
         case HomeType.SecondarySponsor:
            return SponsorBreakpoint;
         case HomeType.RecommendContent:
            return SponsorBannerBreakPoint;
         default:
            if (home.contentType === ContentType.video) {
               return breakpointVideo;
            }
            if (home.contentType === ContentType.podcast) {
               return breakpointPodcast;
            }
            return breakpointEbook;
      }
   };

   return (
      <div key={`segment_${props.indexSegment}_${props.segment.contentType}`}>
         {props.segment.title && (
            <SegmentHeader
               left={props.segment.title}
               right={getViewAll(props.segment)}
               path={generateHeaderLinkPath(props)}
               subTitle={props.segment.subtitle}
            />
         )}

         <div
            className={`categorySwiper${
               (props.segment.type as HomeType) === HomeType.BestSeller
                  ? ' bestSeller'
                  : ''
            }`}
         >
            <Swiper
               className='segment-swiper'
               breakpoints={getBreakpointOfCollection(props.segment)}
               spaceBetween={16}
               centeredSlides={false}
               loop={false}
            >
               {props.segment.recommends?.map((e, index) => (
                  <SwiperSlide
                     key={`col_set_${index}`}
                     className={getSwiperClass(
                        (props.segment.type as HomeType) ?? HomeType.AllContent,
                        props.segment.contentType!
                     )}
                  >
                     {getSwiperItem(
                        (props.segment.type as HomeType) ??
                           HomeType.RecommendContent,
                        e
                     )}
                  </SwiperSlide>
               ))}
               {props.segment.sponsors?.map((e, index) => (
                  <SwiperSlide
                     key={`col_set_${index}`}
                     className={getSwiperClass(
                        (props.segment.type as HomeType) ?? HomeType.AllContent,
                        props.segment.contentType!
                     )}
                  >
                     {getSwiperItem(
                        (props.segment.type as HomeType) ??
                           HomeType.MainSponsor,
                        e
                     )}
                  </SwiperSlide>
               ))}
               {props.segment.sets?.map((e, index) => (
                  <SwiperSlide
                     key={`col_set_${index}`}
                     className={getSwiperClass(
                        (props.segment.type as HomeType) ?? HomeType.AllContent,
                        props.segment.contentType!
                     )}
                  >
                     {getSwiperItem(
                        (props.segment.type as HomeType) ?? HomeType.AllContent,
                        e
                     )}
                  </SwiperSlide>
               ))}

               {props.segment.videos?.map((e, index) => (
                  <div key={`div${index}`}>
                     <SwiperSlide
                        key={`col_${index}`}
                        className={getSwiperClass(
                           (props.segment.type as HomeType) ??
                              HomeType.AllContent,
                           props.segment.contentType!
                        )}
                     >
                        {getSwiperItem(
                           (props.segment.type as HomeType) ??
                              HomeType.AllContent,
                           e,
                           index
                        )}
                     </SwiperSlide>
                  </div>
               ))}

               {props.segment.podcasts?.map((el, index) => (
                  <div key={`div${index}`}>
                     <SwiperSlide
                        key={`col_${index}`}
                        className={getSwiperClass(
                           (props.segment.type as HomeType) ??
                              HomeType.AllContent,
                           props.segment.contentType!
                        )}
                     >
                        {getSwiperItem(
                           (props.segment.type as HomeType) ??
                              HomeType.AllContent,
                           el,
                           index
                        )}
                     </SwiperSlide>
                  </div>
               ))}

               {props.segment.contents?.map((e, index) => (
                  <div key={`div${index}`}>
                     {(props.segment.type as HomeType) ===
                     HomeType.BestSeller ? (
                        <>
                           {index <= props.segment.contents!.length / 2 - 1 ||
                           props.segment.contents!.length == 1 ? (
                              <SwiperSlide
                                 key={`col_${index}`}
                                 className={getSwiperClass(
                                    (props.segment.type as HomeType) ??
                                       HomeType.AllContent,
                                    props.segment.contentType!
                                 )}
                              >
                                 <div className='list-bestSeller'>
                                    <div>
                                       {getSwiperItem(
                                          props.segment.type as HomeType,
                                          props.segment.contents![index * 2],
                                          index * 2
                                       )}
                                       {props.segment.contents!.length > 1
                                          ? getSwiperItem(
                                               props.segment.type as HomeType,
                                               props.segment.contents![
                                                  index * 2 + 1
                                               ],
                                               index * 2 + 1
                                            )
                                          : null}
                                    </div>
                                 </div>
                              </SwiperSlide>
                           ) : null}
                        </>
                     ) : (
                        <SwiperSlide
                           key={`col_${index}`}
                           className={getSwiperClass(
                              (props.segment.type as HomeType) ??
                                 HomeType.AllContent,
                              props.segment.contentType!
                           )}
                        >
                           {getSwiperItem(
                              (props.segment.type as HomeType) ??
                                 HomeType.AllContent,
                              e,
                              index
                           )}
                        </SwiperSlide>
                     )}
                  </div>
               ))}

               {props.segment.category?.map((e, index) => (
                  <SwiperSlide
                     key={`col_Cat_${index}`}
                     className={getSwiperClass(
                        (props.segment.type as HomeType) ?? HomeType.AllContent,
                        props.segment.contentType!
                     )}
                  >
                     {getSwiperItem(
                        (props.segment.type as HomeType) ?? HomeType.AllContent,
                        e
                     )}
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      </div>
   );
};
