/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Content,
    ContentType,
    ListAllRentContentResponse
} from '../../../interface';
import { HibraryRootState } from '../../../redux/rootReducer';
import { BreadcrumbLink, BreadCrumbView } from '../../components/breadcrumb';
import { MemoContentCollectionView } from '../../components/contents';
import { EmptyDataView } from '../../components/nodata';
import { Loading } from '../../customUI/loading';
import { removeSplash } from '../../helpers/removeSplash';
import { action } from '../../redux/request';
import { getRentableContents } from '../../redux/store/storeCrud';
import './index.scss';
import { useIntl } from 'react-intl';

interface RentableContentsProps {
    contentType: ContentType;
}

const RentableContents = (props: RentableContentsProps) => {
    const { contentType } = props;
    const pageId = 'RentableContents';
    const dispatch = useDispatch();
    const intl = useIntl();
    const [contents, setContents] = useState<ListAllRentContentResponse>();
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();

    const { environment, clientInfo, authen, request } = useSelector(
        (state: HibraryRootState) => ({
            clientInfo: state.clientInfo,
            authen: state.auth,
            environment: state.environment,
            request: state.fetch
        }),
        shallowEqual
    );

    const breadcrumbs = useMemo(() => {
        return <BreadCrumbView addLinks={breadCrumbs} />;
    }, [breadCrumbs]);

    useEffect(() => {
        fetchRentableContents(false);
    }, []);

    useEffect(() => {
        if (environment.endOfScreen) {
            if (contents?.lastEvaluatedKey !== undefined && !request.loading) {
                fetchRentableContents(true);
            }
        }
    }, [environment]);

    const selectContentContainerClass = () => {
        switch (contentType) {
            case ContentType.ebook:
                return `content-list content-list-container-ebook `;
            case ContentType.video:
                return `content-list content-list-container-video `;
            case ContentType.podcast:
                return `content-list content-list-container-podcast `;
            default:
                return `content-list content-list-container-ebook `;
        }
    };

    const fetchRentableContents = async (isEndless: boolean) => {
        try {
            dispatch(action.setProcess(true, pageId));
            const lastEvaluatedKey = contents?.lastEvaluatedKey
                ? contents.lastEvaluatedKey
                : undefined;
            const response = await getRentableContents(
                clientInfo.companyPrefix,
                !authen.isLogin,
                lastEvaluatedKey
            );
            if (response) {
                if (isEndless && contents?.ebooks) {
                    const newContents = contents.ebooks.concat(
                        response.ebooks ?? []
                    );
                    setContents({
                        ...contents,
                        ebooks: newContents,
                        lastEvaluatedKey: response.lastEvaluatedKey
                    });
                } else {
                    setContents(response);
                    setBreadCrumbs([
                        {
                            title: intl.formatMessage({
                                id: 'ContentType.BorrowingAvailable'
                            })
                        }
                    ]);
                }
            }
        } finally {
            dispatch(action.setProcess(false, pageId));
            dispatch(removeSplash);
        }
    };

    return (
        <>
            {breadcrumbs}
            <div className='view-all-content'>
                <h1 className='viewAllTitle'>
                    {intl.formatMessage({
                        id: 'ContentType.BorrowingAvailable'
                    })}
                </h1>
                {contents?.ebooks && contents.ebooks.length > 0 ? (
                    <div className={selectContentContainerClass()}>
                        {contents?.ebooks.map((el) => (
                            <MemoContentCollectionView
                                content={el as Content}
                                licenseType={el.licenseType}
                            />
                        ))}
                    </div>
                ) : (
                    !request.loading && <EmptyDataView />
                )}
            </div>
            <Loading id={pageId} />
        </>
    );
};

export default RentableContents;
