import { useState, memo } from 'react';
import { Menu, Dropdown } from 'antd';
import { changeTimeStampToText } from './util';
import { License, LicenseType } from '../../../interface';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, shallowEqual } from 'react-redux';
import { HibraryRootState } from '../../../redux/rootReducer';
import { Modal, Button } from 'antd';
import SVG from 'react-inlinesvg';

import dayjs from 'dayjs';
import ReadingStatModal from '../../components/modal/reading-stat/reading-stat';
import { useIntl } from 'react-intl';

export interface BookShelfContentProps {
    data: License;
    onOpenContent: (licenseUID: string) => void;
    onReturnContent: (licenseUID: string) => void;
}

export const Ebook: React.FC<BookShelfContentProps> = ({
    data,
    onOpenContent,
    onReturnContent
}) => {
    const intl = useIntl();
    const imgErrorSrc = '/images/book-placeholder.jpg';
    const [isImgError, setIsImgError] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [readingModal, setReadingModal] = useState<boolean>(false);
    const imgError = (image: any) => {
        setIsImgError(true);
        image.onError = '';
        return true;
    };

    const dropdownBtn = (
        <Menu>
            <Menu.Item key='1' onClick={() => setConfirmDialog(true)}>
                {intl.formatMessage({ id: 'BookShelf.ModalReturnBtn' })}
            </Menu.Item>
            <Menu.Item key='2' onClick={() => setReadingModal(true)}>
                {intl.formatMessage({ id: 'BookShelf.ReadingStatBtn' })}
            </Menu.Item>
        </Menu>
    );

    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request:
                state.fetch.id === data.licenseUID ? state.fetch : undefined
        }),
        shallowEqual
    );

    return (
        <>
            <div className='bookshelf-collection'>
                <div className={`cover ${request?.loading ? 'loading' : ''}`}>
                    <div className={`filetype ${data.fileType}`}>
                        {data.fileType}
                    </div>
                    <img
                        key={data.licenseUID}
                        src={isImgError ? imgErrorSrc : data.coverImage}
                        className='imageView'
                        onError={imgError}
                        onClick={() => {
                            onOpenContent(data.licenseUID);
                        }}
                    />
                    {data.licenseType === LicenseType.Sample && (
                        <img
                            alt=''
                            src='/images/badge-sample.svg'
                            className='badge-sample'
                        />
                    )}
                    {request?.loading ? (
                        <div className='loading-container'>
                            <LoadingOutlined className='content-loading' />
                        </div>
                    ) : null}
                </div>
                <p className='title'>
                    {changeTimeStampToText(data.expireDate)}
                    <Dropdown
                        overlay={dropdownBtn}
                        trigger={['click']}
                        placement='bottomLeft'
                    >
                        <SVG src='/images/dot-bookshelf.svg' className='dot' />
                    </Dropdown>
                </p>
            </div>
            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={data}
                    nextFunction={onReturnContent}
                />
            )}

            {readingModal && (
                <ReadingStatModal
                    visible={readingModal}
                    onClose={() => setReadingModal(false)}
                    licenseUID={data.licenseUID}
                />
            )}
        </>
    );
};
export const MemoEbook = memo(Ebook);

export const Video: React.FC<BookShelfContentProps> = ({
    data,
    onOpenContent,
    onReturnContent
}) => {
    const imgErrorSrc = '/images/book-placeholder.jpg';
    const [isImgError, setIsImgError] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request:
                state.fetch.id === data.licenseUID ? state.fetch : undefined
        }),
        shallowEqual
    );

    const imgError = (image: any) => {
        setIsImgError(true);
        image.onError = '';
        return true;
    };

    const dropdownBtn = (
        <Menu>
            <Menu.Item key='1' onClick={() => setConfirmDialog(true)}>
                คืนสื่อ
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className='video-collection'>
                <div
                    className={`cover video-content ${
                        request?.loading ? 'loading' : ''
                    }`}
                >
                    <div className={`filetype ${data.fileType}`}>
                        {data.fileType}
                    </div>
                    <img
                        key={data.licenseUID}
                        alt=''
                        src={isImgError ? imgErrorSrc : data.coverImage}
                        className={`imageView`}
                        onError={imgError}
                        onClick={() => {
                            onOpenContent(data.licenseUID);
                        }}
                    />
                    <div className='total-time'>{data.duration}</div>
                    {request?.loading ? (
                        <div className='loading-container'>
                            <LoadingOutlined className='content-loading' />
                        </div>
                    ) : null}
                </div>
                <p className='title'>
                    {changeTimeStampToText(data.expireDate)}
                    <Dropdown
                        overlay={dropdownBtn}
                        trigger={['click']}
                        placement='bottomLeft'
                    >
                        <SVG src='/images/dot-bookshelf.svg' className='dot' />
                    </Dropdown>
                </p>
            </div>
            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={data}
                    nextFunction={onReturnContent}
                />
            )}
        </>
    );
};

export const MemoVideo = memo(Video);

export const Podcast: React.FC<BookShelfContentProps> = ({
    data,
    onOpenContent,
    onReturnContent
}) => {
    const [isImgError, setIsImgError] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const imgError = (image: any) => {
        setIsImgError(true);
        image.onError = '';
        return true;
    };
    const imgErrorSrc = '/images/book-placeholder.jpg';
    const dropdownBtn = (
        <Menu>
            <Menu.Item key='1' onClick={() => setConfirmDialog(true)}>
                คืนสื่อ
            </Menu.Item>
        </Menu>
    );
    const { request } = useSelector(
        (state: HibraryRootState) => ({
            request:
                state.fetch.id === data.licenseUID ? state.fetch : undefined
        }),
        shallowEqual
    );
    return (
        <>
            <div className='bookshelf-collection'>
                <div className={`cover ${request?.loading ? 'loading' : ''}`}>
                    <div className={`filetype ${data.fileType}`}>
                        {data.fileType}
                    </div>
                    <img
                        key={data.licenseUID}
                        alt=''
                        src={isImgError ? imgErrorSrc : data.coverImage}
                        className={`imageView`}
                        onError={imgError}
                        onClick={() => {
                            onOpenContent(data.licenseUID);
                        }}
                    />
                    {request?.loading && (
                        <div className='loading-container'>
                            <LoadingOutlined className='content-loading' />
                        </div>
                    )}
                </div>
                <p className='title'>
                    {changeTimeStampToText(data.expireDate)}
                    <Dropdown
                        overlay={dropdownBtn}
                        trigger={['click']}
                        placement='bottomLeft'
                    >
                        <SVG src='/images/dot-bookshelf.svg' className='dot' />
                    </Dropdown>
                </p>
            </div>
            {confirmDialog && (
                <ConfirmModal
                    setConfirmDialog={setConfirmDialog}
                    data={data}
                    nextFunction={onReturnContent}
                />
            )}
        </>
    );
};

export const MemoPodcast = memo(Podcast);

export const ConfirmModal = ({
    setConfirmDialog,
    data,
    nextFunction
}: {
    setConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
    data: License;
    nextFunction: (licenseUID: string) => void;
}) => {
    const { formatMessage, locale } = useIntl();
    return (
        <>
            <Modal
                className='rental-modal'
                onCancel={() => setConfirmDialog(false)}
                open={true}
                title={formatMessage({ id: 'BookShelf.ModalReturn' })}
                footer={
                    <>
                        <Button
                            size='middle'
                            type='ghost'
                            shape='round'
                            key='back'
                            onClick={() => setConfirmDialog(false)}
                        >
                            {formatMessage({ id: 'BookShelf.ModalCancelBtn' })}
                        </Button>
                        <Button
                            size='middle'
                            type='primary'
                            shape='round'
                            key='confirm'
                            onClick={() => nextFunction(data.licenseUID)}
                        >
                            {formatMessage({ id: 'BookShelf.ModalReturnBtn' })}
                        </Button>
                    </>
                }
            >
                <div>
                    <h3>{data.title}</h3>
                    <p>
                        {formatMessage(
                            {
                                id: 'Book.ModalBorrowBookSubtitle'
                            },
                            {
                                0: ` ${dayjs
                                    .unix(data.expireDate)
                                    .format('DD MMM YYYY HH:mm')} ${
                                    locale === 'th' ? 'น.' : ''
                                }`
                            }
                        )}
                    </p>
                </div>
            </Modal>
        </>
    );
};
