import { Helmet } from 'react-helmet-async';
import { action } from '../../redux/request';
import { Loading } from '../../customUI/loading';
import { Row, Col } from 'antd';
import { CategoryView } from '../../components/category';
import { removeSplash } from '../../helpers/removeSplash';
import { HibraryRootState } from '../../../redux/rootReducer';
import { RenderHomeSegment } from '../home';
import { useHistory } from 'react-router-dom';
import { BreadcrumbLink, BreadCrumbView } from '../../components/breadcrumb';
import { useEffect, useState, memo, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getListSubCategoryRoute } from '../../routes/generatePath';
import {
   CategoryContent,
   ContentType,
   Home,
   HomeType,
   LicenseType,
   SubCategoryContent
} from '../../../interface/home';
import { ListAllCategoriesResponse } from '../../../interface/request';
import {
   findCategories,
   findCategoryDetail
} from '../../redux/categories/categoriesCrud';
import './index.scss';

export interface CategoriesPageProps {
   licenseType: LicenseType;
   contentType: ContentType;
}

export const CategoriesPage = (props: CategoriesPageProps) => {
   const pageId = 'CategoriesPage';
   const dispatch = useDispatch();
   const [categoriesList, setCategoriesList] =
      useState<ListAllCategoriesResponse>();
   const { clientInfo, authen } = useSelector(
      (state: HibraryRootState) => ({
         clientInfo: state.clientInfo,
         authen: state.auth,
         environment: state.environment
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchCategories();
   }, [clientInfo, authen]);

   useEffect(() => {
      console.log('<rerender>');
   });

   const fetchCategories = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await findCategories(
            props.licenseType,
            props.contentType,
            clientInfo.companyPrefix,
            authen.isLogin
         );
         setCategoriesList(data);
         dispatch(removeSplash);
         dispatch(action.setProcess(false, pageId));
      } catch (error) {
         dispatch(removeSplash);
         dispatch(action.setProcess(false, pageId));
      }
   };
   return (
      <>
         <BreadCrumbView />
         <div className='store view-all-content'>
            <Helmet>
               <title>
                  {window.location.hostname.match(/cu-elibrary.com/)
                     ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                     : 'Hibrary'}{' '}
                  | หมวดหมู่
               </title>
            </Helmet>

            <h1 className='viewAllTitle'>{'หมวดหมู่'}</h1>
            <div className='content-list'>
               <Row gutter={[16, 16]} className='list-row-categories'>
                  {categoriesList?.categories?.map((e, index: number) => (
                     <Col key={`col_${index}`} className='col-category'>
                        <CategoryView
                           category={e}
                           licenseType={props.licenseType}
                           contentType={props.contentType}
                        />
                     </Col>
                  ))}
               </Row>
            </div>
            <Loading id={pageId} />
         </div>
      </>
   );
};

interface CategoryContentViewProps {
   catId: string;
   licenseType: LicenseType;
   contentType: ContentType;
}

export const CategoryContentPage = (props: CategoryContentViewProps) => {
   const pageId = 'CategoryContentView';
   const dispatch = useDispatch();
   const history = useHistory();
   const [categoriesContent, setCategoriesContent] =
      useState<CategoryContent>();
   const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbLink[]>();
   const { clientInfo, authen } = useSelector(
      (state: HibraryRootState) => ({
         clientInfo: state.clientInfo,
         authen: state.auth,
         environment: state.environment
      }),
      shallowEqual
   );

   useEffect(() => {
      fetchData();
   }, [clientInfo, authen]);

   const fetchData = async () => {
      try {
         dispatch(action.setProcess(true, pageId));
         const data = await findCategoryDetail(
            props.catId,
            props.licenseType,
            props.contentType,
            clientInfo.companyPrefix,
            authen.isLogin
         );
         if (data!.subcats.length > 1) {
            setCategoriesContent(data);
         } else {
            history.push(
               getListSubCategoryRoute(
                  props.catId,
                  data!.subcats![0].subcatId!,
                  props.licenseType,
                  props.contentType
               )
            );
         }

         setBreadcrumbs(data?.catName ?? '');
         dispatch(action.setProcess(false, pageId));
         dispatch(removeSplash);
      } catch (error) {
         dispatch(action.setProcess(false, pageId));
         dispatch(removeSplash);
      }
   };
   const breadcrumbs = useMemo(() => {
      return <BreadCrumbView addLinks={breadCrumbs} />;
   }, [breadCrumbs]);
   const setBreadcrumbs = (catName: string) => {
      const breadcrumbs: BreadcrumbLink = {
         title: catName
      };
      setBreadCrumbs([breadcrumbs]);
   };

   return (
      <div className='store'>
         <Helmet>
            <title>
               {window.location.hostname.match(/cu-elibrary.com/)
                  ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
                  : 'Hibrary'}{' '}
               | หมวดหมู่ : {`${categoriesContent?.catName ?? ''}`}
            </title>
         </Helmet>
         {breadcrumbs}
         <h1 className='viewAllTitle'>{`${
            categoriesContent?.catName ?? ''
         }`}</h1>
         <div className='segment-list'>
            {categoriesContent?.subcats.map((data, index) => (
               <MemoSubCategorySegment
                  key={`segment_${index}`}
                  catId={props.catId}
                  subcategories={data}
                  licenseType={props.licenseType}
                  contentType={props.contentType}
               />
            ))}
         </div>
         <Loading id={pageId} />
      </div>
   );
};

interface SubCategorySegmentProps {
   catId: string;
   subcategories: SubCategoryContent;
   licenseType: LicenseType;
   contentType: ContentType;
}

const SubCategorySegment = (props: SubCategorySegmentProps) => {
   const key = `${props.subcategories.subcatId}`;
   const segment = props.subcategories as Home;
   segment.title = props.subcategories.subcatName;
   segment.type = HomeType.SubCategoryEbook;
   segment.catId = props.catId;
   segment.contentType = props.contentType;
   if (props.subcategories.ebooks && props.subcategories.ebooks.length > 0)
      segment.contents = props.subcategories.ebooks;

   return (
      <>
         <RenderHomeSegment
            key={key}
            segment={segment}
            indexSegment={0}
            licenseType={props.licenseType}
         />
      </>
   );
};
const MemoSubCategorySegment = memo(SubCategorySegment);
