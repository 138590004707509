import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Sponsor } from '../../../interface/sponsor';
import SponsorModal from '../../components/modal/sponsorModal/sponsorModal';
import { onErrorImage } from '../../Utilities/imageUtilities';
import './_index.scss';

interface SponsorCoverProps {
   content: Sponsor;
}

export const SponsorCover = (props: SponsorCoverProps) => {
   const history = useHistory();
   const [modalVisible, setModalVisible] = useState<boolean>(false);

   const onOpenModal = () => {
      history.push({ search: `?uid=${props.content.sponsorUID}` });
      setModalVisible(true);
   };
   return (
      <div className='sponsorStoreHome'>
         <div className='coverImage' onClick={() => onOpenModal()}>
            <img src={props.content.coverImage} onError={onErrorImage} />
         </div>
         <p onClick={() => onOpenModal()}>{props.content.name}</p>
         {modalVisible && (
            <SponsorModal visible={modalVisible} onCancel={() => setModalVisible(false)} />
         )}
      </div>
   );
};
