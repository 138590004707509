import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { action } from '../../redux/request';
import { formatDate } from '../../helpers/changeFormatDateTime';
import { chkLastEvaluateKey } from '../../redux/utilities';
import { removeSplash } from '../../helpers/removeSplash';
import { Helmet } from 'react-helmet-async';
import { Loading } from '../../customUI/loading';
import { ContentCover, ContentMetaView } from '../../components/contents';
import { RentBtn } from '../../customUI/RentButton';
import { EmptyDataView } from '../../components/nodata';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
  ContentDetail,
  HtiResponse,
  LicenseType,
  Queue,
  QueyListResponse,
  RentalStatus
} from '../../../interface';

import axios from 'axios';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import dayjs from 'dayjs';

export const QueuePage = () => {
  const intl = useIntl();
  const pageId = 'QueuePage';
  const titleText = intl.formatMessage({ id: 'Booking.Title' });
  const dispatch = useDispatch();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [contentList, setContentList] = useState<QueyListResponse>();

  useEffect(() => {
    setLoadingPage(false);
    fetchData();
  }, []);

  const { environment } = useSelector(
    (state: HibraryRootState) => ({
      environment: state.environment
    }),
    shallowEqual
  );

  useEffect(() => {
    if (environment.endOfScreen && contentList?.lastEvaluatedKey) {
      fetchData();
    }
  }, [environment]);

  const reqFunc = async () => {
    dispatch(action.setProcess(true, pageId));
    const qString: any = chkLastEvaluateKey(contentList?.lastEvaluatedKey);
    const {
      data: { data }
    } = await axios.get<HtiResponse<QueyListResponse>>(
      queryString.stringifyUrl({
        url: '/licenses/waiting',
        query: qString
      })
    );

    const currentList = contentList?.ebooks ?? [];
    setContentList({
      ebooks: currentList.concat(data!.ebooks),
      lastEvaluatedKey: data?.lastEvaluatedKey
    });
    dispatch(removeSplash);
    dispatch(action.setProcess(false, pageId));
    setLoadingPage(true);
  };

  const fetchData = async () => {
    try {
      reqFunc();
    } catch (error) {
      dispatch(action.setProcess(false, pageId));
      dispatch(removeSplash);
    }
  };

  return (
    <>
      {/* <BreadCrumbView /> */}
      <div
        className={`qNh ${
          contentList && contentList?.ebooks.length === 0 ? 'emptyView' : ''
        }`}
      >
        <Helmet>
          <title>
            {window.location.hostname.match(/cu-elibrary.com/)
              ? process.env.REACT_APP_CU_ELIBRARY_APP_NAME
              : 'Hibrary'}{' '}
            | {titleText}
          </title>
        </Helmet>

        <h1>{titleText}</h1>
        <Loading id={pageId} />
        {contentList && contentList?.ebooks.length > 0 ? (
          <div className="gridContainer">
            {contentList.ebooks.map((e: Queue, index: any) => (
              <QueueView
                content={e}
                key={`${e.contentUID}_${e.registerDate}_${index}`}
              />
            ))}
          </div>
        ) : loadingPage ? (
          <EmptyDataView />
        ) : null}
      </div>
    </>
  );
};

interface QueueViewParam {
  content: Queue;
}
export const QueueView = (props: QueueViewParam) => {
  const content = props.content;
  const intl = useIntl();

  const checkIsExpire = () => {
    if (props.content?.expireDate) {
      return dayjs().unix() > props.content?.expireDate;
    } else {
      return false;
    }
  };

  return (
    <div className="contentCover">
      <ContentCover
        content={content}
        licenseType={content.licenseType as LicenseType}
      />
      <div className="bookDetail">
        <ContentMetaView
          content={content}
          licenseType={content.licenseType as LicenseType}
        />
        <div>
          {checkIsExpire() ? (
            <Tag color="error" className="expire-tag">
              อีบุ๊กหมดอายุแล้ว
            </Tag>
          ) : null}

          <div className="subtitle">
            {intl.formatMessage({ id: 'Book.Queue' })}
          </div>
          <div className="subtitle-detail">{content.waitingQueue} </div>
        </div>
        {content.rentTimeLimit ? (
          <div>
            <div className="subtitle">
              {intl.formatMessage({ id: 'Booking.BorrowBy' })}
            </div>
            <div className="subtitle-detail">
              {formatDate(content.rentTimeLimit, 'en', 'DD/MM/YYYY')}
            </div>
          </div>
        ) : null}
        <RentBtn
          content={content as ContentDetail}
          size="middle"
          contentType={content.contentType}
          status={content.rentalStatus!}
          style={{ width: 100 }}
          licenseType={content.licenseType as LicenseType}
          title={
            content.rentalStatus === RentalStatus.WaitingListed
              ? intl.formatMessage({
                  id: 'Booking.ModalCancelBtn'
                })
              : intl.formatMessage({ id: 'Book.BorrowBtn' })
          }
        />
      </div>
    </div>
  );
};
