import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCategories } from '../../redux/catalog/catalogCrud';
import { SubCatData } from '../../../interface/listEbook';
import { removeSplash } from '../../helpers/removeSplash';
import { useDispatch } from 'react-redux';
import { Loading } from '../../customUI/loading';
import { action } from '../../redux/request';

import FastAverageColor from 'fast-average-color';
import '../../components/category/index.scss';
import { onErrorImage } from '../../Utilities/imageUtilities';

interface IColor {
  value?: any;
  isLight?: any;
  hex: string;
}
interface categoryCard {
  coverImage: string;
  catName: string;
  catId: number;
  contentAmount?: number;
  oldUrl?: number;
  bgColor?: string;
}

interface categoryProps {
  subCategory?: SubCatData;
}

export const Category = (props: categoryProps) => {
  const pageId = 'catalogCategoryPage';
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<categoryCard[]>();

  useEffect(() => {
    dispatch(action.setProcess(true, pageId));
    getCategories().then((res) => {
      setCategories(res.categories);
      removeSplash(dispatch);
      dispatch(action.setProcess(false, pageId));
    });
  }, []);

  return (
    <>
      <div className="categoryContainer">
        {categories !== undefined &&
        props.subCategory?.subcats === undefined ? (
          <div className="categoryContentList">
            {categories.map((el) => {
              return (
                <CategoryCard
                  key={el.catId}
                  coverImage={el.coverImage}
                  catName={el.catName}
                  catId={el.catId}
                  contentAmount={el.contentAmount}
                />
              );
            })}
          </div>
        ) : (
          props.subCategory?.subcats !== undefined && (
            <div className="categoryContentList">
              {props.subCategory?.subcats.map((el) => {
                return (
                  <CategoryCard
                    key={el.subcatId}
                    coverImage={el.coverImage}
                    catName={el.subcatName}
                    catId={el.subcatId}
                    oldUrl={props.subCategory?.catId}
                  />
                );
              })}
            </div>
          )
        )}
      </div>
      <Loading id={pageId} />
    </>
  );
};

export const CategoryCard = (props: categoryCard) => {
  const [color, setColor] = useState<IColor | undefined>(
    props.bgColor ? { hex: props.bgColor } : undefined
  );
  const [backgroundColor, setBackgroundColor] = useState<string>();
  const [fontColor, setFontColor] = useState<string>();
  const root = document.documentElement;
  const style = getComputedStyle(root);

  const adjustBrightness = (hexCode: string, adjustPercent: number): string => {
    hexCode = hexCode.replace('#', '');

    if (hexCode.length === 3) {
      hexCode =
        hexCode[0] +
        hexCode[0] +
        hexCode[1] +
        hexCode[1] +
        hexCode[2] +
        hexCode[2];
    }

    const hexArray = hexCode.match(/.{2}/g);
    if (!hexArray) {
      throw new Error('Invalid hex code');
    }

    const adjustedHexArray = hexArray.map((hex) => {
      const color = parseInt(hex, 16);
      const adjustableLimit = adjustPercent < 0 ? color : 255 - color;
      const adjustAmount = Math.ceil(adjustableLimit * adjustPercent);
      return (
        '0' + Math.min(255, Math.max(0, color + adjustAmount)).toString(16)
      ).slice(-2);
    });

    return '#' + adjustedHexArray.join('');
  };

  const primaryColor = adjustBrightness(
    style.getPropertyValue('--primary'),
    0.8
  );

  useEffect(() => {
    if (props.bgColor === undefined) {
      generateBackgroundColor();
    } else {
      setFontColor(isDarkColor(props.bgColor) ? '#ffffff' : '#000000');
    }
  }, []);

  useEffect(() => {
    if (color) {
      const backgroundElement = color;
      const background =
        backgroundElement !== undefined
          ? backgroundElement &&
            backgroundElement.value &&
            backgroundElement.value[0] > 230 &&
            backgroundElement.value[1] > 230 &&
            backgroundElement.value[2] > 230
            ? 'rgb(230, 230, 230)'
            : backgroundElement && backgroundElement.hex
          : 'rgb(230, 230, 230)';
      setBackgroundColor(background);

      if (backgroundElement) {
        setFontColor(isDarkColor(color.hex) ? '#ffffff' : '#000000');
      } else {
        setFontColor('#000000');
      }
    }
  }, [color]);

  const isDarkColor = (hexCode: string): boolean => {
    hexCode = hexCode.replace('#', '');
    const r = parseInt(hexCode.substring(0, 2), 16);
    const g = parseInt(hexCode.substring(2, 4), 16);
    const b = parseInt(hexCode.substring(4, 6), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance < 128;
  };

  const generateBackgroundColor = () => {
    let loaded = false;
    const img = document.getElementById(
      props.coverImage ? props.coverImage.toString() : props.catId.toString()
    ) as HTMLImageElement;

    if (typeof img !== 'undefined' && img !== null && props.coverImage) {
      const loadHandler = () => {
        if (loaded) {
          return;
        }
        loaded = true;
        const fac = new FastAverageColor();
        if (img) {
          const googleProxyURL =
            'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
          const finalScr =
            googleProxyURL + encodeURIComponent(props.coverImage!);
          fac
            .getColorAsync(finalScr, {
              ignoredColor: [255, 255, 255, 255] // white
            })
            .then((c: any) => {
              setColor(c);
            })
            .catch(() => setColor({ hex: primaryColor }));
        }
      };
      img.addEventListener('load', loadHandler, false);
      return () => {
        img.removeEventListener('load', loadHandler, false);
      };
    }
  };

  return (
    <Link
      to={{
        pathname: props.oldUrl
          ? `/listEbook/cat/${props.oldUrl}/${props.catId}`
          : `/listEbook/cat/${props.catId}`
      }}
    >
      <div
        className="category fadeIn"
        style={{
          background: props && props.catId ? backgroundColor : 'var(--grey4)'
        }}
      >
        {props && props.catId ? (
          <div
            className="title"
            style={{
              color: fontColor
            }}
          >
            {props.catName}
          </div>
        ) : null}
        <div className={props.catId ? 'cover' : 'cover bigCat'}>
          <img
            id={props.coverImage || `${props.catId}` || ''}
            src={props.coverImage + ''}
            className="ebook"
            alt="book cover2"
            onError={onErrorImage}
          />
        </div>
        {props && props.catId ? null : (
          <div className="title" style={{ color: '#fff', fontSize: '18px' }}>
            {props.catName}
          </div>
        )}
      </div>
    </Link>
  );
};
