import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onErrorImage } from '../../Utilities/imageUtilities';
import FastAverageColor from 'fast-average-color';
import { Category, ContentType, LicenseType } from '../../../interface/home';
import {
  getListCategoryRoute,
  getListSubInCategoryRoute
} from '../../routes/generatePath';
import './index.scss';

export interface CategoryViewProps {
  category: Category;
  licenseType: LicenseType;
  contentType: ContentType;
}
interface IColor {
  value?: any;
  isLight?: any;
  hex: string;
}

export const CategoryView = (props: CategoryViewProps) => {
  const [color, setColor] = useState<IColor>();
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    props.category.bgColor
  );
  const [fontColor, setFontColor] = useState<string>();
  const { licenseType } = props;
  const root = document.documentElement;
  const style = getComputedStyle(root);

  const adjustBrightness = (hexCode: string, adjustPercent: number): string => {
    hexCode = hexCode.replace('#', '');

    if (hexCode.length === 3) {
      hexCode =
        hexCode[0] +
        hexCode[0] +
        hexCode[1] +
        hexCode[1] +
        hexCode[2] +
        hexCode[2];
    }

    const hexArray = hexCode.match(/.{2}/g);
    if (!hexArray) {
      throw new Error('Invalid hex code');
    }

    const adjustedHexArray = hexArray.map((hex) => {
      const color = parseInt(hex, 16);
      const adjustableLimit = adjustPercent < 0 ? color : 255 - color;
      const adjustAmount = Math.ceil(adjustableLimit * adjustPercent);
      return (
        '0' + Math.min(255, Math.max(0, color + adjustAmount)).toString(16)
      ).slice(-2);
    });

    return '#' + adjustedHexArray.join('');
  };

  const primaryColor = adjustBrightness(
    style.getPropertyValue('--primary'),
    0.8
  );

  useEffect(() => {
    if (props.category.bgColor === undefined) {
      generateBackgroundColor();
    }
  }, []);

  useEffect(() => {
    if (backgroundColor)
      setFontColor(checkColorIsLight(backgroundColor) ? '#ffffff' : '#000000');
  }, [backgroundColor]);

  useEffect(() => {
    if (color) {
      const backgroundElement = color;
      const background =
        backgroundElement !== undefined
          ? backgroundElement &&
            backgroundElement.value &&
            backgroundElement.value[0] > 230 &&
            backgroundElement.value[1] > 230 &&
            backgroundElement.value[2] > 230
            ? 'rgb(230, 230, 230)'
            : backgroundElement && backgroundElement.hex
          : 'rgb(230, 230, 230)';
      setBackgroundColor(background);

      if (backgroundElement) {
        setFontColor(checkColorIsLight(color.hex) ? '#ffffff' : '#000000');
      } else {
        setFontColor('#000000');
      }
    }
  }, [color]);

  const bgBigCat = (contentType: ContentType) => {
    switch (contentType) {
      case ContentType.ebook:
        return '#2D9CDB';
      case ContentType.video:
        return '#27AE60';
      case ContentType.podcast:
        return '#FBC020';
      default:
        return '#ffffff';
    }
  };

  const checkColorIsLight = (color: string) => {
    color = color.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance < 128;
  };

  const generateBackgroundColor = () => {
    let loaded = false;
    const img = document.getElementById(
      props.category.coverImage
        ? props.category.coverImage.toString()
        : props.category.catId.toString()
    ) as HTMLImageElement;

    if (
      typeof img !== 'undefined' &&
      img !== null &&
      props.category.coverImage
    ) {
      const loadHandler = () => {
        if (loaded) {
          return;
        }
        loaded = true;
        const fac = new FastAverageColor();
        if (img) {
          const googleProxyURL =
            'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
          const finalScr =
            googleProxyURL + encodeURIComponent(props.category.coverImage!);
          fac
            .getColorAsync(finalScr, {
              ignoredColor: [255, 255, 255, 255] // white
            })
            .then((c: any) => {
              setColor(c);
            })
            .catch(() => setColor({ hex: primaryColor }));
        }
      };
      img.addEventListener('load', loadHandler, false);
      return () => {
        img.removeEventListener('load', loadHandler, false);
      };
    } else {
      setColor({ hex: primaryColor });
    }
  };

  return (
    <Link
      to={{
        pathname: props.category.catId
          ? getListSubInCategoryRoute(
              props.category.catId,
              licenseType,
              props.category.contentType
            )
          : getListCategoryRoute(licenseType, props.category.contentType)
      }}
    >
      <div
        className="category fadeIn"
        style={{
          background:
            props.category && props.category.catId
              ? backgroundColor
              : bgBigCat(props.category && props.category.contentType!)
        }}
      >
        {props.category && props.category.catId ? (
          <div
            className="title"
            style={{
              color: fontColor
            }}
          >
            {props.category.catName}
          </div>
        ) : null}
        <div className={props.category.catId ? 'cover' : 'cover bigCat'}>
          <img
            id={props.category.coverImage || `${props.category.catId}` || ''}
            src={props.category.coverImage + ''}
            className={
              props.contentType === ContentType.video ? 'video' : 'ebook'
            }
            alt="book cover2"
            onError={onErrorImage}
          />
        </div>
        {props.category && props.category.catId ? null : (
          <div className="title" style={{ color: '#fff', fontSize: '18px' }}>
            {props.category.catName}
          </div>
        )}
      </div>
    </Link>
  );
};
