import { SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoginRequest, login as loginAPI } from '../../../../redux/auth/authCrud';
import { useDispatch } from 'react-redux';
import {
    AuthPageType,
    AxiosError,
    ClientInfo,
    Device,
    HtiErrorResponse,
    ManageDeviceType
} from '../../../../../interface';
import { useFormik } from 'formik';
import { actions } from '../../../../redux/auth/authRedux';
import { Button, Input, Modal, Tooltip } from 'antd';
import { checkRedirect } from '../../../../Utilities/validateText';
import { ManageDevice } from '../../../../pages/devices';
import SVG from 'react-inlinesvg';
import * as Yup from 'yup';
import * as home from '../../../../redux/home';
import '../index.scss';

const initialValues: LoginRequest = {
    userID: '',
    password: ''
};

interface LoginProps {
    clientInfo: ClientInfo;
    setPageType: React.Dispatch<SetStateAction<AuthPageType>>;
}

const Login = (props: LoginProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [seePassword, setSeePassword] = useState<boolean>(false);
    const [manageDevice, setManageDevice] = useState<boolean>(false);
    const [status, setStatus] = useState<string>();
    const [devices, setDevices] = useState<{
        devices: Device[];
        memberUID: string;
    }>();

    const eyeIcon = (
        <Tooltip title={seePassword ? 'ซ่อนรหัสผ่าน' : 'แสดงรหัสผ่าน'}>
            <SVG
                src={seePassword ? `/images/eye-slash.svg` : `/images/eye.svg`}
                onClick={() => setSeePassword(!seePassword)}
            />
        </Tooltip>
    );

    const onLogin = async (values: LoginRequest) => {
        try {
            setLoading(true);
            const {
                data: { data }
            } = await loginAPI({
                userID: values.userID,
                password: values.password,
                companyPrefix: props.clientInfo.companyPrefix
            });
            if (data && !checkRedirect(data.userToken, data.code)) {
                dispatch(actions.login(data.userToken));
                dispatch(home.action.resetData());
            }
        } catch (error) {
            const getError = error as AxiosError<HtiErrorResponse<ManageDeviceType>>;
            if (getError.response.data.errorCode === '004') {
                showRemoveDeviceConfirm();
                setDevices({
                    devices: getError.response.data.data?.devices || [],
                    memberUID: getError.response.data.data?.memberUID || ''
                });
            } else setStatus(getError.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const schema = Yup.object().shape({
        userID: Yup.string().required('กรุณากรอกอีเมล หรือ รหัสประจำตัว'),
        password: Yup.string()
            .min(6, 'อย่างน้อย 6 ตัวอักษร')
            .max(50, 'ไม่เกิน 50 ตัวอักษร')
            .required('กรุณากรอกรหัสผ่าน')
    });

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: onLogin
    });

    useEffect(() => {
        if (status) setStatus(undefined);
    }, [formik.values]);

    const showRemoveDeviceConfirm = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'SignIn.AlertDeviceOverLimitTitle' }),
            content: intl.formatMessage({ id: 'SignIn.DeviceOverLimitMessage' }),
            okText: intl.formatMessage({ id: 'Action.Manage' }),
            cancelText: intl.formatMessage({ id: 'Action.CancelBtn' }),
            onOk: () => setManageDevice(true)
        });
    };

    return (
        <>
            <div className='loginContainer'>
                <h1>{intl.formatMessage({ id: 'SignIn.Title' })}</h1>
                <form onSubmit={formik.handleSubmit}>
                    <div className='inputItem'>
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'SignIn.EmailPlaceHolder'
                            })}
                            className='formInput'
                            onChange={(e) => formik.setFieldValue('userID', e.target.value)}
                        />
                        {formik.touched.userID && formik.errors.userID ? (
                            <div className='error-text'>{formik.errors.userID}</div>
                        ) : null}
                    </div>
                    <div className='inputItem'>
                        <Input
                            type={seePassword ? 'text' : 'password'}
                            placeholder={intl.formatMessage({
                                id: 'SignIn.PasswordPlaceHolder'
                            })}
                            onChange={(e) => formik.setFieldValue('password', e.target.value)}
                            className='formInput'
                            suffix={eyeIcon}
                        />
                        <div>
                            <div className='error-text'>
                                {formik.touched.password && formik.errors.password
                                    ? formik.errors.password
                                    : ''}
                            </div>
                            <span
                                className='forgotPassword'
                                itemType='button'
                                onClick={() => props.setPageType(AuthPageType.ForgotPassword)}
                            >
                                {intl.formatMessage({
                                    id: 'SignIn.ForgotPassword'
                                })}
                            </span>
                        </div>
                    </div>
                    <div className='errorField'>{status && <div>{status}</div>}</div>
                    <div className='buttonContain'>
                        <Button className='acceptButton' loading={loading} htmlType='submit'>
                            {loading
                                ? intl.formatMessage({ id: 'Action.JustAMoment' })
                                : intl.formatMessage({ id: 'SignIn.SignInBtn' })}
                        </Button>
                        {props.clientInfo.isPublicRegister ||
                        props.clientInfo.emailDomainRegister ||
                        props.clientInfo.geoLocation ? (
                            <span
                                className='register'
                                onClick={() => props.setPageType(AuthPageType.Register)}
                            >
                                {intl.formatMessage({ id: 'SignIn.RegisterBtn' })}
                            </span>
                        ) : null}
                    </div>
                </form>
            </div>
            <Modal
                destroyOnClose
                footer={null}
                open={manageDevice}
                onCancel={() => setManageDevice(false)}
                title={'จัดการอุปกรณ์'}
                maskClosable={false}
            >
                <ManageDevice devices={devices?.devices} memberUID={devices?.memberUID} />
            </Modal>
        </>
    );
};

export default Login;
