// import { Content, DisplayType } from "../../../interface";
import { onErrorImage } from '../../Utilities/imageUtilities';
import TagFileType from '../tag/TagFileType';

import './index.scss';

interface CoverProps {
   coverImage: string;
   fileType?: string;
   onClick?: () => void;
}

export const ImageCover = (props: CoverProps) => {
   return (
      <div
         className='image-cover'
         onClick={() => {
            if (props.onClick) props.onClick();
         }}
      >
         <img
            src={props.coverImage + ''}
            alt={props.coverImage}
            id={props.coverImage}
            onError={onErrorImage}
         />
         {props.fileType && <TagFileType fileType={props.fileType ?? ''} />}
      </div>
   );
};
