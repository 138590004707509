import { DetailViewParam } from '../../../interface/detailPage';
import {
   getListSubCategoryRoute,
   getListSubInCategoryRoute
} from '../../routes/generatePath';
import TagStyle from '../tag/Tags';

export const TagsView = (props: DetailViewParam) => {
   const content = props.content;
   return (
      <div className='tagContainer'>
         {/* <CategoryView content={content} /> */}
         <TagStyle
            text={props.content.catName!}
            path={getListSubInCategoryRoute(
               content.catId!,
               content.licenseType,
               content.contentType
            )}
         />
         {content.subcatName && content.catName !== content.subcatName ? (
            //  <SubCategoryView content={content} />
            <TagStyle
               text={props.content.subcatName!}
               path={getListSubCategoryRoute(
                  content.catId!,
                  content.subcatId!,
                  content.licenseType,
                  content.contentType
               )}
            />
         ) : null}
      </div>
   );
};
