/* eslint-disable @typescript-eslint/no-unused-vars */
import { RenderHomeSegment } from '.';
import { Home, LicenseType } from '../../../interface';
import { EmptyDataView } from '../../components/nodata';
import { Loading } from '../../customUI/loading';
import HomeSegmentNew from './homeSegment_new';
// import HomeSegmentNew from './homeSegment_new';
interface StoreHomeListProps {
    homes?: Home[];
    licenseType: LicenseType;
}

const StoreHomeList = (props: StoreHomeListProps) => {
    const pageId = 'StoreHomeList';

    return (
        <>
            {props.homes ? (
                props.homes.length > 0 ? (
                    <div className='segment-list'>
                        {props.homes.map((el, index) => {
                            const key = `${props.licenseType}${index}`;
                            return (
                                <RenderHomeSegment
                                    key={key}
                                    segment={el}
                                    indexSegment={index}
                                    licenseType={props.licenseType}
                                />
                                //   <HomeSegmentNew
                                //       key={key}
                                //       segment={el}
                                //       indexSegment={index}
                                //       licenseType={props.licenseType}
                                //   />
                            );
                        })}
                    </div>
                ) : (
                    <EmptyDataView />
                )
            ) : null}
            <Loading id={pageId} />
        </>
    );
};

export default StoreHomeList;
