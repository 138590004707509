import { memo } from 'react';
import dayjs from 'dayjs';
import { Button } from 'antd';
import { RentBtn } from '../../customUI/RentButton';
import { PodcastContent, PodcastEpisode, RentalStatus } from '../../../interface/home';

export interface PodcastEpisodeParam {
    podcast: PodcastContent;
    episode: PodcastEpisode;
    currentEpisode?: PodcastEpisode;
    isPlaying: boolean;
    setCurrentEpisode: (e: PodcastEpisode, isPlaying: boolean) => void;
}

export const PodcastEpisodeView = (props: PodcastEpisodeParam) => {
    const onPlayClick = () => {
        props.setCurrentEpisode(props.episode, !props.isPlaying);
    };

    return (
        <>
            <div className='boxEpisode'>
                <div className='flex'>
                    <div className='title'>{props.episode.title}</div>
                    <p className='time'>
                        {dayjs.unix(props.episode.uploadDate).format('DD MMM YYYY')}
                    </p>
                </div>
                <div className='flex'>
                    <div className='duration'>{props.episode.duration}</div>
                    {props.podcast.rentalStatus === RentalStatus.Rented ? (
                        <Button className='episodeButton' onClick={() => onPlayClick()}>
                            <div className='episode-player-stop'>
                                <img
                                    src={
                                        props.isPlaying
                                            ? '/images/player/stop.svg'
                                            : '/images/player/play-circle.svg'
                                    }
                                    alt=''
                                />
                            </div>
                        </Button>
                    ) : (
                        <RentBtn
                            customBtn={
                                <Button className='episodeButton'>
                                    <div className='episode-player-stop'>
                                        <img src={'/images/player/play-circle.svg'} alt='' />
                                    </div>
                                </Button>
                            }
                            content={props.podcast}
                            size='small'
                            contentType={props.podcast.contentType}
                            status={props.podcast.rentalStatus!}
                            style={{ height: 30 }}
                            licenseType={props.podcast.licenseType}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export const MemoPodcastEpisodeView = memo(PodcastEpisodeView);
