/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../helpers/changeFormatDateTime';
import { Divider } from 'antd';
import { RentBtn } from '../../customUI/RentButton';
import { getCreatorRoute } from '../../routes/generatePath';
import {
    LicenseType,
    RentalStatus,
    VideoContent,
    ContentType
} from '../../../interface/home';
import Hls from 'hls.js';
import parse from 'html-react-parser';
import 'video-react/dist/video-react.css'; // import css
import { SegmentHeader } from '../home/segmentHeader';
import { RelatedView } from '../../components/detailPage/RelateView';
import { TagsView } from '../../components/detailPage/TagsView';
import { useIntl } from 'react-intl';
const {
    Player,
    LoadingSpinner,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    BigPlayButton,
    VolumeMenuButton,
    PlayToggle
} = require('video-react');

export interface VideoViewParam {
    content: VideoContent;
    licenseType?: LicenseType;
}

export const VideoDetailView = (props: VideoViewParam) => {
    const video = props.content;
    const intl = useIntl();

    const onPlayClicked = () => {
        //fetchUpdatePlayList();
    };

    return (
        <>
            <div>
                {video.playlist ? (
                    <div className='videoPlayerView'>
                        <Player
                            playsInline
                            fluid={false}
                            width='100%'
                            height={'100%'}
                            poster={
                                video.coverImage ? video.coverImage : undefined
                            }
                            startTime={0}
                            autoPlay={false}
                        >
                            <BigPlayButton position='center' />
                            <LoadingSpinner />
                            {video.playlist ? (
                                <HLSSource
                                    isVideoChild
                                    src={video.playlist}
                                    contentUID={video.contentUID}
                                />
                            ) : null}

                            <ControlBar
                                autoHide={true}
                                className='video-control'
                            >
                                <ReplayControl seconds={10} order={1.1} />
                                <ForwardControl seconds={30} order={1.2} />
                                <CurrentTimeDisplay order={4.1} />
                                <TimeDivider order={4.2} />
                                <PlaybackRateMenuButton
                                    rates={[5, 2, 1, 0.5, 0.1]}
                                    order={7.1}
                                />
                                <VolumeMenuButton disabled />
                                <PlayToggle />
                            </ControlBar>
                        </Player>
                    </div>
                ) : (
                    <div className='coverVideoContainer video-react'>
                        <img src={video.coverImage} alt='' />
                        {video.rentalStatus === RentalStatus.Rent ? (
                            <RentBtn
                                customBtn={
                                    <button
                                        className='video-react-big-play-button video-react-big-play-button-center'
                                        type='button'
                                        aria-live='polite'
                                        tabIndex={0}
                                    >
                                        <span className='video-react-control-text'>
                                            Play Video
                                        </span>
                                    </button>
                                }
                                content={video}
                                size='small'
                                contentType={video.contentType}
                                status={video.rentalStatus!}
                                disabled={false}
                                style={{ height: 40 }}
                                licenseType={video.licenseType}
                            />
                        ) : (
                            <button
                                className='video-react-big-play-button video-react-big-play-button-center'
                                type='button'
                                aria-live='polite'
                                tabIndex={0}
                                onClick={onPlayClicked}
                            >
                                <span className='video-react-control-text'>
                                    Play Video
                                </span>
                            </button>
                        )}
                    </div>
                )}
                <div className='title'>{video.title}</div>
                <div className='videoData'>
                    <div className='view'>
                        <div className='view-count'>
                            {intl.formatMessage(
                                { id: 'Video.Viewing' },
                                { 0: video.view }
                            )}
                            {video.uploadDate && ' • '}
                            {formatDate(
                                video.uploadDate || 0,
                                intl.locale === 'th' ? 'th' : 'en',
                                'DD MMMM YYYY'
                            )}
                        </div>
                        <div className='flex boxElement'>
                            <EbookElementNumber
                                title={intl.formatMessage({
                                    id: 'Video.Language'
                                })}
                                detail={video.language}
                            />
                            <Divider type='vertical' />
                            <EbookElementNumber
                                title={intl.formatMessage({
                                    id: 'Video.Remaining'
                                })}
                                detail={
                                    Number.isInteger(video.remainConcurrent)
                                        ? video.remainConcurrent
                                        : intl.formatMessage({
                                              id: 'Video.Unlimited'
                                          })
                                }
                            />
                            <Divider type='vertical' />
                            <EbookElementNumber
                                title={intl.formatMessage({
                                    id: 'Video.All'
                                })}
                                detail={
                                    video.concurrent
                                        ? video.concurrent
                                        : intl.formatMessage({
                                              id: 'Video.Unlimited'
                                          })
                                }
                            />
                            <Divider type='vertical' />
                            <EbookElementNumber
                                title={intl.formatMessage({
                                    id: 'Video.Borrowed'
                                })}
                                detail={video.totalRent ? video.totalRent : 0}
                            />
                        </div>
                    </div>
                </div>
                <Divider />
                <div className='boxChannel'>
                    <div>
                        <Link
                            to={{
                                pathname: getCreatorRoute(
                                    video.creatorUID,
                                    video.licenseType,
                                    video.contentType ?? ContentType.video
                                )
                            }}
                        >
                            <div className='flex'>
                                <img
                                    className='channelImage'
                                    src={video.channelImage}
                                    alt=''
                                />
                                <div className='channel-name'>
                                    {video.channelName}
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <RentBtn
                            content={video}
                            size='small'
                            contentType={video.contentType}
                            status={video.rentalStatus!}
                            style={{ height: 40, minWidth: 150 }}
                            licenseType={video.licenseType}
                        />
                    </div>
                </div>
                <Divider />
                <TagsView content={video} />
                <Divider />
            </div>
            {video.description ? (
                <div>
                    <SegmentHeader left='รายละเอียด' right={false} />
                    <div className='description'>
                        {parse(video.description)}
                    </div>
                </div>
            ) : null}
            {video.relatedContents && video.relatedContents.length > 0 ? (
                <div>
                    <SegmentHeader left='วิดีโอที่เกี่ยวข้อง' right={false} />
                    <RelatedView content={video} />
                </div>
            ) : null}
        </>
    );
};

interface EbookElementNumberParam {
    title: string;
    detail: string | number | undefined;
}

export const EbookElementNumber = (props: EbookElementNumberParam) => {
    return (
        <div className='flex'>
            <div className='text-primary-big flex'>
                {props.title}
                <span className='hide'> :</span>
            </div>
            <div className='text-second-big'>{props.detail}</div>
        </div>
    );
};

interface HLSSourceParam {
    isVideoChild: boolean;
    src: string;
    contentUID: string;
    video?: HTMLMediaElement;
    player?: any;
    setVideoWidthHeight?: any;
    type?: any;
}
export const HLSSource = (props: HLSSourceParam) => {
    const player = props.player;
    const video = props.video;
    const hls = new Hls();
    const [isActive, setIsActive] = useState(0);

    useEffect(() => {
        if (Hls.isSupported()) {
            hls.loadSource(props.src);
            if (video) hls.attachMedia(video);
        }
    }, []);

    useEffect(() => {
        if (isActive === 0) {
            if (props.player.isActive) {
                setIsActive(1);
            }
            if (
                props.player &&
                props.setVideoWidthHeight &&
                props.src &&
                props.contentUID
            ) {
                props.setVideoWidthHeight({
                    width:
                        player.videoWidth > player.videoHeight
                            ? 1920
                            : player.videoWidth,
                    height:
                        player.videoWidth > player.videoHeight
                            ? 1080
                            : player.videoHeight
                });
            }
        }
    }, [isActive, player, props]);

    return (
        <source src={props.src} type={props.type || 'application/x-mpegURL'} />
    );
};

export const MemoVideoDetailView = memo(VideoDetailView);
