import { AutoComplete, Input } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ContentType, LicenseType } from '../../../interface';
import { HibraryRootState } from '../../../redux/rootReducer';
import {
    AuthorResult,
    AutoCompleteKey,
    CategoryResult,
    fetchAutocomplete,
    KeywordResult,
    PublisherResult,
    saveSearchAutoComplete,
    saveSearchAutoCompletePublic,
    SearchType,
    TitleResult
} from '../../redux/search/action';
import {
    getCreatorRoute,
    getDetailRoute,
    getKeywordRoute,
    getListSubCategoryRoute,
    getWriterRoute
} from '../../routes/generatePath';

import './index.scss';

const SearchBox = () => {
    const intl = useIntl();
    const history = useHistory();
    const [options, setOption] = useState<any[]>();
    const [isLoading, setIsLoading] = useState(false);

    const { clientInfo, authState } = useSelector(
        (state: HibraryRootState) => ({
            authState: state.auth,
            clientInfo: state.clientInfo
        }),
        shallowEqual
    );

    const renderTitle = (title: string) => (
        <div className='autocomplete-header'>
            <p>{title}</p>
        </div>
    );

    const onLinkClick = async (keyword: string) => {
        setOption(undefined);
        if (authState.isLogin) {
            saveSearchAutoComplete(keyword);
        } else {
            saveSearchAutoCompletePublic(keyword, clientInfo.companyPrefix);
        }
    };

    const renderItem = (title: string, key?: string, link?: string) => ({
        value: title,
        label: (
            <Link to={link ?? ''} onClick={() => onLinkClick(title)}>
                <div key={key} className='autocomplete-item'>
                    {title}
                </div>
            </Link>
        )
    });

    const onChange = async (el: string) => {
        try {
            if (el.length > 3) {
                setIsLoading(true);
                const result = await fetchAutocomplete(
                    el,
                    clientInfo.companyPrefix,
                    authState.isLogin
                );
                const optionConvert = createSearchAutoComplete(result! as any);
                setOption(optionConvert);
            } else {
                setOption([]);
            }
        } catch (error) {
            setOption([]);
        }
        setIsLoading(false);
    };

    const getAutocompleteLink = (
        data:
            | AuthorResult
            | TitleResult
            | PublisherResult
            | KeywordResult
            | CategoryResult,
        licenseType: LicenseType = LicenseType.Rent
    ) => {
        switch (data.type) {
            case SearchType.AUTHOR: {
                const getData = data as AuthorResult;
                return getWriterRoute(getData.keyword, licenseType);
            }

            case SearchType.KEYWORD: {
                const getData = data as TitleResult;
                return getKeywordRoute(getData.keyword, licenseType);
            }
            case SearchType.TITLE: {
                const getData = data as TitleResult;
                return getDetailRoute(
                    getData.contentUID,
                    getData.contentType,
                    licenseType
                );
            }
            case SearchType.PUBLISHER: {
                const getData = data as PublisherResult;
                return getCreatorRoute(getData.creatorUID, licenseType);
            }
            case SearchType.CATEGORY: {
                const getData = data as CategoryResult;
                return getListSubCategoryRoute(
                    getData.catId,
                    getData.subcatId,
                    licenseType,
                    ContentType.ebook
                );
            }
            default: {
                const getData = data as TitleResult;
                return getDetailRoute(
                    getData.contentUID,
                    getData.contentType,
                    licenseType
                );
            }
        }
    };

    const getSegmentTitle = (title: string) => {
        switch (title) {
            case 'author': {
                return 'นักเขียน';
            }
            case 'title': {
                return 'ชื่อ';
            }
            case 'publisher': {
                return 'สำนักพิมพ์';
            }
            case 'category': {
                return 'หมวดหมู่';
            }
            case 'keyword': {
                return 'คำสำคัญ';
            }
            default:
                return '';
        }
    };

    const createSearchAutoComplete = (result: {
        [x: string]: AutoCompleteKey;
    }): any[] => {
        const finalData = [];
        for (const key in result) {
            const value = result[key];
            const title = renderTitle(getSegmentTitle(key));
            const options: any[] = [];
            value.forEach((el: any) => {
                switch (key) {
                    case 'author': {
                        const data = el as AuthorResult;
                        options.push(
                            renderItem(
                                data.keyword,
                                data.creatorUID,
                                getAutocompleteLink(data)
                            )
                        );

                        break;
                    }
                    case 'title': {
                        const data = el as TitleResult;
                        options.push(
                            renderItem(
                                data.keyword,
                                data.contentUID,
                                getAutocompleteLink(data)
                            )
                        );
                        break;
                    }
                    case 'publisher': {
                        const data = el as PublisherResult;
                        options.push(
                            renderItem(
                                data.keyword,
                                data.creatorUID,
                                getAutocompleteLink(data)
                            )
                        );

                        break;
                    }
                    case 'category': {
                        const data = el as CategoryResult;
                        const keyword =
                            data.catName !== data.subcatName
                                ? `${data.catName} > ${data.subcatName}`
                                : `${data.catName}`;
                        options.push(
                            renderItem(
                                keyword,
                                `${data.subcatId}`,
                                getAutocompleteLink(data)
                            )
                        );
                        break;
                    }
                    case 'keyword': {
                        const data = el as KeywordResult;
                        options.push(
                            renderItem(
                                data.keyword,
                                data.contentUID,
                                getAutocompleteLink(data)
                            )
                        );
                        break;
                    }
                    default:
                        break;
                }
            });

            if (options.length > 0) {
                finalData.push({
                    label: title,
                    options: options
                });
            }
        }

        return finalData;
    };

    return (
        <div className='search'>
            <AutoComplete
                className='nav-search-autocomplete'
                dropdownClassName='certain-category-search-dropdown'
                showSearch={true}
                options={options}
            >
                <Input.Search
                    size='middle'
                    className='nav-search-bar'
                    loading={isLoading}
                    onChange={(el) => onChange(' ' + el.target.value)}
                    placeholder={intl.formatMessage({
                        id: 'SEARCH'
                    })}
                    onSearch={(el) => {
                        if (el.length > 0) history.push(`/search/${el.trim()}`);
                    }}
                    allowClear
                />
            </AutoComplete>
        </div>
    );
};

export default SearchBox;
