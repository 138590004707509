import validator from 'validator';
import { getDomainName, getDomainPrefix } from '../pages/main';
import { getUserInfo } from '../redux/auth/authRedux';
import dayjs from 'dayjs';

export const validationDomain = (email: string, domains: string[]) => {
  if (email.match(/[ก-๙]/) || !validator.isEmail(email)) {
    return false;
  }
  if (domains.length === 0) {
    return true;
  } else {
    const getDomain = email.split('@')[1];
    if (getDomain) {
      let result = false;
      -domains.forEach((el) => {
        const getEmailDomain = el.replace(/^@/, '');
        const reg = new RegExp(
          `^([a-z0-9|-]+[a-z0-9]{1,}\\.+)+${getEmailDomain}$|^${getEmailDomain}$`,
          'i'
        );
        const match = getDomain.match(reg);
        if (match) {
          result = true;
        }
      });
      return result;
    } else {
      return false;
    }
  }
};

export const checkRedirect = (userToken: string, code: string) => {
  const prefix = getDomainPrefix();
  const userInfo = getUserInfo(userToken);
  const isDevPath = window.location.origin.includes('dev-');
  const webState = isDevPath ? 'dev-' : '';

  if (prefix && prefix !== userInfo.clientPrefix) {
    const reg = new RegExp(
      `elibrary-([A-z0-9]+)\.${getDomainName(window.location.hostname)}`
    );

    if (
      window.location.hostname === 'bkk.hibrary.me' ||
      userInfo.clientPrefix === 'bkk'
    ) {
      window.location.href = `elibrary-${userInfo.clientPrefix}.${getDomainName(
        window.location.hostname
      )}?ref=${userInfo.uid}&&authenUserToken=${encodeURIComponent(code)}`;
    }

    if (window.location.origin.match(reg)) {
      const newLocation = window.location.origin.replace(
        reg,
        `${webState}elibrary-${userInfo.clientPrefix}.${getDomainName(
          window.location.hostname
        )}`
      );
      window.location.href = `${newLocation}?ref=${
        userInfo.uid
      }&&authenUserToken=${encodeURIComponent(code)}&&`;
    } else {
      window.location.href = `https://elibrary-${
        userInfo.clientPrefix
      }.${getDomainName(window.location.hostname)}?ref=${
        userInfo.uid
      }&&authenUserToken=${encodeURIComponent(code)}`;
    }
    return true;
  } else {
    return false;
  }
};

export const checkOTPExpire = (expireDate: number) => {
  const today = dayjs().unix();
  if (today > expireDate) return true;
  else if (today < expireDate) return false;
  else return true;
};

export const checkValueIsPhoneNumber = (value: string) => {
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
  const regexPhoneNumber = /^(?:\+|\d)[0-9]+$/g;

  if (regexPhoneNumber.test(value)) {
    return true;
  } else if (regexEmail.test(value)) {
    return false;
  } else {
    return false;
  }
};
