import { Themes } from './client';
import { Device } from './device';

export interface OtpPassword {
    uid?: string;
    userID?: string;
    referenceCode?: string;
    transactionDate?: number;
    expireDate?: number;
    verifyOtp?: string;
}

export interface OtpRegister extends OtpPassword {
    userID: string;
    email?: string;
}

export interface SsoUrl {
    ssoUrl: string;
    redireactUrl: string;
}

export interface Data {
    userToken: string;
    firebaseToken: string;
    devices: Device[];
    client: Client;
}

export interface Client {
    themes: Themes;
}

export enum SendEmailForOtpType {
    ForgotPassword = 'ForgotPassword',
    RegisterByEmail = 'RegisterByEmail',
    RegisterByPhoneNumber = 'RegisterByPhoneNumber',
    ChangeEmail = 'ChangeEmail',
    ChangePassword = 'ChangePassword'
}

export enum RegisterType {
    Email = 'Email',
    PhoneNumber = 'PhoneNumber'
}

export enum AuthPageType {
    Login,
    Register,
    RegisterOTP,
    SuccessRegister,
    ForgotPassword,
    ForgotPasswordOTP,
    SuccessForgotPassword,
    ChangeEmail,
    ChangeEmailOTP,
    SuccessChangeEmail,
    ChangePassword,
    ChangePasswordOTP,
    SuccessChangePassword,
    VerifyOTP,
    NewPassword,
    Success,
    FullDevice,
    SelectLibrary,
    AddNewLibrary,
    AddNewLibraryForm,
    RegisterForm
}
