import { NotificationType } from '../../../interface/notification';

export interface NotificationState {
   notification: NotificationType[];
   userSurveyUrl?: string;
   isSponsoredClient?: boolean;
}

const initialState: NotificationState = {
   notification: []
};

export enum NotificationActionType {
   fetchNotificationData = '[fetch]NotificationData'
}

export interface NotificationAction {
   type: NotificationActionType;
   payload?: NotificationState;
}

export const reducer = (
   state = initialState,
   action: NotificationAction
): NotificationState => {
   switch (action.type) {
      case NotificationActionType.fetchNotificationData: {
         const payload = action.payload;
         return {
            ...state,
            notification: payload?.notification ?? [],
            userSurveyUrl: payload?.userSurveyUrl,
            isSponsoredClient: payload?.isSponsoredClient
         };
      }
      default:
         return state;
   }
};
