import axios from 'axios';
import {
   ContentType,
   HomeResponse,
   HomeSet,
   LicenseType,
   ContentDetail
} from '../../../interface/home';
import {
   HtiResponse,
   ContestSetListResponse,
   ListAllRentContentResponse,
   ListAllFreeContentResponse,
   ListContentByCreatorResponse,
   ListContentByWriterResponse,
   ListContentByKeywordResponse,
   SearchResultResponse
} from '../../../interface/request';
import {
   generatePath,
   chkLastEvaluateKey,
   generateCreatorPath,
   generateWriterPath,
   generateKeywordPath,
   generateWriterPathById
} from '../utilities';
import * as StoreModel from './storeModel';

import queryString from 'query-string';

export const findEbook = async (
   contentType?: ContentType,
   licenseType?: LicenseType,
   lastEvaluatedKey?: any,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;
   qString['limit'] = 64;
   let checkLicense = undefined;
   if (licenseType !== LicenseType.Rent) {
      checkLicense = licenseType;
   }
   const path = generatePath(
      `store`,
      `listEbook`,
      prefix,
      checkLicense,
      contentType ?? ContentType.ebook,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<
      HtiResponse<ListAllRentContentResponse | ListAllFreeContentResponse>
   >(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getStoreHome = async () => {
   const path = `/store/home`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<HomeResponse>>(path, {
      params: { enableRentAble: true }
   });
   return data;
};

export const getPublicHome = async (
   prefix?: string,
   contentType: ContentType = ContentType.ebook
) => {
   const path = `/public/${prefix}/store/home?contentType=${contentType}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<HomeResponse>>(path, {
      params: { enableRentAble: true }
   });
   return data;
};

export const getPrivateHome = async (
   prefix?: string,
   contentType?: ContentType,
   isPublic = true
) => {
   const path = generatePath(
      'store',
      'home',
      prefix,
      LicenseType.Private,
      contentType,
      isPublic
   );
   const {
      data: { data }
   } = await axios.get<HtiResponse<HomeResponse>>(path);
   return data;
};

export const getFreeHome = async (
   prefix?: string,
   contentType: ContentType = ContentType.ebook,
   isPublic = true
) => {
   const path = generatePath(
      `store`,
      `home`,
      prefix,
      LicenseType.Free,
      contentType,
      isPublic
   );
   const {
      data: { data }
   } = await axios.get<HtiResponse<HomeResponse>>(path);
   return data;
};
export const getContentDetail = async <T = ContentDetail>(
   uid: string,
   licenseType?: LicenseType,
   prefix?: string,
   isPublic = true
) => {
   const path =
      licenseType === LicenseType.Rent
         ? generatePath(
              `store`,
              `ebooks/${uid}`,
              prefix,
              undefined,
              undefined,
              isPublic
           )
         : generatePath(
              `store`,
              `contents/${uid}`,
              prefix,
              licenseType,
              undefined,
              isPublic
           );
   const {
      data: { data }
   } = await axios.get<HtiResponse<T>>(path);
   return data;
};
export function getEbookDetail(uid: string, licenseType?: string) {
   const path =
      licenseType === LicenseType.Rent
         ? generatePath(
              `/store`,
              `/ebooks/${uid}`,
              licenseType,
              LicenseType.Rent,
              ContentType.ebook
           )
         : generatePath(
              `/store`,
              `/contents/${uid}`,
              licenseType,
              LicenseType.Rent,
              ContentType.ebook
           );
   return axios.get<HtiResponse<StoreModel.StoreBookDetail>>(path);
}
export function getEbookList(
   catId: string,
   subcatId: string,
   contentType: string,
   lastEvaluatedKey?: any,
   licenseType?: string
) {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['subcatId'] = subcatId;
   qString['contentType'] = contentType;
   qString['limit'] = 64;

   const path = generatePath(
      `/store`,
      `/categories/${catId}`,
      licenseType,
      LicenseType.Rent,
      ContentType.ebook
   );

   return axios.get(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
}
export const getEbookListByCreator = async (
   creatorUID: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;
   qString['limit'] = 64;
   const path = generateCreatorPath(
      `store`,
      'creators',
      creatorUID,
      prefix,
      licenseType,
      contentType,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByCreatorResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getEbookListByWriter = async (
   writerName: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;
   qString['limit'] = 64;
   const path = generateWriterPath(
      `store`,
      'authors',
      writerName,
      prefix,
      licenseType,
      contentType,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByWriterResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getEbookListByWriterById = async (
   writerUID: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   creatorUID?: string,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;
   qString['writerID'] = writerUID;
   qString['creatorUID'] = creatorUID;
   qString['limit'] = 64;
   const path = generateWriterPathById(
      `store`,
      'creators',
      writerUID,
      licenseType,
      creatorUID,
      prefix,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByWriterResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getEbookListByKeyword = async (
   keyword: string,
   lastEvaluatedKey?: any,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isPublic = true
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['contentType'] = contentType;
   qString['limit'] = 64;
   const path = generateKeywordPath(
      `store`,
      'tags',
      keyword,
      prefix,
      licenseType,
      contentType,
      isPublic
   );

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListContentByKeywordResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const rentEbook = (
   uid?: string,
   rentalStatus?: string,
   licenseType?: string
) => {
   if (rentalStatus === 'Rent') {
      const path = `/licenses`;
      const qString: any = {
         contentUID: uid,
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.post(path, qString);
   } else if (rentalStatus === 'WaitingList') {
      const path = `/licenses/${uid}/waiting`;
      const qString: any = {
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.post(path, qString);
   } else if (rentalStatus === 'WaitingListed') {
      const path = `/licenses/${uid}/waiting`;
      const qString: any = {
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.delete(path, qString);
   }
};

export function returnEbook(
   uid?: string, //licenseUID
   rentalStatus?: string,
   licenseType?: string
) {
   if (
      rentalStatus === 'Rented' ||
      (rentalStatus === undefined && licenseType) //bookshelf
   ) {
      const path = `/licenses/${uid}/return`;
      const qString: any = {
         licenseType: licenseType ? licenseType : 'Rent'
      };
      return axios.patch(path, qString);
   }
}
export interface ListSetResponse {
   sets: HomeSet[];
}
export const getListSets = async (
   setType: string,
   prefix?: string,
   isLogin = false
) => {
   const path = `${!isLogin ? `/public/${prefix}` : ``}/sets`;
   const qString: any = {
      setType: setType,
      limit: 64
   };

   const {
      data: { data }
   } = await axios.get<HtiResponse<ListSetResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getListContentSets = async (
   uid: string,
   setType: string,
   lastEvaluatedKey?: any,
   prefix?: string,
   isLogin = false
) => {
   const path = `${!isLogin ? `/public/${prefix}` : ``}/sets/${uid}`;
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   qString['setType'] = setType;
   const {
      data: { data }
   } = await axios.get<HtiResponse<ContestSetListResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getListSearch = async (
   keyword: string,
   prefix?: string,
   isPublic?: boolean
) => {
   const path = generatePath(
      `store`,
      `search`,
      prefix,
      undefined,
      ContentType.ebook,
      isPublic
   );
   const qString: any = {
      keyword: keyword
   };
   const {
      data: { data }
   } = await axios.get<HtiResponse<SearchResultResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );

   return data;
};

export function getContactList() {
   return axios.get(`/application/contactList`);
}

export function getIsPublicRegister(prefix: string) {
   return axios.get(`/public/${prefix}/theme`);
}

export const getRentableContents = async (
   clientPrefix: string,
   isPublic = true,
   lastEvaluatedKey: any
) => {
   try {
      const path = isPublic
         ? `/public/${clientPrefix}/store/rentAbleContents`
         : `/store/rentAbleContents`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<ListAllRentContentResponse>>(path, {
         params: { lastEvaluatedKey: lastEvaluatedKey }
      });
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};
