import axios from 'axios';
import { CategoryContent, ContentType, LicenseType } from '../../../interface/home';
import {
   HtiResponse,
   ListAllCategoriesResponse,
   ListAllRentContentResponse
} from '../../../interface/request';
import { generateCategoryPath, chkLastEvaluateKey } from '../utilities';
import queryString from 'query-string';

export const findCategories = async (
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isLogin?: boolean
) => {
   const path = generateCategoryPath(
      `categories`,
      undefined,
      prefix,
      licenseType,
      contentType,
      !isLogin
   );
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListAllCategoriesResponse>>(path);
   return data;
};

export const findCategoryDetail = async (
   catId: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   prefix?: string,
   isLogin?: boolean
) => {
   const path = generateCategoryPath(
      `categories`,
      catId,
      prefix,
      licenseType,
      contentType,
      !isLogin
   );
   const qString: any = { contentType: contentType };

   const {
      data: { data }
   } = await axios.get<HtiResponse<CategoryContent>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

export const getSubCategoryContent = async (
   catId: string,
   subcatId: string,
   licenseType?: LicenseType,
   contentType?: ContentType,
   lastEvaluatedKey?: any,
   prefix?: string,
   isLogin?: boolean
) => {
   const qString: any = chkLastEvaluateKey(lastEvaluatedKey);
   const path = generateCategoryPath(
      `store`,
      `categories/${catId}`,
      prefix,
      licenseType,
      contentType,
      !isLogin
   );
   qString['contentType'] = contentType;
   qString['subcatId'] = subcatId;
   const {
      data: { data }
   } = await axios.get<HtiResponse<ListAllRentContentResponse>>(
      queryString.stringifyUrl({
         url: path,
         query: qString
      })
   );
   return data;
};

// https://api-elibrary-prod.hytexts.com/public/hti/store/categories/7?contentType=ebook&subcatId=34
//https://api-elibrary-prod.hytexts.com/public/hti/store/free/categories/5?contentType=ebook&subcatId=83
